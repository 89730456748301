import React, { useContext, memo } from 'react';
import Utils from '../../utils';
import DataContext from '../../Contexts/DataContext';
import './LocationGroupHeader.scss';

/**
 * Represents the header of the consumer app.
 * @param {Object} props The component properties.
 */
const LocationGroupHeader = memo(() => {
  const context = useContext(DataContext);
  const logoUrl = Utils.getLogoUrl();

  return (
    <div className="loc-group-header">
      <div className="loc-group-header__logo-cont">
        <a href={`/${context.uniqueLocationId}`}>
          <img alt="Site Logo" src={logoUrl} />
        </a>
      </div>
    </div>
  );
});

export default LocationGroupHeader;
