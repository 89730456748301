import React, { Component } from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';
import CloseIcon from '@material-ui/icons/Close';
import DataContext from '../../Contexts/DataContext';
import EventBuilder from '../../eventBuilder';
import Utils from '../../utils';
import Overlay from '../../Components/Overlay/Overlay';
import PatientSurveyService from '../../Services/patientSurveyService';
import LocationConfigService from '../../Services/locationConfigService';
import ContentManagementService from '../../Services/contentManagementService';
import PatientSurvey from '../../Components/PatientSurvey/PatientSurvey';
import Constants from '../../constants';
import Enums from '../../enums';
import './PatientSurveyPage.scss';

const eventBuilder = new EventBuilder();

/**
 * Represents the feedback page.
 */
class PatientSurveyPage extends Component {
  /**
   * Initializes a new instance of the Feedback page.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      isReadyToRender: false,
      locationInfo: null,
      surveyUrlKey: '',
      showThankYouModal: false,
      showErrorSavingModal: false,
    };
    this._patientSurveyService = new PatientSurveyService();
    this._locationConfigService = new LocationConfigService();
    this._contentManagementService = new ContentManagementService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      this.setState(() => ({ showLoadingOverlay: true }));

      const locationData =
        await this._locationConfigService.getLocationOrGroupInfo();

      const languageTag =
        locationData?.locationInfo.storeInformation.languageTag;
      if (languageTag && languageTag.length) {
        await this._contentManagementService.loadLocalizations(languageTag);
        await i18n.changeLanguage(languageTag);
      }

      const surveyUrlKey = Utils.getUrlParam('urlKey');

      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
        surveyUrlKey: surveyUrlKey,
        locationInfo: locationData?.locationInfo,
      }));
    } catch (error) {
      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
      }));
    }
  }

  _onCloseThankYouModal = () => {
    this.setState(() => ({ showThankYouModal: false }));
    this.context.navigateTo(Constants.homeRoute, this.props.history);
  };

  _onCloseErrorSavingModal = () => {
    this.setState(() => ({ showErrorSavingModal: false }));
  };

  _onSubmitSurvey = async (survey) => {
    try {
      const { locationInfo, surveyUrlKey } = this.state;
      survey.locationId = locationInfo.locationId;
      survey.UrlKey = surveyUrlKey;
      await this._patientSurveyService.savePatientSurvey({ survey: survey });
      this.setState(() => ({ showThankYouModal: true }));
    } catch (error) {
      this.setState(() => ({
        showErrorSavingModal: true,
      }));
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      isReadyToRender,
      showThankYouModal,
      showErrorSavingModal,
      showLoadingOverlay,
      locationInfo,
    } = this.state;

    const name = locationInfo?.storeInformation?.name;
    const address = locationInfo?.storeInformation?.address;

    const { t } = this.props;
    const logoUrl = Utils.getLogoUrl();

    return (
      <div className="page page--patientsurvey">
        {isReadyToRender && (
          <section className="patient-survey__header">
            <div className="patient-survey__brand">
              <img
                alt="Site Logo"
                className="patient-survey__logo"
                src={logoUrl}
              />
            </div>
          </section>
        )}
        {isReadyToRender && (
          <section className="patient-survey__content">
            <div className="patient-survey__storeinfo">
              <h2>{name}</h2>
              <p>{address}</p>
            </div>
            <PatientSurvey onClose={this._onSubmitSurvey} />
          </section>
        )}
        {isReadyToRender && (
          <section className="patient-survey__footer">
            <span className="patient-survey__sponsor">
              {t('Powered by Essilor')}
            </span>
          </section>
        )}
        <Overlay flex={true} show={showThankYouModal}>
          <div className="patient-survey__">
            <div className="patient-survey____header">
              <span className="patient-survey____spacer">&nbsp;</span>
              <h3 className="patient-survey____thankyou">{t('Thank you!')}</h3>
              <button
                className="patient-survey____close"
                onClick={this._onCloseThankYouModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="patient-survey____content">
              <p className="patient-survey____gesture">
                {t('This simple gesture can make a big difference.')}
              </p>
              <div className="patient-survey____footer">
                <button
                  className="patient-survey____dismiss"
                  onClick={this._onCloseThankYouModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>

        <Overlay flex={true} show={showErrorSavingModal}>
          <div className="patient-survey__">
            <div className="patient-survey____header">
              <span className="patient-survey____spacer">&nbsp;</span>
              <h3 className="patient-survey____thankyou">
                {t("We're sorry!")}
              </h3>
              <button
                className="patient-survey____close"
                onClick={this._onCloseErrorSavingModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="patient-survey____content">
              <p className="patient-survey____gesture">
                {t('An unexpected issue occurred.')}
              </p>
              <div className="patient-survey____footer">
                <button
                  className="patient-survey____dismiss"
                  onClick={this._onCloseErrorSavingModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>

        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

PatientSurveyPage.contextType = DataContext;

export default withTranslation()(PatientSurveyPage);
