/**
 * Represents a collection of strongly typed constants.
 */
class Constants {
  /**
   * Represents the current customer appointment key.
   */
  static get currCustomerApptKey() {
    return 'cca';
  }

  /**
   * Represents the current translation url key.
   */
  static get currTranslationUrlKey() {
    return 'ctu';
  }

  /**
   * Represents the session key
   */
  static get sessIdKey() {
    return 'sessId';
  }

  /**
   * Gets the consumer info key.
   */
  static get consumerInfoKey() {
    return 'cik';
  }

  /**
   * Gets the booking info key
   */
  static get bookingInfoKey() {
    return 'bookingInfo';
  }

  /**
   * Gets the English language tag.
   */
  static get englishLanguageTag() {
    return 'en-US';
  }

  /**
   * Gets the home page route.
   */
  static get homeRoute() {
    return '/';
  }

  static get locationInfoKey() {
    return 'lik';
  }

  /**
   * Gets the masked phone key.
   */
  static get maskedPhone() {
    return 'mp';
  }

  /**
   * Gets the masked sms phone key.
   */
  static get maskedSmsPhone() {
    return 'msp';
  }

  /**
   * Gets the Spanish language tag.
   */
  static get spanishLanguageTag() {
    return 'es';
  }

  /**
   * Gets the Portuguese language tag.
   */
  static get portugueseLanguageTag() {
    return 'pt';
  }

  /**
   * Gets the Turkey language tag.
   */
  static get turkeyLanguageTag() {
    return 'tr-TR';
  }

  /**
   * Gets the Russian language tag.
   */
  static get russiaLanguageTag() {
    return 'ru';
  }

  /**
   * Gets the Hong Kong language tag.
   */
  static get hongkongLanguageTag() {
    return 'zh-HK';
  }

  /**
   * Gets the Taiwan language tag.
   */
  static get taiwanLanguageTag() {
    return 'zh-TW';
  }

  /**
   * Gets the India language tag.
   */
  static get indiaLanguageTag() {
    return 'en-IN';
  }

  /**
   * Represents the default display name for the default resource
   */
  static get defaultResourceDisplayName() {
    return 'Appointments';
  }

  /**
   * Gets the waitlist value for verification
   * of joruney
   */
  static get waitlist() {
    return 'waitlist';
  }

  /**
   * Gets the personal info page route.
   */
  static get personalInfoPageRoute() {
    return '/waitlist/personal-info';
  }

  /**
   * Gets the details page route.
   */
  static get detailsPageRoute() {
    return '/book/details';
  }

  /**
   * Gets the confirm page route.
   */
  static get confirmPageRoute() {
    return '/book/confirm';
  }

  /**
   * Gets the resource page route for booking journey.
   */
  static get bookResourcePageRoute() {
    return '/book/resource';
  }

  /**
   * Gets the personal info page route for booking journey.
   */
  static get bookPersonalInfoPageRoute() {
    return '/book/personal-info';
  }

  /**
   * Gets the appointments page route for booking journey.
   */
  static get bookAppointmentPageRoute() {
    return '/book/visit';
  }

  /**
   * Gets the terms of use path
   */
  static get termsOfUse() {
    return 'terms';
  }

  /**
   * Gets the privacy policy path
   */
  static get privacyPolicy() {
    return 'privacy';
  }

  static get surveyResponseThankYouRoute() {
    return '/surveyResponse/thankYou';
  }

  static get siteConfig() {
    return 'siteConfig';
  }

  static get voucherStorageKey() {
    return 'utm_campaign';
  }

  /**
   * Represents the IsWhatsAppB2CEnabled Country Setting key
   */
  static settingIsWhatsAppB2CEnabled = 'IsWhatsAppB2CEnabled';

  /**
   * Represents the IsWhatsAppNotificationsEnabled Country Setting key
   */
  static settingIsWhatsAppNotificationsEnabled =
    'IsWhatsAppNotificationsEnabled';

  /**
   * Represents the IsSmsOneWay Country Settings key
   */
  static settingIsSmsOneWay = 'IsSmsOneWay';

  /**
   * Represents the IsRequestCustomerFeedbackEnabled key
   */
  static settingIsRequestCustomerFeedbackEnabled =
    'IsRequestCustomerFeedbackEnabled';

  /**
   * Represents the IsStellestEnabled LocationSetting
   */
  static settingIsStellestEnabled = 'IsStellestEnabled';

  /**
   * Represents the IncludeCountryCodeInEcpPhoneNumber Country Setting key
   */
  static settingIncludeCountryCodeInEcpPhoneNumber =
    'IncludeCountryCodeInEcpPhoneNumber';

  /**
   * Represents the DefaultMessagingPlatform Country Setting key
   */
  static settingDefaultMessagingPlatform = 'DefaultMessagingPlatform';

  /**
   * Represents minimal location info session key.
   */
  static minimalLocationInfoKey = 'mlik';

  /**
   * Represents the site id session key.
   */
  static siteIdKey = 'si';

  /**
   * Represents the language tag session key.
   */
  static languageTagKey = 'lng';

  static Events = {
    get LocationInfoLoaded() {
      return 'LocationInfoLoaded';
    },
  };
}

// Lock object to prevent modification (true static).
Object.freeze(Constants);

export default Constants;
