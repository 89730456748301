import React, { Component } from 'react';
import axios from 'axios';
import Session from '../../session';
import Constants from '../../constants';
import { ClickAwayListener } from '@material-ui/core';
import MuiTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import './StellestConfirmation.scss';
import EbnLogo from '../../Assets/Images/EyeBookNow-Logo.png';
import ThumbsUpIcon from '../../Assets/Images/ThumbsUp-Icon.png';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AppointmentService from '../../Services/appointmentService';
import Utils from '../../utils';
import StellestLogoWhite from '../../Assets/Images/Stellest-logo-white.png';
import cloneDeep from 'lodash.clonedeep';
import Overlay from '../../Components/Overlay/Overlay';
import Events from '../../events';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

class StellestConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationUrl: `${process.env.REACT_APP_STELLEST_CONFIRMATION_API}`,
      disableDownloadIcsButton: true,
      getConfirmationInfoUrl: `${process.env.REACT_APP_STELLEST_GET_CONFIRMATION_API}`,
      readyToLoad: false,
      showIcsDownloadTooltip: false,
      showLoadingOverlay: true,
      locationInfo: Session.getItem(Constants.locationInfoKey),
    };
    this._appointmentService = new AppointmentService();
  }

  async componentDidMount() {
    this.setupSubscriptions();
    if (this.state.locationInfo) {
      await this._initComponent();
      this.setState({ showLoadingOverlay: false });
    }
  }

  setupSubscriptions = () => {
    //if this is subscribed to after the event is emitted, then we already have the data. if it is subscribed to before the event emits then this will be called.
    Events.on(Constants.Events.LocationInfoLoaded, async () => {
      this.setState({
        showLoadingOverlay: true,
        locationInfo: Session.getItem(Constants.locationInfoKey),
      });
      await this._initComponent();
      this.setState({ showLoadingOverlay: false });
    });
  };

  _initComponent = async () => {
    const { confirmationUrl, getConfirmationInfoUrl } = this.state;
    const locationInfo = Session.getItem(Constants.locationInfoKey);
    const { uniqueLocationId } = locationInfo;

    const urlParams = new URLSearchParams(window.location.search);
    const confirmationId = urlParams.get('guid');
    const confirmed = urlParams.get('confirmed');

    const url = `${confirmationUrl}/${uniqueLocationId}?confirmationId=${confirmationId}`;
    const getConfirmationUrl = `${getConfirmationInfoUrl}/${uniqueLocationId}?confirmationId=${confirmationId}`;

    if (confirmationId) {
      // fire confirmation logic
      let success = false;
      if (!confirmed) {
        await axios
          .post(url)
          .then((response) => {
            if (response.status === 200) {
              success = true;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }

      await axios
        .get(getConfirmationUrl)
        .then((response) => {
          this.setState({
            appointment: response.data.appointment,
            locationInfo: locationInfo,
            readyToLoad: true,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  _onDownloadIcsFile = async () => {
    try {
      this.setState(() => ({ disableDownloadIcsButton: true }));

      let appointment = this.state.appointment;
      const { firstName, lastName } = appointment.patient;
      const fileName = `${firstName}_${lastName}_${appointment.appointmentType.displayName}.ics`;
      let downloadUrl = '';

      var data = cloneDeep(appointment);
      data.startTime = Utils.convertIsoStringToDate(appointment.startTime);

      // If Chrome on iOS? There's a known bug with Chrome on iOS where
      // It won't recognize an iCal file if downloaded via the download
      // attribute for an anchor.
      if (navigator.userAgent.includes('CriOS')) {
        downloadUrl = `${process.env.REACT_APP_APPOINTMENT_API}/getAppointmentForCalendar/${this._appointmentId}?fileName=${fileName}&requestedimeType=text/calendar`;
      } else {
        const icsData = await this._appointmentService.downloadIcsFile(data);
        downloadUrl = window.URL.createObjectURL(
          new Blob([icsData], { type: 'text/calendar' })
        );
      }

      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.setAttribute('download', fileName);

      document.body.appendChild(anchor);

      anchor.click();

      document.body.removeChild(anchor);

      this.setState(() => ({ disableDownloadIcsButton: false }));
    } catch (error) {
      console.error(error);
    }
  };

  _onToggleIcsDownloadTooltip = () => {
    this.setState((prevState) => ({
      showIcsDownloadTooltip: !prevState.showIcsDownloadTooltip,
    }));
  };

  _onCloseIcsDownloadTooltip = () => {
    this.setState(() => ({ showIcsDownloadTooltip: false }));
  };

  render() {
    const {
      disableDownloadIcsButton,
      readyToLoad,
      locationInfo,
      showIcsDownloadTooltip,
      showLoadingOverlay,
    } = this.state;

    if (readyToLoad) {
      const { address, latitude, longitude, name, phone } =
        locationInfo.storeInformation;

      console.log('Location info:', locationInfo);

      return (
        <div className="stellest-confirmation">
          <div className="header">
            <img
              className="stellest-logo"
              src={StellestLogoWhite}
              alt="Stellest Logo"
            />
            <p>Follow up appointment was confirmed and scheduled using:</p>
            <img className="ebn-logo" src={EbnLogo} alt="Eye Book Now logo" />
          </div>
          <div className="title">
            <div className="title-contents">
              <div className="icon-container">
                <img src={ThumbsUpIcon} alt="thumbs up" />
              </div>
              <h2>
                Your appointment has been confirmed!
                <span>
                  <sup class="foot-note-indicator">1</sup>
                </span>
              </h2>
              <p>
                <b>You’re all set!</b> You have successfully confirmed your
                follow-up appointment.
              </p>
              <hr />
            </div>
          </div>
          <div className="content">
            <div className="stellest-add-to-calendar">
              <button
                className="confirm__download-ics"
                // disabled={disableDownloadIcsButton}
                onClick={this._onDownloadIcsFile}
              >
                {'Add to my calendar'}
              </button>
              <ClickAwayListener onClickAway={this._onCloseIcsDownloadTooltip}>
                <button
                  className="confirm__info-icon"
                  type="button"
                  onClick={this._onToggleIcsDownloadTooltip}
                >
                  <Tooltip
                    open={showIcsDownloadTooltip}
                    placement="top-end"
                    title={
                      'Please note that the .ICS calendar appointment file may not work on non-native browsers for the iOS platform or native calendar applications for Android devices.'
                    }
                  >
                    <span className="confirm__tooltip">i</span>
                  </Tooltip>
                </button>
              </ClickAwayListener>
            </div>
            <a
              className="get-directions"
              href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
              target="_blank"
            >
              <LocationOnIcon color="white" />
              Get directions
            </a>
            <p>
              <b>{name}</b> - {address} <b>{phone}</b>
            </p>
            <p class="foot-note">(1) Terms of Use & Privacy Policy apply.</p>
          </div>
          <div className="footer">
            <p>Powered by Essilor</p>
          </div>
        </div>
      );
    }
    return (
      <div>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default StellestConfirmation;
