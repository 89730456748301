import React, { Component } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Utils from '../../utils';
import Session from '../../session';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import DataContext from '../../Contexts/DataContext';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import GoogleRating from '../../Components/GoogleRating/GoogleRating';
import LocationConfigService from '../../Services/locationConfigService';
import ContentManagementService from '../../Services/contentManagementService';
import AppointmentService from '../../Services/appointmentService';
import './LocationInfo.scss';

/**
 * Represents the location information home page.
 */
class LocationInfo extends Component {
  /**
   * Initializes a new instance of the LocationInfo component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showStoreHours: false,
      showSurvey: true,
      showSurveyQuestions: false,
      includeCountryCodeInEcpPhoneNumber: false,
    };
    this._eventBuilder = new EventBuilder();
    this._pageviewBuilder = new PageviewBuilder();
    this._pageviewBuilder.pageview(this._pageviewBuilder.Page.home);
    this._locationConfigService = new LocationConfigService();
    this._contentManagementService = new ContentManagementService();
    this._appointmentService = new AppointmentService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    this._eventBuilder
      .withCategory(this._eventBuilder.Category.Home.homePage)
      .withAction(this._eventBuilder.Action.Pageview.homePage)
      .withLabel(this._eventBuilder.Label.practiceIdentifier)
      .post();
  }

  _isStoreOpen() {
    const { t } = this.props;
    const dayOfWeekId = moment().day() + 1;
    const { schedules } = this.context.minimalLocationInfo.storeHours;
    const schedule = schedules.find((s) => s.dayOfWeekId === dayOfWeekId);

    if (schedule) {
      const closed = -1;
      const timeBlock = schedule.timeBlocks[0];

      if (timeBlock.start === closed && timeBlock.end === closed) {
        return false;
      } else {
        const format = 'LT';
        const currentTime = moment().format(format);
        const languageTag = Session.getItem(Constants.languageTagKey);
        const dayHours = Utils.getDayHours(
          dayOfWeekId,
          schedules,
          languageTag,
          t
        );
        const startHours = moment(dayHours.split(' - ')[0], format);
        const endHours = moment(dayHours.split(' - ')[1], format);
        const momentCurrentTime = moment(currentTime, format);
        const holidays = this.context.locationInfo?.appliedHolidays?.map(
          (appliedHoliday) => ({
            startMoment: moment(
              appliedHoliday.startDate,
              '(YYYY, MM, DD, hh, mm)'
            ),
            endMoment: moment(appliedHoliday.endDate, '(YYYY, MM, DD, hh, mm)'),
          })
        );

        const isDuringStoreHours = momentCurrentTime.isBetween(
          startHours,
          endHours
        );
        const isDuringHoliday = holidays?.some(
          (holiday) =>
            momentCurrentTime.isSameOrAfter(holiday.startMoment) &&
            momentCurrentTime.isSameOrBefore(holiday.endMoment)
        );
        if (isDuringStoreHours && !isDuringHoliday) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  onBookAppointment = () => {
    const { locationInfo, bookingInfo, update } = this.context;

    let values = {
      ...this.context,
      journey: 'booking',
    };

    update(values);

    Session.setItem('ApptBooked', false);

    this._eventBuilder
      .withAction(this._eventBuilder.Action.Click.bookNow)
      .withCategory(this._eventBuilder.Category.Home.homePage)
      .withLabel(this._eventBuilder.Label.practiceIdentifier)
      .post();

    const resourcesLength = locationInfo.resources.length;
    // If the resource is only 1 then it should skip the resource page
    // and choose that resource as the chosen resource
    if (resourcesLength === 1) {
      let bookingInfoSession = bookingInfo;
      bookingInfoSession.resource = locationInfo.resources[0];
      Session.setItem(Constants.bookingInfoKey, bookingInfoSession);
      update({
        ...this.context,
        bookingInfo: {
          ...bookingInfo,
          resource: { ...locationInfo.resources[0] },
        },
      });

      this.context.navigateTo(
        Constants.bookAppointmentPageRoute,
        this.props.history
      );
    } else {
      this.context.navigateTo(
        Constants.bookResourcePageRoute,
        this.props.history
      );
    }
  };

  _onToggleStoreHours = () => {
    this.setState((prevState) => ({
      showStoreHours: !prevState.showStoreHours,
    }));
    this._eventBuilder
      .withCategory(this._eventBuilder.Category.Home.homePage)
      .withAction(this._eventBuilder.Action.Click.ecpOfficeHours)
      .withLabel(this._eventBuilder.Label.practiceIdentifier)
      .post();
  };

  onClickCallNow = () => {
    this._eventBuilder
      .withCategory(this._eventBuilder.Category.Home.homePage)
      .withAction(this._eventBuilder.Action.Click.callNowPhoneNumber)
      .withLabel(this._eventBuilder.Label.practiceIdentifier)
      .post();
  };

  onClickLocationAddress = () => {
    this._eventBuilder
      .withCategory(this._eventBuilder.Category.Home.homePage)
      .withAction(this._eventBuilder.Action.Click.locationAddress)
      .withLabel(this._eventBuilder.Label.practiceIdentifier)
      .post();
  };

  formatResources = (resources) => {
    const { t } = this.props;

    let clean = resources.map((r) => {
      if (r.displayName === '') {
        r.displayName = t('Default Resource Display Name');
      }

      return r;
    });

    return clean;
  };

  getGuardrailsMessage = (isFullyOnBoarded, isStoreClosed, locationInfoRetrieved) => {
    const { t } = this.props;
    if (!isFullyOnBoarded && locationInfoRetrieved) {
      return t(
        'This location has not been activated on the booking platform yet. If you need assistance, kindly call your eye care provider at the number above.'
      );
    } else if (isStoreClosed) {
      return t(
        'The location is currently closed. Kindly review the schedule below and book an appointment within the available hours of operation.'
      );
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const { showStoreHours } = this.state;
    const { t } = this.props;
    const { locationInfo, minimalLocationInfo, locationInfoRetrieved } = this.context;
    const { name, address, storeHours, languageTag, googleReviewInfo } =
      minimalLocationInfo;
    const { schedules } = storeHours;
    const { appliedHolidays, agreementSigned, storeInformation } = locationInfo;
    const rating = googleReviewInfo?.rating;
    const numberOfUserRatings = googleReviewInfo?.numberOfUserRatings;
    const viewReviewUrl = googleReviewInfo?.viewReviewUrl;
    const placeId = googleReviewInfo?.placeId;
    const phone = storeInformation?.phone;
    const smsPhone = storeInformation?.smsPhone;
    const latitude = storeInformation?.latitude;
    const longitude = storeInformation?.longitude;
    const headerEventBuilder = new EventBuilder();
    const isStoreOpen = this._isStoreOpen();
    const maskedPhone = Session.getItem(Constants.maskedPhone);
    const isStoreClosed =
      !isStoreOpen || Utils.isHoliday(new Date(), appliedHolidays);
    const isBookAppointmentButtonDisabled = !agreementSigned;
    const guardrailsMessage = this.getGuardrailsMessage(
      agreementSigned,
      isStoreClosed,
      locationInfoRetrieved
    );

    return (
      <div className="page page--home">
        <section className="loc-info__header">
          <Header
            canNavigateBack={false}
            eventBuilder={headerEventBuilder.withCategory(
              headerEventBuilder.Category.Home.homePage
            )}
          />
        </section>
        <section className="loc-info__content">
          <h1 className="loc-info__title">{t('Home')}</h1>
          <div className="loc-info__hero"></div>
          <div className="loc-info__overlay"></div>
          <div className="loc-info__shape">
            <div
              className={`loc-info__location ${
                showStoreHours ? 'loc-info__location--hide' : ''
              }`}
            >
              <h2 className="loc-info__address">{name}</h2>
              <a
                className="loc-info__maps"
                href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
                rel="noopener noreferrer"
                onClick={this.onClickLocationAddress}
                target="_blank"
              >
                {address}
              </a>
              {phone !== smsPhone && (
                <a
                  className="loc-info-address__phone"
                  href={`tel:${maskedPhone}`}
                  onClick={this.onClickCallNow}
                >
                  {maskedPhone}
                </a>
              )}
              <div className="loc-info__location--mobile">
                {placeId && (
                  <div className="google-rating">
                    <GoogleRating
                      ratings={rating}
                      numberOfRatings={numberOfUserRatings}
                      googleRatingUrl={viewReviewUrl}
                    />
                  </div>
                )}
                {guardrailsMessage && (
                  <p className="loc-info-drawer__guardrails">
                    {guardrailsMessage}
                  </p>
                )}
                <div className="loc-info-actions__action-cont">
                  <button
                    className="loc-info-actions__action"
                    disabled={isBookAppointmentButtonDisabled}
                    onClick={this.onBookAppointment}
                  >
                    {t(`Schedule A Visit`)}
                  </button>
                </div>
                <p className="loc-info-actions__button-subtext">
                  {t('For today or in the future')}
                </p>
              </div>
            </div>
            <div className="loc-info-actions">
              {placeId && (
                <div className="google-rating">
                  <GoogleRating
                    ratings={rating}
                    numberOfRatings={numberOfUserRatings}
                    googleRatingUrl={viewReviewUrl}
                  />
                </div>
              )}
              {guardrailsMessage && (
                <p className="loc-info-drawer__guardrails loc-info-drawer__guardrails--desktop">
                  {guardrailsMessage}
                </p>
              )}
              <div className="loc-info-actions__action-cont">
                <button
                  className="loc-info-actions__action"
                  disabled={isBookAppointmentButtonDisabled}
                  onClick={this.onBookAppointment}
                >
                  {t(`Schedule A Visit`)}
                </button>
              </div>
              <p className="loc-info-actions__button-subtext">
                {t('For today or in the future')}
              </p>
            </div>
            <div
              className={`loc-info__drawer ${
                showStoreHours ? 'loc-info__drawer--show-hours' : ''
              }`}
            >
              <p className="loc-info__statement">
                {ReactHtmlParser(t("We're Prioritizing your safety"))}
              </p>
              <button
                className="loc-info__toggle-hours"
                onClick={this._onToggleStoreHours}
              >
                <i className="loc-info__clock-cont">
                  <QueryBuilderIcon className="toggle-hours__clock-icon" />
                </i>
                <span className="loc-info__toggle-hours-text">
                  {t(`Store Hours`)}
                </span>
              </button>
              <div
                className={`loc-info__day-times ${
                  showStoreHours ? 'loc-info__day-times--visible' : ''
                }`}
              >
                <div>
                  <p className="loc-info-drawer__day">{t('Mon')}</p>
                  <p className="loc-info-drawer__day">{t('Tue')}</p>
                  <p className="loc-info-drawer__day">{t('Wed')}</p>
                  <p className="loc-info-drawer__day">{t('Thu')}</p>
                </div>
                <div>
                  <p className="loc-info-drawer__time">
                    {Utils.getDayHours(2, schedules, languageTag, t)}
                  </p>
                  <p className="loc-info-drawer__time">
                    {Utils.getDayHours(3, schedules, languageTag, t)}
                  </p>
                  <p className="loc-info-drawer__time">
                    {Utils.getDayHours(4, schedules, languageTag, t)}
                  </p>
                  <p className="loc-info-drawer__time">
                    {Utils.getDayHours(5, schedules, languageTag, t)}
                  </p>
                </div>
                <div>
                  <p className="loc-info-drawer__day">{t('Fri')}</p>
                  <p className="loc-info-drawer__day">{t('Sat')}</p>
                  <p className="loc-info-drawer__day">{t('Sun')}</p>
                </div>
                <div>
                  <p className="loc-info-drawer__time">
                    {Utils.getDayHours(6, schedules, languageTag, t)}
                  </p>
                  <p className="loc-info-drawer__time">
                    {Utils.getDayHours(7, schedules, languageTag, t)}
                  </p>
                  <p className="loc-info-drawer__time">
                    {Utils.getDayHours(1, schedules, languageTag, t)}
                  </p>
                </div>
              </div>
              <p className="loc-info-drawer__sponsor">
                {t('Powered by Essilor')}
              </p>
            </div>
          </div>
        </section>
        <section className="loc-info__footer">
          <Footer
            schedules={schedules}
            showForMobile={false}
            showHours={true}
            showStatement={true}
          />
        </section>
      </div>
    );
  }
}

LocationInfo.contextType = DataContext;

export default withTranslation()(LocationInfo);
