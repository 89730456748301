import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DataContext from '../../Contexts/DataContext';
import Header from '../../Components/Header/Header';
import OptOutService from '../../Services/optOutService';
import Session from '../../session';
import Constants from '../../constants';
import Utils from '../../utils';
import Overlay from '../../Components/Overlay/Overlay';
import WavingImg from '../../Assets/Images/EBN-Graphic-Waving-Woman.png';
import SittingImg from '../../Assets/Images/EBN-Graphic-Waving-Man.png';
import Events from '../../events';

import './OptOutConfirmation.scss';

const optoutService = new OptOutService();

class OptOutConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      isOptedOut: false,
      patientKey: Utils.getUrlParamWithCase('patientKey'),
      isErrored: false,
      locationInfo: Session.getItem(Constants.locationInfoKey),
    };
    this._optOutService = new OptOutService();
  }

  async componentDidMount() {
    this.setupSubscriptions();
    if (this.state.locationInfo) {
      await this.loadOptOutStatus();
    }
  }

  setupSubscriptions = () => {
    //if this is subscribed to after the event is emitted, then we already have the data. if it is subscribed to before the event emits then this will be called.
    Events.on(Constants.Events.LocationInfoLoaded, async () => {
      this.setState({
        showLoadingOverlay: true,
        locationInfo: Session.getItem(Constants.locationInfoKey),
      });
      await this.loadOptOutStatus();
    });
  };

  async loadOptOutStatus() {
    const { locationInfo, patientKey } = this.state;
    const { locationId } = locationInfo;
    try {
      var data = await this._optOutService.optOutStatus({
        locationId,
        patientKey,
      });
      this.setState(() => ({
        loaded: true,
        isOptedOut: data.isOptedOut,
      }));
    } catch (err) {
      console.error(err);
    }
  }

  _displayPoweredBy = () => {
    const siteConfig = Session.getItem(Constants.siteConfig);

    if (
      Utils.isTurkeyMarket(siteConfig?.languageTag) ||
      Utils.isHongKongMarket(siteConfig?.languageTag) ||
      Utils.isTaiwanMarket(siteConfig?.languageTag)
    ) {
      return false;
    }
    return true;
  };

  _onSubmit = async () => {
    const { locationInfo, patientKey } = this.state;
    const { locationId } = locationInfo;
    this.setState(() => ({
      loaded: false,
    }));
    try {
      await this._optOutService.optOut({ locationId, patientKey });
    } catch (err) {
      console.error(err);
    }
    this.setState(() => ({
      loaded: true,
      isOptedOut: true,
    }));
  };

  render() {
    const displayPoweredBy = this._displayPoweredBy();
    const { t } = this.props;
    const { locationInfo, minimalLocationInfo } = this.context;
    const { loaded, isOptedOut } = this.state;
    const { name, address } = minimalLocationInfo;
    return (
      <div className="page">
        <section className="optout__header">
          <Header canNavigateBack={false} hidePhone={true}></Header>
        </section>
        <section className="optout__content">
          {!isOptedOut && (
            <>
              <h1 className="main__text">
                {t('Are you sure you want to opt out?')}
              </h1>
              <h3 className="sub__text">
                {t(
                  `Click the button below to stop receiving all messages from our location.`
                )}
              </h3>
              <button
                className="optout__submit"
                disabled={!loaded}
                onClick={this._onSubmit}
              >
                {t('Opt Out')}
              </button>
            </>
          )}
          {isOptedOut && (
            <>
              <h1 className="main__text">{t('You will be missed!')}</h1>
              <h3 className="sub__text">
                {t(`You've opted out of receiving messages from our location.`)}
              </h3>
            </>
          )}
          <div className="center__container">
            <div className="location__message">
              <span className="from-the-friendly-st">
                {t('From the friendly staff at:')}
              </span>
              <div className="text-style-1">{name}</div>
              <div className="text-style-2">{address}</div>
            </div>
          </div>
          <div className="center__container sub_text">
            <div className="sub_sub__text">
              {t(
                'We never share any of your information outside of our location.'
              )}
            </div>
          </div>
          <div className="center__container images">
            <img className="man__sitting__img" src={SittingImg} />
            <img className="woman__waving" src={WavingImg} />
          </div>
        </section>
        <section className="optout__footer">
          <div className="terms__privacy">
            <a href="/terms" rel="noopener noreferrer" target="_blank">
              {t('Terms of Use')}
            </a>
            <span> {t('and')} </span>
            <a href="/privacy" rel="noopener noreferrer" target="_blank">
              {t('Privacy Policy')}
            </a>
          </div>
          {displayPoweredBy && (
            <div className="powered__by">
              <span>{t('Powered by Essilor')}</span>
            </div>
          )}
        </section>
        <Overlay show={!loaded}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

OptOutConfirmation.contextType = DataContext;

export default withTranslation()(OptOutConfirmation);
