import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';
import Constants from '../../constants';
import PageviewBuilder from '../../pageviewBuilder';
import DataContext from '../../Contexts/DataContext';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import './SurveyResponse.scss';

const pageviewBuilder = new PageviewBuilder();

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  popper: {
    zIndex: 11000,
  },
}))(MuiTooltip);

/**
 * Represents the survey response page of the consumer app.
 */
class SurveyResponse extends Component {
  /**
   * Initializes a new instance of the SurveyResponse component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {};
    this._onClose = this._onClose.bind(this);
  }

  /**
   * Executes when the component mounts to the DOM.
   */
  async componentDidMount() {
    pageviewBuilder.pageview(pageviewBuilder.Page.SurveyResponse.ThankYou);
  }

  _onClose = () => {
    this.context.navigateTo(Constants.homeRoute, this.props.history);
  };

  /**
   * Renders the component.
   */
  render() {
    const { t } = this.props;

    return (
      <div className="page">
        <section className="survey-reponse__header">
          <Header onNavigateBack={this._onClose} />
        </section>
        <section className="survey-reponse__content">
          <p className="survey-reponse__subtitle">{t('Your Response')}</p>
          <div className="survey-reponse__body">
            <h3 className="survey-reponse__thankyou">{t('Thank you!')}</h3>
            <p className="survey-reponse__gesture">
              {t('This simple gesture can make a big difference.')}
            </p>
            <div className="survey-reponse__footer">
              <button
                className="survey-reponse__dismiss"
                onClick={this._onClose}
              >
                {t('Close')}
              </button>
            </div>
          </div>
        </section>
        <section className="details__footer">
          <Footer showActions={false} />
        </section>
      </div>
    );
  }
}

SurveyResponse.contextType = DataContext;

export default withTranslation()(SurveyResponse);
