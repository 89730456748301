import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import DataContext from '../../Contexts/DataContext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Utils from '../../utils';
import Session from '../../session';
import Constants from '../../constants';
import WhatsAppIcon from '../../Assets/Images/WhatsApp-50x50.png';
import './Header.scss';

/**
 * Represents the header of the consumer app.
 * @param {Object} props The component properties.
 */
const Header = (props) => {
  const {
    canNavigateBack,
    isBookingComplete,
    onClose,
    onNavigateBack,
    eventBuilder,
    hideCloseButton,
    timeOut,
    hidePhone = false,
  } = props;
  const context = useContext(DataContext);

  const logoUrl = Utils.getLogoUrl();

  const onClickCallNow = () => {
    eventBuilder
      .withAction(eventBuilder.Action.Click.callNow)
      .withLabel(eventBuilder.Label.practiceIdentifierAndNumberOfPeople)
      .post();
  };

  const onClickNavigateBack = () => {
    eventBuilder
      .withAction(eventBuilder.Action.Click.backButton)
      .withLabel(eventBuilder.Label.practiceIdentifier)
      .post();

    onNavigateBack();
  };

  const onClickClose = () => {
    eventBuilder
      .withAction(eventBuilder.Action.Click.closeFinishedBooking)
      .withLabel(eventBuilder.Label.practiceIdentifier)
      .post();

    onClose();
  };

  const maskedPhone = Session.getItem(Constants.maskedPhone);
  const smsPhone = context.locationInfo?.storeInformation?.smsPhone;
  const { countrySettings, locationSettings, phoneCountryCode } =
    context.locationInfo;
  const isWhatsAppAllowed =
    countrySettings &&
    countrySettings.some(
      (countrySetting) =>
        countrySetting.settingName === Constants.settingIsWhatsAppB2CEnabled &&
        countrySetting.settingValue === true
    );
  const canUseWhatsApp =
    locationSettings &&
    locationSettings.some(
      (locationSetting) =>
        locationSetting.displayName === Constants.settingIsWhatsAppB2CEnabled &&
        locationSetting.settingValue === true
    );
  if (timeOut) {
    setTimeout(() => {
      onClickClose();
    }, timeOut);
  }

  return (
    <div className="header">
      <button
        className={`header__back ${
          !canNavigateBack ? 'header__back--hidden' : ''
        }`}
        onClick={onClickNavigateBack}
      >
        <NavigateBeforeIcon fontSize="large" />
      </button>
      <div className="header__logo-cont">
        <a href={`/${context.locationInfo.uniqueLocationId}`}>
          <img alt="Site Logo" src={logoUrl} />
        </a>
      </div>
      <div className={`header__contact ${hidePhone ? 'header__hide' : ''}`}>
        {!isBookingComplete && (
          <div className="header__phone-cont">
            {isWhatsAppAllowed && canUseWhatsApp && (
              <a
                className="header__whatsapp-link header__whatsapp-link--mobile"
                href={`${process.env.REACT_APP_WHATSAPP_MOBILE_API}${phoneCountryCode}${smsPhone}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  alt="WhatsApp Logo"
                  className="header__whatsapp-logo"
                  src={WhatsAppIcon}
                />
              </a>
            )}
            {isWhatsAppAllowed && canUseWhatsApp && (
              <a
                className="header__whatsapp-link header__whatsapp-link--desktop"
                href={`${process.env.REACT_APP_WHATSAPP_WEB_API}${phoneCountryCode}${smsPhone}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  alt="WhatsApp Logo"
                  className="header__whatsapp-logo"
                  src={WhatsAppIcon}
                />
              </a>
            )}
            <a
              className="header__phone"
              href={`tel:${maskedPhone}`}
              onClick={onClickCallNow}
            >
              <PhoneIcon />
            </a>
          </div>
        )}
        {isBookingComplete ||
          (hideCloseButton && (
            <button className="header__close" onClick={onClickClose}>
              <CloseIcon />
            </button>
          ))}
      </div>
    </div>
  );
};

Header.propTypes = {
  canNavigateBack: PropTypes.bool,
  isBookingComplete: PropTypes.bool,
  onClose: PropTypes.func,
  onNavigateBack: PropTypes.func,
  eventBuilder: PropTypes.object,
};

Header.defaultProps = {
  canNavigateBack: true,
  isBookingComplete: false,
};

export default withTranslation()(Header);
