import axios from 'axios';

/**
 * Represents the Opt Out service.
 */
class OptOutService {
  /**
   *
   * @param {Object} params The params.
   * @param {Object} params.locationId locationId
   * @param {Object} params.patientKey patientKey
   * @returns A Promise.
   */
  async optOutStatus(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { locationId, patientKey } = params;
        const url = `${process.env.REACT_APP_OPTOUT_API}/${locationId}?patientKey=${encodeURIComponent(patientKey)}`;

        const resp = await axios.get(url);

        resolve(resp.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   *
   * @param {Object} params The params.
   * @param {Object} params.locationId locationId
   * @param {Object} params.patientKey patientKey
   * @returns A Promise.
   */
  async optOut(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { locationId, patientKey } = params;
        const url = `${process.env.REACT_APP_OPTOUT_API}/${locationId}`;
        const data = {
          patientKey: patientKey
        };
        const resp = await axios.post(url, data);

        resolve(resp.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default OptOutService;
