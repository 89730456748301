import React, { Component } from 'react';
import DataContext from './DataContext';
import Session from '../session';
import Constants from '../constants';

/**
 * Represents the data provider used throughout the application.
 */
export default class DataProvider extends Component {
  /**
   * Initializes a new instance of the DataProvider class.
   * @param {Object} props The compoonent properties
   */
  constructor(props) {
    super(props);
    Session.clear();

    this._navigateTo = this._navigateTo.bind(this);
    this._updateState = this._updateState.bind(this);

    let locationInfo = Session.getItem(Constants.locationInfoKey);
    let locationInfoRetrieved = true;

    if (!locationInfo) {
      locationInfoRetrieved = false;
      locationInfo = {
        locationId: null,
        appointmentTypes: [],
        locationSettings: [],
        countrySettings: [],
        numberOfPatientsInQueue: 0,
        schedule: {
          schedules: [],
        },
        storeInformation: {
          name: '',
          address: '',
          phone: '',
          website: '',
        },
      };
    }

    let consumerInfo = Session.getItem(Constants.consumerInfoKey);

    if (!consumerInfo) {
      consumerInfo = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      };
    }

    //get booking out of session, build skeleton if not present
    let bookingInfo = Session.getItem(Constants.bookingInfoKey);

    if (!bookingInfo) {
      bookingInfo = {
        resource: '',
        selectedDate: new Date(),
        timesAvailable: [],
        selectedTime: '',
      };
    }

    let minimalLocationInfo = Session.getItem(Constants.minimalLocationInfoKey);

    if (!minimalLocationInfo) {
      minimalLocationInfo = {
        name: '',
        siteId: 0,
        address: '',
        languageTag: '',
        storeHours: [],
      };
    }

    this.state = {
      chainInfo: {
        chainName: '',
        locatorUrl: '',
        chainLogoUrl: '',
        chainMarketingHtml: '',
      },
      locale: 'en-US',
      locationInfo: locationInfo,
      locationInfoRetrieved: locationInfoRetrieved,
      consumerInfo: consumerInfo,
      journey: '',
      bookingInfo: bookingInfo,
      uniqueLocationId: '',
      phoneCountryCode: '',
      minimalLocationInfo: minimalLocationInfo,
      navigateTo: this._navigateTo,
      update: this._updateState,
    };
  }

  _navigateTo(route, history) {
    const { uniqueLocationId } = this.state;
    history.push(`/${uniqueLocationId}${route}${window.location.search}`);
  }

  _updateState(values) {
    this.setState(() => values);
  }

  /**
   * Renders the context children.
   */
  render() {
    return (
      <DataContext.Provider value={this.state}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}
