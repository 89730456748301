import React, { Component, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Enums from '../../enums';
import Session from '../../session';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import DataContext from '../../Contexts/DataContext';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Overlay from '../../Components/Overlay/Overlay';
import GoogleRating from '../../Components/GoogleRating/GoogleRating';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import './Booking.scss';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();

/**
 * Represents the Booking page of the consumer app.
 */
class Booking extends Component {
  /**
   * Initializes a new instance of the Booking component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showLoadingOverlay: true,
      isDateCurrent: false,
      selectedAppointmentTypeId: 0,
    };
  }

  /**
   * Executes when the component mounts to the DOM.
   */
  async componentDidMount() {
    const locationInfo = Session.getItem(Constants.locationInfoKey);
    if (!locationInfo?.locationId) {
      window.location = Constants.homeRoute;
    }

    const { languageTag } = locationInfo.storeInformation;

    if (languageTag && languageTag.length) {
      momentLocaleWrapper.locale(languageTag);
    }

    //adding this in so resource is auto-set if not defined, this is to address the locator iframe booking that occurs
    const {bookingInfo, update} = this.context;
    if (!bookingInfo.resource) {
      let values = {
        ...this.context,
        journey: 'booking',
      };
  
      update(values);

      let bookingInfoSession = bookingInfo;
      bookingInfoSession.resource = locationInfo.resources[0];
      Session.setItem(Constants.bookingInfoKey, bookingInfoSession);
      update({
        ...this.context,
        bookingInfo: {
          ...bookingInfo,
          resource: { ...locationInfo.resources[0] },
        },
      });
    }

    const apptTypeId =
      locationInfo.selectedAppointmentType?.locationAppointmentTypeId ??
      locationInfo.appointmentTypes[0].locationAppointmentTypeId;
    this.setState(() => ({
      selectedAppointmentTypeId: apptTypeId,
    }));

    const { locationInfoRetrieved } = this.context;
    if (!locationInfo.agreementSigned && locationInfoRetrieved) {
      console.error(
        'This location is not fully onboarded. Proceeding to book may cause an issue.'
      );
    }

    eventBuilder
      .withCategory(eventBuilder.Category.Booking.timePage)
      .withLabel(eventBuilder.Label.practiceIdentifier);

    pageviewBuilder.pageview(pageviewBuilder.Page.Booking.time);

    //Get time Slot at first
    await this.getTimeSlot(this.context.bookingInfo.selectedDate);
  }

  /**
   * Executes when the user navigates back to the previous page.
   */
  onNavigateBack = () => {
    const resourcesLength = this.context.locationInfo.resources.length;

    if (resourcesLength === 1)
      this.context.navigateTo(Constants.homeRoute, this.props.history);
    else
      this.context.navigateTo(
        Constants.bookResourcePageRoute,
        this.props.history
      );
  };

  async getTimeSlot(date) {
    const { t } = this.props;
    const { bookingInfo, locationInfo, update } = this.context;
    const { resource } = bookingInfo;
    const { selectedAppointmentType, locationId } = locationInfo;

    try {
      if (selectedAppointmentType == null) {
        locationInfo.selectedAppointmentType = locationInfo.appointmentTypes[0];
        Session.setItem(Constants.locationInfoKey, locationInfo);
        update({ locationInfo: locationInfo });

        this.setState(() => ({
          selectedAppointmentTypeId:
            locationInfo.selectedAppointmentType.locationAppointmentTypeId,
        }));
      }

      this.setState(() => ({ showLoadingOverlay: true }));
      const getAvailabilityApi = `${process.env.REACT_APP_AVAILABILITY_API}/getAvailability`;

      const data = {
        resourceId: resource.resourceId ?? locationInfo.resources[0].resourceId,
        locationId: locationId,
        appointmentTypeId:
          locationInfo.selectedAppointmentType.locationAppointmentTypeId,
        date: momentLocaleWrapper(date).format('(YYYY, MM, DD, 00, 00)'),
        numDaysToRetrieve: 1,
      };

      const result = await axios.post(getAvailabilityApi, data);

      if (result?.data) {
        const bookingInfoSession = bookingInfo;
        bookingInfoSession.selectedDate = date;
        bookingInfoSession.timesAvailable = result.data.slotDates;

        Session.setItem(Constants.bookingInfoKey, bookingInfoSession);

        update({
          ...this.context,
          bookingInfo: {
            ...bookingInfo,
            selectedDate: date,
            timesAvailable: result.data.slotDates,
          },
        });

        this.setState(() => ({
          showLoadingOverlay: false,
          isDateCurrent:
            momentLocaleWrapper().format('DD-MM-YYYY') ===
            momentLocaleWrapper(date).format('DD-MM-YYYY'),
        }));
      }
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        this.setState(() => ({
          showLoadingOverlay: false,
          errorMessage: t(
            'An unexpected issue occurred while retrieving the location info'
          ),
        }));
      }
    }
  }

  _getSlots = () => {
    const { bookingInfo, locationInfo } = this.context;
    const { timesAvailable } = bookingInfo;
    const { t } = this.props;
    const holidays = locationInfo.appliedHolidays.map((appliedHoliday) => ({
      startMoment: moment(appliedHoliday.startDate, '(YYYY, MM, DD, hh, mm)'),
      endMoment: moment(appliedHoliday.endDate, '(YYYY, MM, DD, hh, mm)'),
    }));
    const timesBtn = timesAvailable
      .filter((ta) => {
        const taMoment = moment(ta, '(YYYY, MM, DD, hh, mm)');
        return !holidays.some(
          (holiday) =>
            taMoment.isSameOrAfter(holiday.startMoment) &&
            taMoment.isSameOrBefore(holiday.endMoment)
        );
      })
      .map((ta, key) => (
        <button
          className="booking__button"
          key={key}
          onClick={() => {
            this.handleTimeSlotClick(ta);
          }}
        >
          {momentLocaleWrapper(ta, '(YYYY, MM, DD, hh, mm)').format('LT')}
        </button>
      ));

    return timesBtn.length === 0 ? (
      <div className="no-time-slot">{t('No Available Appointments')}</div>
    ) : (
      timesBtn
    );
  };

  handleTimeSlotClick = (timeslot) => {
    const { update, bookingInfo, locationInfo } = this.context;

    const { selectedAppointmentTypeId } = this.state;

    let bookingInfoSession = bookingInfo;
    bookingInfoSession.selectedTime = timeslot;
    Session.setItem(Constants.bookingInfoKey, bookingInfoSession);

    if (
      locationInfo.selectedAppointmentType == null ||
      locationInfo.selectedAppointmentType.locationAppointmentTypeId !==
        selectedAppointmentTypeId
    ) {
      //This means the dropdown is not clicked, choose the displayed/first appointment type
      locationInfo.selectedAppointmentType = locationInfo.appointmentTypes.find(
        (x) => x.locationAppointmentTypeId === selectedAppointmentTypeId
      );
      Session.setItem(Constants.locationInfoKey, locationInfo);
      update({
        ...this.context,
        bookingInfo: {
          ...bookingInfo,
          selectedTime: timeslot,
        },
        locationInfo: locationInfo,
      });
    } else {
      update({
        ...this.context,
        bookingInfo: {
          ...bookingInfo,
          selectedTime: timeslot,
        },
      });
    }

    eventBuilder
      .withAction(eventBuilder.Action.Click.appointmentTimeListing)
      .withLabel(eventBuilder.Label.practiceIdentifierAndDateTime)
      .post();

    this.context.navigateTo(
      Constants.bookPersonalInfoPageRoute,
      this.props.history
    );
  };

  handleCalendarChange = (date) => {
    const { bookingInfo, update } = this.context;

    let bookingInfoSession = bookingInfo;
    bookingInfoSession.selectedDate = date;
    Session.setItem(Constants.bookingInfoKey, bookingInfoSession);

    update({
      ...this.context,
      bookingInfo: {
        ...bookingInfo,
        selectedDate: date,
      },
    });
    this.getTimeSlot(date);

    eventBuilder.withAction(eventBuilder.Action.Click.chooseDate).post();

    pageviewBuilder.pageview(pageviewBuilder.Page.Booking.dates);
  };

  handleCalendarChangeCustomButtons = (action) => {
    const { bookingInfo, update } = this.context;

    let date;
    if (action === 'next') {
      date = momentLocaleWrapper(bookingInfo.selectedDate).add(1, 'days');
      eventBuilder.withAction(
        eventBuilder.Action.Click.chooseDateScrollForward
      );
    } else {
      date = momentLocaleWrapper(bookingInfo.selectedDate).subtract(1, 'days');
      eventBuilder.withAction(
        eventBuilder.Action.Click.chooseDateScrollBackward
      );
    }

    let bookingInfoSession = bookingInfo;
    bookingInfoSession.selectedDate = date;
    Session.setItem(Constants.bookingInfoKey, bookingInfoSession);

    update({
      ...this.context,
      bookingInfo: {
        ...bookingInfo,
        selectedDate: date,
      },
    });
    this.getTimeSlot(date);

    eventBuilder.post();
  };

  onAppointmentTypeChange = async (apptTypeId) => {
    const { locationInfo, update } = this.context;

    const selectedAppointmentType = locationInfo.appointmentTypes.find(
      (x) => x.locationAppointmentTypeId === apptTypeId
    );
    locationInfo.selectedAppointmentType = selectedAppointmentType;
    Session.setItem(Constants.locationInfoKey, locationInfo);
    update({ locationInfo: locationInfo });

    await this.getTimeSlot(this.context.bookingInfo.selectedDate);

    this.setState(() => ({
      selectedAppointmentTypeId:
        selectedAppointmentType.locationAppointmentTypeId,
    }));

    eventBuilder
      .withAction(
        eventBuilder.Action.Click.reason,
        selectedAppointmentType.displayName
      )
      .post();
  };

  /**
   * Renders the component.
   */
  render() {
    const { showLoadingOverlay, isDateCurrent, selectedAppointmentTypeId } =
      this.state;
    const { t } = this.props;
    const { bookingInfo, locationInfo, minimalLocationInfo } = this.context;
    const { selectedDate, resource, isFirstAvailable } = bookingInfo;
    const { googleReviewInfo } = minimalLocationInfo;
    const rating = googleReviewInfo?.rating;
    const numberOfUserRatings = googleReviewInfo?.numberOfUserRatings;
    const viewReviewUrl = googleReviewInfo?.viewReviewUrl;
    const placeId = googleReviewInfo?.placeId;

    if (!locationInfo?.locationId) {
      return null;
    }
    const appointmentTypeItems = locationInfo.appointmentTypes.map(
      (at, key) => (
        <MenuItem key={key} value={at.locationAppointmentTypeId}>
          {at.displayName}
        </MenuItem>
      )
    );
    const hasResourceDetails =
      resource.displayName !== Constants.defaultResourceDisplayName &&
      locationInfo.resources.length > 1 &&
      isFirstAvailable === false;

    return (
      <div className="page">
        <section className="booking__header">
          <Header
            eventBuilder={eventBuilder}
            onNavigateBack={this.onNavigateBack}
          />
        </section>
        <section className="booking__content">
          <h1 className="booking__title">{t('Resource')}</h1>
          <h2 className="booking__subtitle">{t('Schedule your visit with')}</h2>
          <h2 className="booking__subtitle">
            {locationInfo.storeInformation.name}
          </h2>
          {placeId && (
            <div className="booking__subtitle grating">
              <GoogleRating
                ratings={rating}
                numberOfRatings={numberOfUserRatings}
                googleRatingUrl={viewReviewUrl}
              />
            </div>
          )}
          {hasResourceDetails ? (
            <Fragment>
              <h4 className="booking__resource-info">{t('Resource')}</h4>
              <h4 className="booking__resource-value">
                {resource.displayName === Constants.defaultResourceDisplayName
                  ? t(resource.displayName)
                  : resource.displayName}
              </h4>
            </Fragment>
          ) : null}
          <div
            className={
              hasResourceDetails
                ? 'booking__body-hasresource'
                : 'booking__body-noresource'
            }
          >
            <h4 className="booking__reason">
              {t('What is the reason for your visit?')}
            </h4>
            <div className="booking-servicetype">
              <FormControl
                className="booking-servicetype__options"
                variant="outlined"
              >
                <Select
                  className="booking-servicetype__input booking-servicetype__input--select"
                  id="booking-servicetype-appttype"
                  required
                  labelId="booking-servicetype-label"
                  variant="outlined"
                  value={selectedAppointmentTypeId}
                  onChange={(e) =>
                    this.onAppointmentTypeChange(parseInt(e.target.value))
                  }
                >
                  {appointmentTypeItems}
                </Select>
              </FormControl>
            </div>
            <h4 className="booking__appt-day">
              {t('When would you like to be seen?')}
            </h4>
            <div className="booking__calendar-container">
              <div
                className="booking__prev"
                onClick={
                  !isDateCurrent
                    ? () => this.handleCalendarChangeCustomButtons('prev')
                    : () => {}
                }
              >
                &lt;
              </div>
              <div className="booking__calendar">
                <MuiPickersUtilsProvider
                  libInstance={momentLocaleWrapper}
                  locale={momentLocaleWrapper.locale()}
                  utils={MomentUtils}
                >
                  <DatePicker
                    minDate={new Date()}
                    disableToolbar
                    required
                    className="booking-calendar__input"
                    format="ddd, L"
                    margin="normal"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={selectedDate}
                    onChange={this.handleCalendarChange}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div
                className="booking__next"
                onClick={() => this.handleCalendarChangeCustomButtons('next')}
              >
                &gt;
              </div>
            </div>
            <div className="booking__slots">{this._getSlots()}</div>
          </div>
        </section>
        <section className="booking__footer">
          <Footer showActions={false} />
        </section>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

Booking.contextType = DataContext;

export default withTranslation()(Booking);
