import axios from 'axios';
import Session from './session';
import { v4 as uuidv4 } from 'uuid';
import Constants from './constants';

(() => {
  // Setup axios to pass the jwt for each request.
  axios.interceptors.request.use((config) => {
    let sessId = Session.getItem(Constants.sessIdKey);
    if (!sessId) {
      sessId = uuidv4();
      Session.setItem(Constants.sessIdKey, sessId);
    }
    config.headers['x-SessionId'] = sessId;
    return config;
  });
})();
