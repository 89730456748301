import { ClickAwayListener } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MuiTooltip from '@material-ui/core/Tooltip';
import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import GoogleRating from '../../Components/GoogleRating/GoogleRating';
import Constants from '../../constants';
import DataContext from '../../Contexts/DataContext';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import RegEx from '../../regex';
import VoucherService from '../../Services/voucherService';
import Session from '../../session';
import Storage from '../../storage';
import Utils from '../../utils';
import './PersonalInfo.scss';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

class MessagingSmsType {
  static get whatsApp() {
    return 2;
  }

  static get smsText() {
    return 1;
  }
}

/**
 * Represents the personal info page of the consumer app.
 */
class PersonalInfo extends Component {
  /**
   * Initializes a new instance of the PersonalInfo component.
   * @param {Object} props The component properties.
   */
  constructor(props, context) {
    super(props);

    this._formErrorSetClean = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    };

    this._allowedPhoneChars = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      ' ',
      '(',
      ')',
      '-',
      '.',
    ];
    this._phoneRegex = new RegExp('^.{3,18}$'); // any chars between 3 and 18 total
    this._phoneMask = '';

    const isWhatsAppNotificationsEnabled =
      context.locationInfo?.countrySettings?.some(
        (cs) =>
          cs.settingName === Constants.settingIsWhatsAppNotificationsEnabled &&
          cs.settingValue === true
      ) || false;

    const getDefaultMessagingSmsType = () => {
      if (isWhatsAppNotificationsEnabled) {
        return (
          context.locationInfo?.countrySettings?.find(
            (cs) => cs.settingName === Constants.settingDefaultMessagingPlatform
          )?.settingValue || MessagingSmsType.whatsApp
        );
      } else {
        return MessagingSmsType.smsText;
      }
    };
    const defaultMessagingSmsType = (this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      voucher: '',
      voucherAllowed: false,
      formErrors: cloneDeep(this._formErrorSetClean),
      showPhoneTooltip: false,
      messagingSmsType: getDefaultMessagingSmsType(),
      isWhatsAppNotificationsEnabled,
    });

    this._voucherService = new VoucherService();
    eventBuilder
      .withCategory(eventBuilder.Category.Waitlist.infoPage)
      .withLabel(eventBuilder.Label.practiceIdentifier);
  }

  /**
   * Executes when the component mounts to the DOM.
   */
  componentDidMount() {
    const { locationInfo } = this.context;
    const { messagingSmsType } = this.state;

    if (!locationInfo) {
      this.props.history.push(Constants.homeRoute);
    }

    const consumerInfo = Session.getItem(Constants.consumerInfoKey);

    if (consumerInfo) {
      const { firstName, lastName, phone, email } = consumerInfo;
      this.setState(() => ({
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
      }));
    }

    if (this._isWaitlist()) {
      pageviewBuilder.pageview(pageviewBuilder.Page.Waitlist.info);
    } else {
      eventBuilder.withCategory(eventBuilder.Category.Booking.infoPage);
      pageviewBuilder.pageview(pageviewBuilder.Page.Booking.info);
    }

    const { locationGroup } = locationInfo.storeInformation;

    if (locationGroup) {
      const vouchers = locationInfo.vouchers;
      const showVoucherField = this._voucherService.showVoucherField(
        vouchers,
        locationGroup
      );

      this.setState(() => ({
        voucherAllowed: Utils.isVoucherAllowed(locationGroup),
        showVoucherField: showVoucherField,
      }));
    }

    const code = Storage.getItem(Constants.voucherStorageKey);

    if (code) {
      this.setState(() => ({ voucher: code }));
    }

    if (this._showSmsWarningCheck(messagingSmsType)) {
      this.setState(() => ({ displaySmsOneWayWarning: true }));
    }
  }

  _isFormValid = async () => {
    let isValid = true;
    const { firstName, lastName, phone, email, voucher, voucherAllowed } =
      this.state;

    const { locationInfo, bookingInfo } = this.context;
    const { selectedTime } = bookingInfo;

    const formErrors = cloneDeep(this._formErrorSetClean);

    const { t } = this.props;

    if (!firstName) {
      isValid = false;
      formErrors.firstName = t('First Name is required');
    }

    if (!lastName) {
      isValid = false;
      formErrors.lastName = t('Last Name is required');
    }

    if (!phone) {
      isValid = false;
      formErrors.phone = t('Phone Number is required');
    } else {
      const isPhoneValid = (phone) => {
        let valid = true;

        if (!this._phoneRegex.test(phone)) {
          valid = false;
          return;
        }

        for (let index = 0; index < phone.length; ++index) {
          if (!this._allowedPhoneChars.includes(phone[index])) {
            valid = false;
            break;
          }
        }

        return valid;
      };

      if (!isPhoneValid(phone)) {
        isValid = false;
        formErrors.phone = t('Please enter a valid phone number');
      }
    }

    if (email && !RegEx.email.test(email)) {
      isValid = false;
      formErrors.email = t('Please enter a valid email');
    }

    //execute validation here
    if (voucher && voucherAllowed) {
      const startTime = selectedTime ? selectedTime : null;
      //make call here, locationId will be converted to locationGroup in the API call
      const res = await this._voucherService.ValidateVoucherCode(
        voucher.toUpperCase(),
        startTime,
        locationInfo.locationId
      );
      if (res && !res.success) {
        isValid = false;
        formErrors.voucher = t('Invalid or expired voucher code');
      }
    }

    this.setState(() => ({ formErrors: formErrors }));

    return isValid;
  };

  _onFormFieldChange = (value, path) => {
    this.setState((prevState) => {
      let formErrors = { ...prevState.formErrors };
      formErrors.email = '';
      const updatedState = { ...prevState, formErrors };
      Utils.update(updatedState, path, value);

      return {
        ...updatedState,
      };
    });
  };

  /**
   * Executes when the user navigates back to the previous page.
   */
  onNavigateBack = () => {
    this.context.navigateTo(
      Constants.bookAppointmentPageRoute,
      this.props.history
    );
  };

  _onSeeDetails = async () => {
    if (await this._isFormValid()) {
      const { firstName, lastName, phone, email, messagingSmsType, voucher } =
        this.state;
      const consumerInfo = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
        messagingSmsType: messagingSmsType,
      };

      eventBuilder
        .withAction(eventBuilder.Action.Click.seeDetailResults)
        .post();

      Session.setItem(Constants.consumerInfoKey, consumerInfo);
      Storage.setItem(Constants.voucherStorageKey, voucher);

      this.context.update({ consumerInfo: consumerInfo });
      this.context.navigateTo(Constants.detailsPageRoute, this.props.history);
    }
  };

  _onSmsTypeChange = (type) => {
    this.setState(() => ({
      messagingSmsType: type,
      displaySmsOneWayWarning: this._showSmsWarningCheck(type),
    }));
  };

  _showSmsWarningCheck = (type) => {
    const isSmsOneWay =
      this.context.locationInfo?.countrySettings?.some(
        (cs) =>
          cs.settingName === Constants.settingIsSmsOneWay &&
          cs.settingValue === true
      ) || false;
    return isSmsOneWay && type === MessagingSmsType.smsText;
  };

  _onTogglePhoneTooltip = () => {
    eventBuilder.withAction(eventBuilder.Action.Click.infoIconPhone).post();
    this.setState((prevState) => ({
      showPhoneTooltip: !prevState.showPhoneTooltip,
    }));
  };

  _onClosePhoneTooltip = () => {
    this.setState(() => ({ showPhoneTooltip: false }));
  };

  _isWaitlist = () => {
    return this.context.journey === Constants.waitlist;
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      firstName,
      lastName,
      phone,
      email,
      voucher,
      showVoucherField,
      formErrors,
      showPhoneTooltip,
      messagingSmsType,
      displaySmsOneWayWarning,
      isWhatsAppNotificationsEnabled,
    } = this.state;
    const { phoneCountryCode, storeInformation, uniqueLocationId } =
      this.context.locationInfo;
    const { languageTag } = storeInformation;
    const { t } = this.props;
    const { minimalLocationInfo } = this.context;
    const { googleReviewInfo } = minimalLocationInfo;
    const rating = googleReviewInfo?.rating;
    const numberOfUserRatings = googleReviewInfo?.numberOfUserRatings;
    const viewReviewUrl = googleReviewInfo?.viewReviewUrl;
    const placeId = googleReviewInfo?.placeId;

    return (
      <div className="page">
        <section className="personal-info__header">
          <Header
            eventBuilder={eventBuilder}
            onNavigateBack={this.onNavigateBack}
          />
        </section>
        <section className="personal-info__content">
          <h1 className="personal-info__title">{t('Exam Type')}</h1>
          <h2 className="personal-info__subtitle">
            {this._isWaitlist()
              ? t('Add to waitlist')
              : t('Schedule your visit with')}
          </h2>
          <h2 className="personal-info__subtitle">{storeInformation.name}</h2>
          {placeId && (
            <div className="personal-info__subtitle grating">
              <GoogleRating
                ratings={rating}
                numberOfRatings={numberOfUserRatings}
                googleRatingUrl={viewReviewUrl}
              />
            </div>
          )}
          <p className="personal-info__info">
            {t(
              'We just need basic information to send you upcoming visit reminders'
            )}
          </p>
          <p className="personal-info__subinfo">
            {ReactHtmlParser(
              t(
                'We always ensure your data is secure and never share your personal information with any third parties.'
              )
            )}
          </p>
          <form className="personal-info__form">
            <div className="personal-info__row">
              <div className="personal-info__input-cont">
                <TextField
                  className="personal-info__input"
                  label={t('First Name')}
                  placeholder={t('Default First Name')}
                  value={firstName}
                  variant="outlined"
                  onChange={(e) =>
                    this._onFormFieldChange(e.target.value, ['firstName'])
                  }
                />
                <span
                  className={`personal-info__error ${
                    formErrors.firstName ? 'personal-info__error--visible' : ''
                  }`}
                >
                  {formErrors.firstName}
                </span>
              </div>
              <div className="personal-info__input-cont">
                <TextField
                  className="personal-info__input"
                  label={t('Last Name')}
                  placeholder={t('Default Last Name')}
                  value={lastName}
                  variant="outlined"
                  onChange={(e) =>
                    this._onFormFieldChange(e.target.value, ['lastName'])
                  }
                />
                <span
                  className={`personal-info__error ${
                    formErrors.lastName ? 'personal-info__error--visible' : ''
                  }`}
                >
                  {formErrors.lastName}
                </span>
              </div>
            </div>
            <div className="personal-info__row">
              <div className="personal-info__input-cont">
                <TextField
                  className="personal-info__input personal-info__input--phone"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {phoneCountryCode}
                      </InputAdornment>
                    ),
                  }}
                  label={t('Phone Number')}
                  placeholder={t('Default Phone Format')}
                  type="tel"
                  value={phone}
                  variant="outlined"
                  onChange={(e) =>
                    this._onFormFieldChange(e.target.value, ['phone'])
                  }
                />
                <ClickAwayListener onClickAway={this._onClosePhoneTooltip}>
                  <button
                    className="personal-info__input-icon"
                    type="button"
                    onClick={this._onTogglePhoneTooltip}
                  >
                    <Tooltip
                      open={showPhoneTooltip}
                      placement="top-end"
                      title={t(
                        'We collect this information solely for the purpose of sending you updates on your appointment. This information is not shared with any third party.'
                      )}
                    >
                      <span className="personal-info__tooltip">i</span>
                    </Tooltip>
                  </button>
                </ClickAwayListener>
                <span
                  className={`personal-info__error ${
                    formErrors.phone ? 'personal-info__error--visible' : ''
                  }`}
                >
                  {formErrors.phone}
                </span>
              </div>
              <div className="personal-info__input-cont">
                <TextField
                  className="personal-info__input"
                  label={t('Email') + ' (' + t('Optional') + ')'}
                  type="email"
                  value={email}
                  variant="outlined"
                  onChange={(e) =>
                    this._onFormFieldChange(e.target.value, ['email'])
                  }
                />
                <span
                  className={`personal-info__error ${
                    formErrors.email ? 'personal-info__error--visible' : ''
                  }`}
                >
                  {formErrors.email}
                </span>
              </div>

              {showVoucherField && (
                <div className="personal-info__input-cont">
                  <TextField
                    className="personal-info__input"
                    label={t('Voucher Code')}
                    value={voucher}
                    variant="outlined"
                    onChange={(e) =>
                      this._onFormFieldChange(e.target.value, ['voucher'])
                    }
                  />
                  <span
                    className={`personal-info__error ${
                      formErrors.voucher ? 'personal-info__error--visible' : ''
                    }`}
                  >
                    {formErrors.voucher}
                  </span>
                </div>
              )}
            </div>
            {isWhatsAppNotificationsEnabled && (
              <div className="personal-info__row personal-info__row--row">
                <label className="personal-info__sms-label">
                  {t('Receive your appointment reminders on')}:
                </label>
                <RadioGroup
                  row
                  value={messagingSmsType}
                  onChange={(e) =>
                    this._onSmsTypeChange(parseInt(e.target.value))
                  }
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={t('WhatsApp')}
                    labelPlacement="end"
                    value={MessagingSmsType.whatsApp}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={t('SMS Texting')}
                    labelPlacement="end"
                    value={MessagingSmsType.smsText}
                  />
                  {displaySmsOneWayWarning && (
                    <p className="personal-info__sms-one-way-warning personal-info__error personal-info__error--visible">
                      {t(
                        'Notice: Please note that you are choosing SMS as your preferred channel of communication, this will prohibit you from chatting back and forth with the eye care provider and limits you to receiving 1 way messages from the location.'
                      )}
                    </p>
                  )}
                </RadioGroup>
              </div>
            )}
            {languageTag !== Constants.turkeyLanguageTag && (
              <p className="personal-info__privacy">
                {t('By using this platform, you agree to our')}&nbsp;
                <a
                  href={`/terms?uniqueLocationId=${uniqueLocationId}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('Terms of Use')}
                </a>
                &nbsp;{t('and')}&nbsp;
                <a
                  href={`/privacy?uniqueLocationId=${uniqueLocationId}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('Privacy Policy')}
                </a>
              </p>
            )}
            <div className="personal-info__see-details-cont">
              <button
                className="personal-info__see-details"
                type="button"
                disabled={this.state.showRequiredMessage}
                onClick={this._onSeeDetails}
              >
                {t('Review Details')}
              </button>
              {this.state.showRequiredMessage && (
                <p className="personal-info__see-details-warning">
                  {t(
                    'Agreement is required before we can book your appointment'
                  )}
                </p>
              )}
            </div>
          </form>
        </section>
        <section className="personal-info__footer">
          <Footer showActions={false} />
        </section>
      </div>
    );
  }
}

PersonalInfo.contextType = DataContext;

export default withTranslation()(PersonalInfo);
