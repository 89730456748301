import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Session from '../../session';
import Constants from '../../constants';
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import MuiTooltip from '@material-ui/core/Tooltip';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import './StellestReschedule.scss';
import EbnLogo from '../../Assets/Images/EyeBookNow-Logo.png';
import CalendarIcon from '../../Assets/Images/Generic-Calendar-Icon.png';
import AppointmentService from '../../Services/appointmentService';
import StellestLogoWhite from '../../Assets/Images/Stellest-logo-white.png';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import moment from 'moment';
import Enums from '../../enums';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import Overlay from '../../Components/Overlay/Overlay';
import Events from '../../events';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

class StellestReschedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationUrl: `${process.env.REACT_APP_APPOINTMENT_API}/updateAndConfirmAppointment`,
      disableDownloadIcsButton: true,
      getConfirmationInfoUrl: `${process.env.REACT_APP_STELLEST_GET_CONFIRMATION_API}`,
      readyToLoad: false,
      showIcsDownloadTooltip: false,
      locationInfo: Session.getItem(Constants.locationInfoKey),
      showBooking: false,
      showLoadingOverlay: true,
    };
    this._appointmentService = new AppointmentService();
  }

  async componentDidMount() {
    this.setupSubscriptions();
    if (this.state.locationInfo !== null) {
      await this.initComponent();
      this.setState({ showLoadingOverlay: false });
    }
  }

  async initComponent() {
    const { getConfirmationInfoUrl, locationInfo } = this.state;
    const { uniqueLocationId } = locationInfo;

    const urlParams = new URLSearchParams(window.location.search);
    const confirmationId = urlParams.get('guid');

    const nextPageUrl = `confirm?guid=${confirmationId}&confirmed=true`;

    const getConfirmationUrl = `${getConfirmationInfoUrl}/${uniqueLocationId}?confirmationId=${confirmationId}`;

    const { languageTag } = locationInfo.storeInformation;

    if (languageTag && languageTag.length) {
      momentLocaleWrapper.locale(languageTag);
    }

    if (confirmationId) {
      //get appointment info via confirmation
      await axios
        .get(getConfirmationUrl)
        .then((response) => {
          this.setState({
            nextPageUrl: nextPageUrl,
            appointment: response.data.appointment,
            locationInfo: locationInfo,
          });
        })
        .catch((err) => {
          console.error(err);
        });

      //Get time Slot at first
      await this.getTimeSlot(this.state.appointment.startTime);
      this.setState({ readyToLoad: true });
    }
  }

  setupSubscriptions = () => {
    //if this is subscribed to after the event is emitted, then we already have the data. if it is subscribed to before the event emits then this will be called.
    Events.on(Constants.Events.LocationInfoLoaded, async () => {
      this.setState({
        showLoadingOverlay: true,
        locationInfo: Session.getItem(Constants.locationInfoKey),
      });
      await this.initComponent();
      this.setState({ showLoadingOverlay: false });
    });
  };

  async getTimeSlot(date) {
    const { t } = this.props;

    const { resource, appointmentType, locationId } = this.state.appointment;

    try {
      this.setState(() => ({ showLoadingOverlay: true }));
      const getAvailabilityApi = `${process.env.REACT_APP_AVAILABILITY_API}/getAvailability`;

      const data = {
        resourceId: resource.resourceId,
        locationId: locationId,
        appointmentTypeId: appointmentType.locationAppointmentTypeId,
        date: momentLocaleWrapper(date).format('(YYYY, MM, DD, 00, 00)'),
        numDaysToRetrieve: 1,
      };

      const result = await axios.post(getAvailabilityApi, data);

      if (result?.data) {
        this.setState(() => ({
          showLoadingOverlay: false,
          isDateCurrent:
            momentLocaleWrapper().format('DD-MM-YYYY') ===
            momentLocaleWrapper(date).format('DD-MM-YYYY'),
          selectedDate: date,
          timesAvailable: result.data.slotDates,
        }));
      }
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        this.setState(() => ({
          showLoadingOverlay: false,
          errorMessage: t(
            'An unexpected issue occurred while retrieving the location info'
          ),
        }));
      }
    }
  }

  _getSlots = () => {
    const { timesAvailable, locationInfo } = this.state;
    //const { timesAvailable } = bookingInfo;
    const { t } = this.props;
    const holidays = locationInfo.appliedHolidays.map((appliedHoliday) => ({
      startMoment: moment(appliedHoliday.startDate, '(YYYY, MM, DD, hh, mm)'),
      endMoment: moment(appliedHoliday.endDate, '(YYYY, MM, DD, hh, mm)'),
    }));
    const timesBtn = timesAvailable
      .filter((ta) => {
        const taMoment = moment(ta, '(YYYY, MM, DD, hh, mm)');
        return !holidays.some(
          (holiday) =>
            taMoment.isSameOrAfter(holiday.startMoment) &&
            taMoment.isSameOrBefore(holiday.endMoment)
        );
      })
      .map((ta, key) => (
        <button
          className="reschedule__button"
          key={key}
          onClick={() => {
            this.handleTimeSlotClick(ta);
          }}
        >
          {momentLocaleWrapper(ta, '(YYYY, MM, DD, hh, mm)').format('LT')}
        </button>
      ));

    return timesBtn.length === 0 ? (
      <div className="no-time-slot">{t('No Available Appointments')}</div>
    ) : (
      timesBtn
    );
  };

  handleTimeSlotClick = async (timeslot) => {
    const { nextPageUrl, appointment, confirmationUrl, locationInfo } =
      this.state;
    const { uniqueLocationId } = locationInfo;
    const urlParams = new URLSearchParams(window.location.search);
    const confirmationId = urlParams.get('guid');

    //post call to update/confirm appointment
    const url = `${confirmationUrl}/${uniqueLocationId}?confirmationId=${confirmationId}&appointmentId=${appointment.appointmentId}`;

    const data = {
      appointment: {
        locationId: appointment.locationId,
        patient: appointment.patient,
        created: appointment.created,
        startTime: timeslot,
        endTime: null,
        appointmentType: appointment.appointmentType,
        swimlane: {
          description: 'schedule',
          swimlaneId: Enums.Swimlanes.queued,
        },
        notes: appointment.notes,
        resource: appointment.resource,
        currentStatus: appointment.currentStatus,
        patient: {
          preferredPlatform: appointment.patient.preferredPlatform,
        },
        voucherCode: appointment.voucherCode,
      },
    };
    await axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          //navigate to next page
          window.location = `${nextPageUrl}`;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleCalendarChange = (date) => {
    this.getTimeSlot(date);

    eventBuilder.withAction(eventBuilder.Action.Click.chooseDate).post();

    pageviewBuilder.pageview(pageviewBuilder.Page.StellestReschedule.dates);
  };

  handleCalendarChangeCustomButtons = (action) => {
    const { selectedDate } = this.state;

    let date;
    if (action === 'next') {
      date = momentLocaleWrapper(selectedDate).add(1, 'days');
      eventBuilder.withAction(
        eventBuilder.Action.Click.chooseDateScrollForward
      );
    } else {
      date = momentLocaleWrapper(selectedDate).subtract(1, 'days');
      eventBuilder.withAction(
        eventBuilder.Action.Click.chooseDateScrollBackward
      );
    }

    this.getTimeSlot(date);

    eventBuilder.post();
  };

  _onAlternateDateTimeClick = () => {
    this.setState({ showBooking: true });
  };

  render() {
    const {
      readyToLoad,
      locationInfo,
      appointment,
      showBooking,
      isDateCurrent,
      selectedDate,
      timesAvailable,
      showLoadingOverlay,
    } = this.state;

    const { t } = this.props;

    if (readyToLoad) {
      const { address, name, phone } = locationInfo.storeInformation;

      const { startTime } = appointment;

      let summarySlots = [];
      if (timesAvailable.length === 0) {
        summarySlots = this._getSlots();
      } else {
        summarySlots = this._getSlots().slice(0, 4);
      }

      return (
        <div className="reschedule">
          <div className="reschedule__header">
            <img
              className="reschedule__stellest-logo"
              src={StellestLogoWhite}
              alt="Stellest Logo"
            />
            <p>Follow up appointment was confirmed and scheduled using:</p>
            <img
              className="reschedule__ebn-logo"
              src={EbnLogo}
              alt="Eye Book Now logo"
            />
          </div>
          {!showBooking && (
            <section className="reschedule__basic">
              <div className="reschedule__title">
                <div className="reschedule__title-contents">
                  <div className="reschedule__icon-container">
                    <img src={CalendarIcon} alt="thumbs up" />
                  </div>
                  <h2>We understand that life can be complicated...</h2>
                  <p>
                    Here are some available times for your appointment date{' '}
                    {momentLocaleWrapper(startTime).format('LL')}:
                  </p>
                  <div className="reschedule__title-suggested-slots">
                    {summarySlots}
                  </div>
                </div>
              </div>
              <div className="reschedule__content">
                <hr />
                <button
                  className="alt-button"
                  onClick={this._onAlternateDateTimeClick}
                >
                  {'Choose From Alternate Days & Times'}
                </button>
                <p>
                  <b>{name}</b> - {address} <b>{phone}</b>
                </p>
              </div>
            </section>
          )}
          {showBooking && (
            <section className="reschedule__booking">
              <div className="reschedule__title">
                <div className="reschedule__title-booking-contents">
                  <h1>
                    Here are some additional days and times to reschedule.
                  </h1>
                </div>
              </div>
              <div className="reschedule__booking__body">
                <h4 className="reschedule__booking__appt-day">
                  {t('Day of Appointment:')}
                </h4>
                <div className="reschedule__booking__calendar-container">
                  <div
                    className="reschedule__booking__prev"
                    onClick={
                      !isDateCurrent
                        ? () => this.handleCalendarChangeCustomButtons('prev')
                        : () => {}
                    }
                  >
                    &lt;
                  </div>
                  <div className="reschedule__booking__calendar">
                    <MuiPickersUtilsProvider
                      libInstance={momentLocaleWrapper}
                      locale={momentLocaleWrapper.locale()}
                      utils={MomentUtils}
                    >
                      <DatePicker
                        minDate={new Date()}
                        disableToolbar
                        required
                        className="reschedule__booking-calendar__input"
                        format="ddd, L"
                        margin="normal"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        value={selectedDate}
                        onChange={this.handleCalendarChange}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div
                    className="reschedule__booking__next"
                    onClick={() =>
                      this.handleCalendarChangeCustomButtons('next')
                    }
                  >
                    &gt;
                  </div>
                </div>
                <h4 className="reschedule__booking__times-avail">
                  {t('Times Available:')}
                </h4>
                <div className="reschedule__booking__slots">
                  {this._getSlots()}
                </div>
              </div>
              <div className="reschedule__booking__location-info">
                <p>
                  <b>{name}</b> - {address} <b>{phone}</b>
                </p>
              </div>
            </section>
          )}
          <div className="reschedule__footer">
            <p>Powered by Essilor</p>
          </div>
          <Overlay show={showLoadingOverlay}>
            <i className="spinner-eclipse"></i>
          </Overlay>
        </div>
      );
    }
    return (
      <div>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(StellestReschedule);
