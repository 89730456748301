import i18n from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import Session from './session';
import Constants from './constants';

const jsonVersion = `${
  process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT === 'production' ||
  process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT === 'qa'
    ? `?v=${uuidv4()}`
    : ''
}`;

/**
 * Represents a wrapper around i18n.
 */
class I18n {
  /**
   * Changes the current language.
   * @param {String} lang The language to change to.
   */
  static async changeLanguage(lang) {
    return await i18n.changeLanguage(lang);
  }

  /**
   * Initializes the i18n setup.
   */
  static init() {
    i18n
      // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(Backend)

      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        backend: {
          loadPath: () => {
            const url = Session.getItem(Constants.currTranslationUrlKey);

            if (url) {
              return `${url}${jsonVersion}`;
            }

            return '';
          },
        },
        fallbackLng: 'en-US',
        debug: process.env.REACT_APP_TRANSLATIONS_ENVIRONMENT === 'development',
        nsSeparator: false,
        keySeparator: false,
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        whitelist: [
          'en-US',
          'en-IN',
          'es',
          'pt',
          'en-AE',
          'en-BN',
          'en-JP',
          'en-KR',
          'en-MY',
          'en-PH',
          'en-RU',
          'en-SG',
          'tr-TR',
          'tr',
          'en',
          'zh-TW',
          'zh-HK',
        ],
        react: {
          wait: true,
          bindI18n: 'languageChanged loaded',
        },
      });
  }
}

export default I18n;
