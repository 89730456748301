import axios from 'axios';
import Utils from '../utils';
import Constants from '../constants';

/**
 * Represents the location configuration service.
 */
class LocationConfigService {
  /**
   * Returns the location configuration or group info (multiple locations).
   */
  async getLocationAndGroupInfoData(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          siteConfigUrl: params.siteConfigUrl,
          uniqueLocationId: params.uniqueLocationId,
        };
        const url = `${process.env.REACT_APP_LOCATIONINFO_API}/loadLocPage`;
        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async isStellestEnabled(params) {
    return new Promise(async (resolve, reject) => {
      try {
        this.getLocationAndGroupInfoData(params)
          .then((locAndGroupInfoData) => {
            const { getLocationAndGroupInfoResponse } = locAndGroupInfoData;
            const { locationInfo } = getLocationAndGroupInfoResponse;

            var isStellestEnabled = locationInfo.locationSettings.some(
              (locationSetting) =>
                locationSetting.displayName ===
                  Constants.settingIsStellestEnabled &&
                locationSetting.settingValue === true
            );
            var result = {
              stellestEnabled: isStellestEnabled,
              languageTag: locationInfo.storeInformation.languageTag,
            };
            resolve(result);
          })
          .catch((error) => {
            console.error(error);
            resolve({ stellestEnabled: false, languageTag: 'en' });
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns the location configuration or group info (multiple locations).
   */
  async getLocationOrGroupInfo() {
    return new Promise(async (resolve, reject) => {
      try {
        const uniqueLocationId = Utils.getUniqueLocationIdFromUrlPathName(
          window.location.pathname
        );
        const data = {
          uniqueLocationId: uniqueLocationId,
          includeQueueInfo: true,
        };
        const url = `${process.env.REACT_APP_LOCATIONINFO_API}/getLocationOrGroupInfo`;
        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns the minimal location info.
   * @param {Object} params The request params.
   */
  async getMinimalLocationInfo(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { url, uniqueLocationId } = params;
        const data = {
          url: url,
          uniqueLocationId: uniqueLocationId,
        };
        const requestUrl = `${process.env.REACT_APP_LOCATIONINFO_API}/getMinimalLocInfo`;
        const res = await axios.post(requestUrl, data);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns site config data
   */
  async getSiteConfigData(requestUrl) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${
          process.env.REACT_APP_LOCATIONINFO_API
        }/GetSiteConfigByUrl?url=${encodeURI(requestUrl)}`;
        const res = await axios.get(url);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default LocationConfigService;
