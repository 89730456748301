import axios from 'axios';

/**
 * Represents the feedback service.
 */
class PatientSurveyService {
  /**
   * Saves patient survey.
   * @param {Object} params The params.
   * @param {Object} params.survey The survey.
   * @returns A Promise.
   */
  async savePatientSurvey(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { survey } = params;
        const url = `${process.env.REACT_APP_PATIENT_SURVEY_API}`;
        const data = survey;

        await axios.post(url, data);

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default PatientSurveyService;
