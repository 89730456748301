import axios from 'axios';

/**
 * Represents the appointment service.
 */
class AppointmentService {
  /**
   * Downloads the ics file an appointment.
   * @param {String} appointment The appointment to download the ics file for.
   */
  async downloadIcsFile(appointment) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_APPOINTMENT_API}/getAppointmentForCalendar`;
        const data = {
          appointment: appointment,
        };
        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Downloads the ics file an appointment.
   * @param {String} appointment The appointment to download the ics file for.
   */
  async getIcsUrl(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_APPOINTMENT_API}/getAppointmentForCalendar/${params.appointmentId}?requestedimeType=${params.mimeType}`;
        const res = await axios.get(url);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns the latest number of patients in queue or waitlist.
   * @param {String} locationId The location to gather waitlist info.
   */
  async getLatestNumberInQueue(locationId) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_APPOINTMENT_API}/getNumberOfPatientsInQueue?locationId=${locationId}`;
        const res = await axios.get(url);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async savePreAppointmentSurvey(preAppointmentSurvey) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_APPOINTMENT_SURVEY_API}`;
        const data = {
          preAppointmentSurvey: preAppointmentSurvey,
        };
        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
export default AppointmentService;
