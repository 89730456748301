import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EventBuilder from '../../eventBuilder';
import { withTranslation } from 'react-i18next';
import StarIcon from '@material-ui/icons/Star';
import './Rating.scss';

const eventBuilder = new EventBuilder();

/**
 * Represents the supported rating alignments.
 */
export class RatingAlignment {
  /**
   * Indicates left alignment.
   */
  static left = 1;

  /**
   * Indicates center alignment.
   */
  static center = 2;

  /**
   * Indicates right alignment.
   */
  static right = 3;
}

/**
 * Represents the supported rating values.
 */
export class RatingValue {
  /**
   * Indicates no rating.
   */
  static none = 0;

  /**
   * Indicates a not likely rating.
   */
  static notLikely = 1;

  /**
   * Indicates a likely rating.
   */
  static likely = 2;

  /**
   * Indicates a neutral rating.
   */
  static neutral = 3;

  /**
   * Indicates a most likely rating.
   */
  static mostLikely = 4;

  /**
   * Indicates a very likely rating.
   */
  static veryLikely = 5;
}

Object.freeze(RatingAlignment);
Object.freeze(RatingValue);

/**
 * Represents a rating component that allows customer feedback.
 * @param {Object} props The component properties.
 */
class Rating extends Component {
  /**
   * Initializes a new instance of the Rating component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      ratings: [],
      rating: RatingValue.none,
    };
    this._align = props.align || RatingAlignment.left;
    this._max = 5;
  }

  /**
   * Executes when the component is mounted to the DOM.
   */
  componentDidMount() {
    const ratings = [];
    let rating = this._max;

    for (let index = 0; index < this._max; ++index) {
      ratings.push({
        id: index,
        isSelected: false,
        rating: rating--,
      });
    }

    this.setState(() => ({ ratings: ratings }));
  }

  _generateRatings = () => {
    let order = this._max;

    // We're doing some CSS trickery with flexbox and ordering.
    // We're reversing the order of the items in the DOM in
    // order to allow styling previous siblings on hover. When
    // the user mousehovers over a rating, typically all items
    // before it will highlight to represent the total rating.
    // Normally we can't do this without flexbox ordering because
    // the DOM is traversed top to bottom with CSS. We're aslo
    // providing the correct rating value for each item.
    return this.state.ratings.map((rating, key) => (
      <li
        className={`rating__item ${
          rating.isSelected ? 'rating__item--selected' : ''
        } ${this.props.disabled ? 'rating__item--disabled' : ''}`}
        key={key}
        style={{ order: order-- }}
      >
        <span
          className="rating__icon-cont"
          data-rating={order + 2 - 1}
          onClick={() => this._onSelectRating(rating)}
        >
          <StarIcon className="rating__icon" />
        </span>
      </li>
    ));
  };

  _onSelectRating = (selectedRating) => {
    this.setState((prevState) => {
      let rating = RatingValue.none;

      const updatedRatings = prevState.ratings.map((r) => {
        if (r.id === selectedRating.id) {
          r.isSelected = true;
          rating = r.rating;
        } else {
          r.isSelected = false;
        }

        return r;
      });

      return {
        rating: rating,
        ratings: updatedRatings,
      };
    });
  };

  _onSubmit = () => {
    const { rating } = this.state;
    console.log('rating rating:', rating);

    if (rating) {
      eventBuilder
        .withCategory(eventBuilder.Category.Feedback.feedbackPage)
        .withAction(eventBuilder.Action.Click.submitStarRating)
        .post();

      const { onSubmitRating } = this.props;
      onSubmitRating && onSubmitRating(rating);
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const { align, disabled, text, lowRatingText, highRatingText, t } =
      this.props;
    let alignClass = '';

    switch (align) {
      case RatingAlignment.left:
        alignClass = 'rating__ratings--align-left';
        break;
      case RatingAlignment.center:
        alignClass = 'rating__ratings--align-center';
        break;
      case RatingAlignment.right:
        alignClass = 'rating__ratings--align-right';
        break;
      default:
        alignClass = '';
        break;
    }

    return (
      <div className="rating">
        {text && <p className="rating__text">{text}</p>}
        <ul className={`rating__ratings ${alignClass}`}>
          {this._generateRatings()}
        </ul>
        {lowRatingText && highRatingText && (
          <div className="rating__scale">
            <span className="rating__low-rating">{lowRatingText}</span>
            <span className="rating__high-rating">{highRatingText}</span>
          </div>
        )}
        <button
          className="rating__submit"
          disabled={disabled}
          onClick={this._onSubmit}
        >
          {t('Submit')}
        </button>
      </div>
    );
  }
}

Rating.propTypes = {
  align: PropTypes.number,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  lowRatingText: PropTypes.string,
  highRatingText: PropTypes.string,
  onSubmitRating: PropTypes.func,
};

export default withTranslation()(Rating);
