import momentLocaleWrapper from './momentLocaleWrapper';
import Session from './session';
import Constants from './constants';

/**
 * Represents a collection of utility methods.
 */
class Utils {
  /**
   * Converts a five minute increment value past midnight to a time string.
   * @param {Number} numFiveMinuteIncrements The number of five minute increments past minute.
   * @param {String} format The format of the time.
   * @param {string} locale The locale to specify, defaults to en-US.
   */
  static convertFiveMinuteIncrementsToTimeString(
    numFiveMinuteIncrements,
    format,
    locale = 'en-US'
  ) {
    const CLOSED = -1;
    let time = 'Closed';

    if (numFiveMinuteIncrements !== CLOSED) {
      const actualMinutes = numFiveMinuteIncrements * 5;

      let date = new Date('2000/1/1');

      date = new Date(date.getTime() + actualMinutes * 60000);

      momentLocaleWrapper.locale(locale);
      time = momentLocaleWrapper(date).format(format);
    }

    return time;
  }

  static convertUrlToLocale() {
    // TODO: This should use getMarketConfigByUrl. Refactor after refactoring data calls.
    // Main issue is that call hasn't been made yet, and high risk to refactor now.
    const url = window.location.hostname;
    let locale = 'en';
    if (url.endsWith('citaconmisojos.com')) {
      locale = 'es';
    } else if (url.endsWith('agendaotica.com')) {
      locale = 'pt';
    } else if (url.endsWith('eyebooknow.in')) {
      locale = 'en';
    } else if (
      url.endsWith('gözlükrandevuşimdial.com') ||
      url.endsWith('xn--gzlkrandevuimdial-zzb0j43g.com')
    ) {
      locale = 'ru';
    } else if (url.endsWith('eyebooknow.tw')) {
      locale = 'zh-TW';
    } else if (url.endsWith('hk')) {
      locale = 'zh-HK';
    }

    return locale;
  }

  /**
   * Debounces a function.
   * @param {Function} func The function to debounce.
   * @param {Number} wait The amount in milliseconds to wait to call the function.
   * @param {Boolean} isImmediate A value that determines whether to call the function immediately.
   * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's debounce function.
   */
  static debounce(func, wait, isImmediate) {
    let timeout = null;

    return function () {
      const that = this;
      const args = arguments;
      const callLater = () => {
        timeout = null;

        if (!isImmediate) {
          func.apply(that, args);
        }
      };
      const callNow = isImmediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(callLater, wait);

      if (callNow) {
        func.apply(that, args);
      }
    };
  }

  static getDayHours(dayOfWeekId, schedules, locale, t) {
    let dayHours = '';

    if (dayOfWeekId && schedules && schedules.length > 0) {
      const schedule = schedules.find((s) => s.dayOfWeekId === dayOfWeekId);

      if (schedule) {
        const closed = -1;
        const timeBlock = schedule.timeBlocks[0];

        if (timeBlock.start === closed && timeBlock.end === closed) {
          dayHours = t('Closed');
        } else {
          const startHours = this.translateTimeBlockIfClosed(
            schedule.timeBlocks[0].start,
            locale,
            t
          );
          const endHours = this.translateTimeBlockIfClosed(
            schedule.timeBlocks[0].end,
            locale,
            t
          );
          dayHours = `${startHours} - ${endHours}`;
        }
      }
    }

    return dayHours;
  }

  /**
   * Returns the day of the week based on its id.
   * @param {Number} dayOfWeekId The day of the week id.
   */
  static getDayOfWeek(dayOfWeekId) {
    let dayOfWeek = '';

    // TODO: Need to pull static localized days based on locale.
    switch (dayOfWeekId) {
      case 1:
        dayOfWeek = 'Sunday';
        break;
      case 2:
        dayOfWeek = 'Monday';
        break;
      case 3:
        dayOfWeek = 'Tuesday';
        break;
      case 4:
        dayOfWeek = 'Wednesday';
        break;
      case 5:
        dayOfWeek = 'Thursday';
        break;
      case 6:
        dayOfWeek = 'Friday';
        break;
      case 7:
        dayOfWeek = 'Saturday';
        break;
      default:
        dayOfWeek = 'Sunday';
        break;
    }

    return dayOfWeek;
  }

  /**
   * Returns the uniqueLocationId from the provided path name.
   * @param {String} pathname The path name.
   */
  static getUniqueLocationIdFromUrlPathName(pathname) {
    let lid = '';

    if (pathname) {
      const urlSegments = pathname.split('/');

      if (urlSegments && urlSegments.length > 0) {
        lid = urlSegments[1];
      }
    }

    return lid;
  }

  /**
   * Returns a url/query string parameter.
   * @param {String} name The name of the url/query string parameter.
   * @retuns String
   */
  static getUrlParam(name) {
    let value = '';

    if (typeof URLSearchParams !== 'undefined') {
      value = new URLSearchParams(window.location.search.toLowerCase()).get(
        name.toLowerCase()
      );
    } else {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const params = new RegExp(
        `[\\?&]${name.toLowerCase()}=([^&#]*)`,
        'i'
      ).exec(window.location.search.toLowerCase());
      value =
        params === null
          ? ''
          : decodeURIComponent(params[1].replace(/\+/g, ' '));
    }

    value = value === null ? '' : value;

    return value;
  }

  /**
   * Returns a url/query string parameter with maintained case.
   * @param {String} name The name of the url/query string parameter.
   * @retuns String
   */
  static getUrlParamWithCase(name) {
    let value = '';

    if (typeof URLSearchParams !== 'undefined') {
      value = new URLSearchParams(window.location.search).get(name);
    } else {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const params = new RegExp(`[\\?&]${name}=([^&#]*)`, 'i').exec(
        window.location.search
      );
      value =
        params === null
          ? ''
          : decodeURIComponent(params[1].replace(/\+/g, ' '));
    }

    value = value === null ? '' : value;

    return value;
  }

  /**
   * Returns a value that determines whether the provided day is a holiday.
   * @param {Date} date The date to check.
   * @param {Array} holidays The list of holidays.
   */
  static isHoliday(date, holidays) {
    let isHoliday = false;

    if (date && holidays) {
      const currDate = momentLocaleWrapper(date, '(YYYY, MM, DD, hh, mm)');
      isHoliday = holidays.some(
        (holiday) =>
          momentLocaleWrapper(
            holiday.startDate,
            '(YYYY, MM, DD, hh, mm)'
          ).isSameOrAfter(currDate) &&
          momentLocaleWrapper(
            holiday.endDate,
            '(YYYY, MM, DD, hh, mm)'
          ).isSameOrBefore(currDate)
      );
    }

    return isHoliday;
  }

  /**
   * Returns a value that determines if the provided language tag is for Spanish.
   * @param {String} languageTag The language tag to check.
   */
  static isSpanishMarket(languageTag) {
    return languageTag === Constants.spanishLanguageTag;
  }

  /**
   * Returns a value that determines if the provided language tag is for Portuguese.
   * @param {String} languageTag The language tag to check.
   */
  static isPortugueseMarket(languageTag) {
    return languageTag === Constants.portugueseLanguageTag;
  }

  /**
   * Returns a value that determines if the provided language tag is for Turkey.
   * @param {String} languageTag The language tag to check.
   */
  static isTurkeyMarket(languageTag) {
    return languageTag === Constants.turkeyLanguageTag;
  }

  /**
   * Returns a value that determines if the provided language tag is for Hong Kong.
   * @param {String} languageTag The language tag to check.
   */
  static isHongKongMarket(languageTag) {
    return languageTag === Constants.hongkongLanguageTag;
  }

  /**
   * Returns a value that determines if the provided language tag is for Taiwan.
   * @param {String} languageTag The language tag to check.
   */
  static isTaiwanMarket(languageTag) {
    return languageTag === Constants.taiwanLanguageTag;
  }

  /**
   * Returns a value that determines if the provided language tag is for India.
   * @param {String} languageTag The language tag to check.
   */
  static isIndiaMarket(languageTag) {
    return languageTag === Constants.indiaLanguageTag;
  }

  /**
   * Checks if location ID is for "Terms of Use" or "Privacy Policy"
   * * @param {String} locationId The location Id to check.
   */
  static isTermsOrPrivacyPage(locationId) {
    if (
      locationId === Constants.termsOfUse ||
      locationId === Constants.privacyPolicy
    ) {
      return true;
    }
  }

  static translateTimeBlockIfClosed = (time, locale, t) => {
    const closed = 'Closed';
    let timeString = this.convertFiveMinuteIncrementsToTimeString(
      time,
      'LT',
      locale
    );

    if (timeString === closed) {
      timeString = t(closed);
    }

    return timeString;
  };

  /**
   * Trims all whitespace from a string.
   * @param {String} str The string.
   */
  static trimAll(str) {
    let trimmedStr = str;

    if (str) {
      trimmedStr = str.replace(/\s+/g, '');
    }

    return trimmedStr;
  }

  /**
   * Updates a property of an object.
   * @param {Object} obj The object to update (object/array).
   * @param {Array} path The path of the property to set.
   * @param {Any} value The value.
   */
  static update(obj, path, value) {
    if (obj && path) {
      if (path.length === 1) {
        obj[path] = value;
      } else {
        this.update(obj[path[0]], path.slice(1), value);
      }
    }
  }

  /**
   * Returns the s3 link for terms of use based on locale
   * @param {String} locale specified locale for the terms of use.
   * @retuns String
   */
  static getTermsOfUseLink(locale) {
    return `${process.env.REACT_APP_CONTENT_URL}/localizations/${locale}/html/consumer.termsconditions.html`;
  }

  /**
   * Returns the s3 link for privacy based on locale
   * @param {String} locale specified locale for the privacy
   * @retuns String
   */
  static getPrivacyLink(locale) {
    return `${process.env.REACT_APP_CONTENT_URL}/localizations/${locale}/html/consumer.privacy.html`;
  }

  /**
   * Returns the site logo based on site id.
   * @returns String
   */
  static getLogoUrl() {
    let url = '';
    const siteId = Session.getItem('si');

    if (siteId) {
      url = `${process.env.REACT_APP_CONTENT_URL}/sites/${siteId}/media/website-logo.png`;
    }

    return url;
  }

  static convertIsoStringToDate(date) {
    return momentLocaleWrapper(date).format('(YYYY, MM, DD, HH, mm)');
  }

  static convertDateToISOString(date) {
    return momentLocaleWrapper(date, '(YYYY, MM, DD, hh, mm)').toISOString();
  }

  /**
   * Returns a value that determines whether the provided location group allows vouchers.
   * @param {String} locationGroup The location group to verify.
   */
  static isVoucherAllowed(locationGroup) {
    return process.env.REACT_APP_LOCATION_GROUP_VOUCHER_WHITELIST.toLocaleLowerCase().includes(
      locationGroup.toLocaleLowerCase()
    );
  }
}

// Lock object to prevent modification (true static).
Object.freeze(Utils);

export default Utils;
