import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EventBuilder from '../../eventBuilder';
import './GoogleRating.scss';

const eventBuilder = new EventBuilder();

const GoogleRating = (props) => {
  const { ratings, numberOfRatings, googleRatingUrl } = props;

  const getStarRating = (locationRating) => {
    const starRatingItems = [];
    for (let index = 0; index < ~~locationRating; ++index) {
      starRatingItems.push(<StarIcon color="primary" />);
    }

    const fullStar = starRatingItems.length;

    if (fullStar < 5) {
      const halfStar = locationRating - fullStar;
      if (halfStar > 0) {
        starRatingItems.push(<StarHalfIcon color="primary" />);
      }

      const emptyStar = ~~(5 - locationRating);
      for (let index = 0; index < emptyStar; ++index) {
        starRatingItems.push(<StarBorderIcon color="primary" />);
      }
    }
    return starRatingItems.slice(0, 5);
  };

  const onClickGoogleRating = () => {
    eventBuilder
      .withCategory(eventBuilder.Category.Home.homePage)
      .withAction(eventBuilder.Action.Click.googleRating)
      .withLabel(eventBuilder.Label.practiceIdentifier)
      .post();
  };

  return (
    <a href={googleRatingUrl} target="_blank" onClick={onClickGoogleRating}>
      <div className="googlerating__container">
        <span>{ratings}</span>
        {getStarRating(ratings)}
        <span>({numberOfRatings})</span>
        <span>&nbsp;Google</span>
      </div>
    </a>
  );
};

GoogleRating.propTypes = {
  ratings: PropTypes.number,
  numberOfRatings: PropTypes.number,
  googleRatingUrl: PropTypes.string,
};

export default GoogleRating;
