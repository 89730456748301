import GoogleAnalytics from './googleAnalytics';
import Utils from './utils';

class Pages {
  static home = 'homepage';
  static Waitlist = {
    start: 'waitlist/start',
    reason: 'waitlist/reason',
    provider: 'waitlist/provider', // unused currently
    info: 'waitlist/info',
    details: 'waitlist/details',
    confirmation: 'waitlist/confirmation',
  };
  static Booking = {
    reason: 'booking/reason',
    provider: 'booking/provider',
    time: 'booking/time',
    dates: 'booking/dates',
    info: 'booking/info',
    details: 'booking/details',
    confirmation: 'booking/confirmation',
  };
  static StellestReschedule = {
    dates: 'mstellest/reschedule',
  };
  static SurveyResponse = {
    ThankYou: 'surveyResponse/thankYou',
  };
  static Download = {
    downloadAttachment: 'download/'
  }
}

Object.freeze(Pages.Waitlist);
Object.freeze(Pages.Booking);
Object.freeze(Pages);

/**
 * Wrapper class around GA Pageviews.
 */
class PageviewBuilder {
  constructor() {
    this.Page = Pages;
    this._uniqueLocationId = Utils.getUniqueLocationIdFromUrlPathName(
      window.location.pathname
    );
  }
  /**
   * Fires off a pageview.
   * @param {string} Page Set with this.Page
   */
  pageview = (page) => {
    // do we want the hostname as well?
    GoogleAnalytics.pageview(`${this._uniqueLocationId}/${page}`);
  };
}

Object.freeze(PageviewBuilder);

export default PageviewBuilder;
