import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import Utils from '../../utils';
import Session from '../../session';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import DataContext from '../../Contexts/DataContext';
import GoogleRating from '../../Components/GoogleRating/GoogleRating';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import PageviewBuilder from '../../pageviewBuilder';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import './Resource.scss';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();

/**
 * Represents the exam type page of the consumer app.
 */
const Resource = (props) => {
  const context = useContext(DataContext);
  const locationInfo = Session.getItem(Constants.locationInfoKey);

  if (!locationInfo?.locationId) {
    window.location = Constants.homeRoute;
  }

  const { minimalLocationInfo } = context;
  const { googleReviewInfo } = minimalLocationInfo;
  const rating = googleReviewInfo?.rating;
  const numberOfUserRatings = googleReviewInfo?.numberOfUserRatings;
  const viewReviewUrl = googleReviewInfo?.viewReviewUrl;
  const placeId = googleReviewInfo?.placeId;

  const { resources } = locationInfo;
  const resourcesToSendToAnalytics = resources
    .map((x) => x.displayName)
    .join(';');

  eventBuilder
    .withCategory(eventBuilder.Category.Booking.providerPage)
    .withLabel(eventBuilder.Label.listOfOptions, resourcesToSendToAnalytics)
    .withAction(eventBuilder.Action.Pageview.providerPage)
    .post();

  eventBuilder.withLabel(eventBuilder.Label.practiceIdentifier).post();

  pageviewBuilder.pageview(pageviewBuilder.Page.Booking.provider);

  const _getResources = () => {
    const { t } = props;
    const buttons = resources.map((resource, key) => {
      return (
        resource.dayWithNextAvailability && (
          <button
            className="resource__button"
            key={key}
            onClick={() => onResourceSelected(resource, false)}
          >
            {t(resource.displayName)}
          </button>
        )
      );
    });

    return buttons;
  };

  const onNavigateBack = () => {
    context.navigateTo(Constants.homeRoute, props.history);
  };

  const onResourceSelected = (resource, isFirstAvailable) => {
    const { update, navigateTo } = context;
    const bookingInfoSession = context.bookingInfo;
    bookingInfoSession.resource = resource;
    bookingInfoSession.isFirstAvailable = isFirstAvailable;
    bookingInfoSession.selectedDate = Utils.convertDateToISOString(
      resource.dayWithNextAvailability
    );
    Session.setItem(Constants.bookingInfoKey, bookingInfoSession);

    update({
      ...context,
      bookingInfo: {
        ...context.bookingInfo,
        resource: resource,
        isFirstAvailable: isFirstAvailable,
        selectedDate: Utils.convertDateToISOString(
          resource.dayWithNextAvailability
        ),
      },
    });

    navigateTo(Constants.bookAppointmentPageRoute, props.history);

    eventBuilder.withAction(eventBuilder.Action.Click.providerName);

    if (isFirstAvailable) {
      eventBuilder.withLabel(eventBuilder.Label.firstAvailableResource);
    } else {
      eventBuilder.withLabel(eventBuilder.Label.selectedResource);
    }

    eventBuilder.post();
    eventBuilder.withLabel(eventBuilder.Label.practiceIdentifier).post();
  };

  const getFirstAvailable = () => {
    const formattedDatesWithNextAvailability = resources
      .filter((r) => r.dayWithNextAvailability)
      .map((r) => {
        return momentLocaleWrapper(
          r.dayWithNextAvailability,
          '(YYYY, MM, DD, HH, mm)'
        );
      });
    const earliestDate = momentLocaleWrapper.min(
      formattedDatesWithNextAvailability
    );
    const earliestResource = resources.filter((r) => {
      return (
        r.dayWithNextAvailability ===
        momentLocaleWrapper(earliestDate).format('(YYYY, MM, DD, HH, mm)')
      );
    });

    return earliestResource && earliestResource[0];
  };

  const { t } = props;

  return (
    <div className="page">
      <section className="resource__header">
        <Header eventBuilder={eventBuilder} onNavigateBack={onNavigateBack} />
      </section>
      <section className="resource__content">
        <h1 className="resource__title">{t('Resource')}</h1>
        <h2 className="resource__subtitle">{t('Schedule your visit with')}</h2>
        <h2 className="resource__subtitle">
          {locationInfo.storeInformation.name}
        </h2>
        {placeId && (
          <div className="resource__subtitle grating">
            <GoogleRating
              ratings={rating}
              numberOfRatings={numberOfUserRatings}
              googleRatingUrl={viewReviewUrl}
            />
          </div>
        )}
        <p className="resource__preference">
          {t('Please select your preference below')}
        </p>
        <div className="resource__types">
          <div className="resource__first-avail">
            <button
              className="resource__button"
              onClick={() => onResourceSelected(getFirstAvailable(), true)}
            >
              {t('First Available')}
            </button>
          </div>
          {_getResources()}
        </div>
      </section>
      <section className="resource__footer">
        <Footer showActions={false} />
      </section>
    </div>
  );
};

export default withTranslation()(Resource);
