import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Overlay from '../../Components/Overlay/Overlay';
import './Legal.scss';
import Utils from '../../utils';
import ContentManagementService from '../../Services/contentManagementService';
import LocationConfigService from '../../Services/locationConfigService';

class Terms extends Component {
  /**
   * Initializes a new instance of the PersonalInfo component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
    this._contentMgmtService = new ContentManagementService();
  }

  async componentDidMount() {
    this._toggleLoadingOverlay();
    let languageTag = '';
    const url = window.location.hostname;

    if (url.endsWith('eyebooknow.in')) {
      languageTag = 'en-IN';
    } else {
      languageTag = Utils.convertUrlToLocale();
    }

    const uniqueLocationId = Utils.getUrlParam('uniqueLocationId');
    if (uniqueLocationId) {
      this._setTermsLinkForLocation(uniqueLocationId);
    } else {
      var urlLink = await this._contentMgmtService.getTermsAndConditions(
        languageTag
      );
      this.setState({ termsUrl: urlLink });
    }
    this._toggleLoadingOverlay();
  }

  _setTermsLinkForLocation = (uniqueLocationId) => {
    const requestUrl = window.location.href.toLowerCase();
    const locationConfigService = new LocationConfigService();
    locationConfigService
      .isStellestEnabled({
        siteConfigUrl: requestUrl,
        uniqueLocationId: uniqueLocationId,
      })
      .then((data) => {
        this._contentMgmtService
          .getTermsAndConditions(data.languageTag, data.stellestEnabled)
          .then((urlLink) => {
            this.setState({ termsUrl: urlLink });
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  _toggleLoadingOverlay = () => {
    this.setState((prevState) => ({ loaded: !prevState.showLoadingOverlay }));
  };

  _handleLoad = () => {
    this.setState({ loaded: false });
  };

  render() {
    const { loaded } = this.state;

    const termsUrl = this.state.termsUrl;
    return (
      <div className="App">
        <section className="App__content">
          <div className="terms-privacy">
            <Overlay show={loaded}>
              <i className="spinner-eclipse"></i>
            </Overlay>
            <iframe
              id="terms-use-iframe"
              title="TermsAndConditions"
              src={termsUrl}
              style={{
                width: 'auto',
                height: '80vh',
                display: 'block',
                overflow: 'hidden',
                border: '1px solid #979797',
                padding: '20px',
                borderRadius: '20px',
              }}
              onLoad={this._handleLoad}
            ></iframe>
          </div>
        </section>
      </div>
    );
  }
}
export default withTranslation()(Terms);
