import React, { memo, useContext } from 'react';
import DataContext from '../../Contexts/DataContext';
import LocationGroupHeader from '../LocationGroupHeader/LocationGroupHeader';
import LocationGroupFooter from '../LocationGroupFooter/LocationGroupFooter';
import './LocationGroup.scss';

/**
 * Represents the location group home page.
 */
const LocationGroup = memo(() => {
  const context = useContext(DataContext);
  const { chainInfo } = context;
  const { chainLogoUrl, chainMarketingHtml, locatorUrl } = chainInfo;

  return (
    <div className="loc-group">
      <section className="loc-group__header">
        <LocationGroupHeader />
      </section>
      <section className="loc-group__content">
        <div className="loc-group__body">
          {chainLogoUrl && (
            <div className="loc-group__logo-parent">
              <div className="loc-group__logo-cont">
                <img
                  alt="Group Logo"
                  className="loc-group__logo"
                  src={chainLogoUrl}
                />
              </div>
            </div>
          )}
          <p
            className="loc-group__statement"
            dangerouslySetInnerHTML={{ __html: chainMarketingHtml }}
          ></p>
          <div className="loc-group__iframe-body">
            <iframe
              className="loc-group__iframe"
              src={locatorUrl}
              title="Store Locator"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="loc-group__footer">
        <LocationGroupFooter />
      </section>
    </div>
  );
});

export default LocationGroup;
