import axios from 'axios';
import moment from 'moment';
import Utils from '../utils';
/**
 * Represents the appointment service.
 */
class VoucherService {
  /**
   * Downloads the ics file an appointment.
   * @param {String} appointment The appointment to download the ics file for.
   */
  async ValidateVoucherCode(voucher, startTime, locationId) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = {
          voucherCode: voucher,
          AppointmentTimeFormatted: startTime,
          locationGroup: '',
          locationId: locationId,
        };
        const url = `${process.env.REACT_APP_APPOINTMENT_API}/validateVoucher`;
        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Determines whether the voucher text field should be displayed.
   * @param {Object} vouchers
   * @param {String} locationGroup
   * @returns {Boolean}
   */
  showVoucherField(vouchers, locationGroup) {
    let showVoucherField = true;

    if (vouchers) {
      if (vouchers.length <= 0) showVoucherField = false;

      if (!Utils.isVoucherAllowed(locationGroup)) showVoucherField = false;

      vouchers.forEach((voucher) => {
        if (
          !moment()
            .local()
            .isBetween(voucher.activeDuring.start, voucher.activeDuring.end)
        )
          showVoucherField = false;
      });
    } else {
      showVoucherField = false;
    }

    return showVoucherField;
  }
}

export default VoucherService;
