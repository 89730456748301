/**
 * Represents a collection of strongly typed regular expressions.
 */
class RegEx {
  /**
   * Gets a regular expression used to validate emails.
   */
  static get email() {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }
}

// Lock object to prevent modification (true static).
Object.freeze(RegEx);

export default RegEx;
