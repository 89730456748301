import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import './LocationGroupFooter.scss';

/**
 * Represents the footer for the location group locator page.
 */
const LocationGroupFooter = memo(() => {
  const { t } = useTranslation();

  return (
    <div className="loc-group-footer">
      <span className="loc-group-footer__sponsor">
        {t('Powered by Essilor')}
      </span>
    </div>
  );
});

export default LocationGroupFooter;
