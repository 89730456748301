import Utils from './utils';

// local variable only accessible from this module (way of doing private variables).
let _value = '';

/**
 * Represents a way of detecting breakpoints (e.g. mobile, tablet, desktop) programatically.
 */
class Breakpoint {
  /**
   * Refreshes the breakpoint value.
   */
  static refreshValue() {
    const body = document.querySelector('body');

    if (body) {
      const computedStyle = window.getComputedStyle(body, ':before');

      if (computedStyle) {
        const propertyValue = computedStyle.getPropertyValue('content');

        if (propertyValue) {
          _value = propertyValue.replace(/"/g, '');
        }
      }
    }
  }

  /**
   * Gets the breakpoint value.
   */
  static get value() {
    return _value;
  }
}

window.addEventListener(
  'resize',
  Utils.debounce(() => {
    Breakpoint.refreshValue();
  }, 250)
);

// If modern browser.
if (typeof Event === 'function') {
  window.dispatchEvent(new Event('resize'));
} else {
  // Browser is IE.
  const event = document.createEvent('UIEvents');
  event.initUIEvent('resize', true, false, window, 0);
  window.dispatchEvent(event);
}

// Lock object to prevent modification (true static).
Object.freeze(Breakpoint);

export default Breakpoint;
