import React, { Component } from 'react';
import axios from 'axios';
import Session from '../../session';
import Constants from '../../constants';
import './StellestSmsLanding.scss';
import EbnLogo from '../../Assets/Images/EyeBookNow-Logo.png';
import AppointmentService from '../../Services/appointmentService';
import StellestLogoWhite from '../../Assets/Images/Stellest-logo-white.png';
import momentLocaleWrapper from '../../momentLocaleWrapper';

class StellestSmsLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationUrl: `${process.env.REACT_APP_STELLEST_CONFIRMATION_API}`,
      disableDownloadIcsButton: true,
      getConfirmationInfoUrl: `${process.env.REACT_APP_STELLEST_GET_CONFIRMATION_API}`,
      readyToLoad: false,
      showIcsDownloadTooltip: false,
    };
    this._appointmentService = new AppointmentService();
  }

  async componentDidMount() {
    const { getConfirmationInfoUrl } = this.state;
    const locationInfo = Session.getItem(Constants.locationInfoKey);
    const { uniqueLocationId } = locationInfo;

    const urlParams = new URLSearchParams(window.location.search);
    const confirmationId = urlParams.get('guid');

    const getConfirmationUrl = `${getConfirmationInfoUrl}/${uniqueLocationId}?confirmationId=${confirmationId}`;

    if (confirmationId) {
      // get appointmentInfo

      await axios
        .get(getConfirmationUrl)
        .then((response) => {
          this.setState({
            appointment: response.data.appointment,
            locationInfo: locationInfo,
            readyToLoad: true,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  _handleConfirmClick() {
    const urlParams = new URLSearchParams(window.location.search);
    const confirmationId = urlParams.get('guid');

    const nextPageUrl = `confirm?guid=${confirmationId}`;

    window.location = `${nextPageUrl}`;
  }

  _handleRescheduleClick() {
    const urlParams = new URLSearchParams(window.location.search);
    const confirmationId = urlParams.get('guid');

    const nextPageUrl = `reschedule?guid=${confirmationId}`;

    window.location = `${nextPageUrl}`;
  }

  render() {
    const { readyToLoad, locationInfo, appointment } = this.state;

    if (readyToLoad) {
      const { address, name } = locationInfo.storeInformation;

      return (
        <div className="stellest-sms-landing page">
          <section className="">
            <div className="stellest-sms-landing__header">
              <img
                className="stellest-sms-landing__logo"
                src={StellestLogoWhite}
                alt="Stellest Logo"
              />
              <p>Follow up appointment was confirmed and scheduled using:</p>
              <img
                className="stellest-sms-landing__ebn-logo"
                src={EbnLogo}
                alt="Eye Book Now logo"
              />
            </div>
          </section>

          <section className="stellest-sms-landing__content">
            <div className="stellest-sms-landing__title">
              <div className="stellest-sms-landing__title-contents">
                <h2>You've got an upcoming appointment</h2>
                <p>It's time to re-evaluate your vision.</p>
              </div>
            </div>

            <div className="">
              <div className="stellest-sms-landing__content__message">
                <p>
                  <b>Dear {appointment.patient.firstName}, </b>
                </p>
                <p>
                  Your provider has scheduled a follow-up appointment to
                  re-evaluate your vision, based on the correction that has been
                  done by the initial lenses.
                </p>
              </div>
              <div className="stellest-sms-landing__content__data">
                <div className="stellest-sms-landing__content__details">
                  <p>
                    <b>Location: </b> <b>{name}</b> {address}
                  </p>
                  <p>
                    <b>Name: </b> {appointment.patient.firstName}{' '}
                    {appointment.patient.lastName}
                  </p>
                  <p>
                    <b>Date: </b>{' '}
                    {momentLocaleWrapper(appointment.startTime).format(
                      'dddd LL'
                    )}{' '}
                    at {momentLocaleWrapper(appointment.startTime).format('LT')}
                  </p>
                </div>
                <div className="stellest-sms-landing__content__actions">
                  <button
                    className="stellest-sms-landing__content__actions__primary-button"
                    onClick={() => this._handleConfirmClick()}
                  >
                    Confirm
                  </button>
                  <button
                    className="stellest-sms-landing__content__actions__secondary-button"
                    onClick={() => this._handleRescheduleClick()}
                  >
                    Reschedule
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section className="stellest-sms-landing__footer">
            <p>Powered by Essilor</p>
          </section>
        </div>
      );
    }
    return null;
  }
}

export default StellestSmsLanding;
