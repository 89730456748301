import React, { Component, Fragment } from 'react';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTooltip from '@material-ui/core/Tooltip';
import RoomIcon from '@material-ui/icons/Room';
import Enums from '../../enums';
import Session from '../../session';
import Constants from '../../constants';
import Breakpoint from '../../breakpoint';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import Overlay from '../../Components/Overlay/Overlay';
import DataContext from '../../Contexts/DataContext';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import GoogleRating from '../../Components/GoogleRating/GoogleRating';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import ThumbsUpIcon from '../../Assets/Images/ThumbsUp-Icon.png';
import Utils from '../../utils';
import Storage from '../../storage';
import AppointmentService from '../../Services/appointmentService';
import PreAppointmentSurvey from '../../Components/PreAppointmentSurvey/PreAppointmentSurvey';
import './Details.scss';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  popper: {
    zIndex: 11000,
  },
}))(MuiTooltip);

/**
 * Represents the details page of the consumer app.
 */
class Details extends Component {
  /**
   * Initializes a new instance of the Details component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      disableDownloadIcsButton: false,
      errorMessage: '',
      showConfirmModal: false,
      showPreApptSurvey: false,
      showSurveyQuestions: false,
      showIcsDownloadTooltip: false,
      latestQueueNumber: 0,
      isContentChecked: false,
      showRequiredMessage: false,
    };
    this._appointmentService = new AppointmentService();
    this._onConfirmBooking = this._onConfirmBooking.bind(this);
    this.onNavigateBack = this.onNavigateBack.bind(this);
  }

  /**
   * Executes when the component mounts to the DOM.
   */
  async componentDidMount() {
    const locationInfo = Session.getItem(Constants.locationInfoKey);

    if (!locationInfo.locationId) {
      window.location = Constants.homeRoute;
    }

    const languageTag = Session.getItem(Constants.languageTagKey);

    if (languageTag) {
      momentLocaleWrapper.locale(languageTag);
    }

    if (languageTag === Constants.turkeyLanguageTag) {
      const { t } = this.props;
      this.setState(() => ({
        showRequiredMessage: true,
        errorMessage: t(
          'Agreement is required before we can book your appointment'
        ),
      }));
    }

    if (this._isWaitlist()) {
      eventBuilder.withCategory(eventBuilder.Category.Waitlist.detailsPage);
      pageviewBuilder.pageview(pageviewBuilder.Page.Waitlist.details);
    } else {
      eventBuilder.withCategory(eventBuilder.Category.Booking.detailsPage);
      pageviewBuilder.pageview(pageviewBuilder.Page.Booking.details);
    }

    eventBuilder.withLabel(eventBuilder.Label.practiceIdentifier);
  }

  _onCloseModal = () => {
    const sessionApptBooked = Session.getItem('ApptBooked') ? true : false;
    const { showConfirmModal, showPreApptSurvey } = this.state;
    if (sessionApptBooked && !showConfirmModal && !showPreApptSurvey) {
      this.context.navigateTo(Constants.homeRoute, this.props.history);
    }
    this.setState(() => ({
      showConfirmModal: false,
      showPreApptSurvey: true,
    }));
  };

  _onCloseSurvey = async (preAppointmentSurvey) => {
    if (preAppointmentSurvey) {
      eventBuilder
        .withCategory(eventBuilder.Category.Booking.detailsPage)
        .withAction(eventBuilder.Action.Click.submitPreAppointmentSurvey)
        .post();
      const updatedPreAppointmentSurvey = Object.assign(
        {},
        this.state.preAppointmentSurvey,
        preAppointmentSurvey
      );
      await this._appointmentService.savePreAppointmentSurvey(
        updatedPreAppointmentSurvey
      );
      this.context.navigateTo(
        Constants.surveyResponseThankYouRoute,
        this.props.history
      );
    } else {
      eventBuilder
        .withCategory(eventBuilder.Category.Booking.detailsPage)
        .withAction(eventBuilder.Action.Click.dismissPreAppointmentSurvey)
        .post();
      this.context.navigateTo(Constants.homeRoute, this.props.history);
    }
  };

  async _onConfirmBooking() {
    const { t } = this.props;
    try {
      this.setState(() => ({
        errorMessage: '',
        showLoadingOverlay: true,
      }));

      const { consumerInfo, locationInfo, bookingInfo } = this.context;
      const { locationId, selectedAppointmentType, phoneCountryCode } =
        locationInfo;
      const { resource, selectedTime } = bookingInfo;

      const cleanConsumerInfo = {
        firstName: consumerInfo.firstName,
        lastName: consumerInfo.lastName,
        phone: `${phoneCountryCode.substring(1)}${consumerInfo.phone}`,
        email: consumerInfo.email,
        patientConsents: [
          {
            locationId: locationInfo.locationId,
            eventTypeId: 1,
            consent: this.state.isContentChecked,
            created: new Date().toISOString(),
          },
        ],
      };

      const startTime = selectedTime ? selectedTime : null;
      const currentStatus = this._isWaitlist()
        ? Enums.AppointmentStatus.queued
        : Enums.AppointmentStatus.booked;
      const voucher = Storage.getItem(Constants.voucherStorageKey);
      const data = {
        appointment: {
          locationId: locationId,
          patient: {
            ...cleanConsumerInfo,
            preferredPlatform: consumerInfo.messagingSmsType,
          },
          created: new Date().toISOString(),
          startTime: startTime,
          endTime: null,
          appointmentType: {
            id: selectedAppointmentType.locationAppointmentTypeId,
            length: selectedAppointmentType.duration,
            displayName: selectedAppointmentType.displayName,
          },
          swimlane: {
            description: 'schedule',
            swimlaneId: Enums.Swimlanes.queued,
          },
          notes: '',
          resource: resource ? resource : null,
          currentStatus: currentStatus,

          voucherCode: voucher?.toUpperCase(),
        },
      };
      const url = `${process.env.REACT_APP_APPOINTMENT_API}/saveAppointmentNew`;
      const res = await axios.post(url, data);
      this.setState({
        preAppointmentSurvey: res.data.appointment.preAppointmentSurvey,
      });

      if (res && res.data && res.data.success) {
        this._appointmentId = res.data.appointment.appointmentId;

        // Store the appointment for use in downloading the .ics file.
        Session.setItem(Constants.currCustomerApptKey, res.data.appointment);
        Session.setItem('ApptBooked', true);
        Storage.setItem(Constants.voucherStorageKey, '');

        if (this._isWaitlist()) {
          eventBuilder
            .withCategory(eventBuilder.Category.Waitlist.detailsPage)
            .withAction(eventBuilder.Action.Click.confirmForWaitlist);
        } else {
          eventBuilder
            .withCategory(eventBuilder.Category.Booking.detailsPage)
            .withAction(eventBuilder.Action.Click.confirmBooking)
            .withLabel(
              eventBuilder.Label
                .practiceIdentifierAndDateTimeAndResourceAndMessagingType
            );
        }
        eventBuilder.post();

        eventBuilder
          .withCategory(eventBuilder.Category.Booking.confirmPage)
          .withAction(eventBuilder.Action.Pageview.bookingConfirmedPage)
          .withLabel(
            eventBuilder.Label
              .practiceIdentifierAndDateTimeAndResourceAndMessagingType
          )
          .post();

        if (Breakpoint.value === 'tablet' || Breakpoint.value === 'desktop') {
          const queueNumberResponse =
            await this._appointmentService.getLatestNumberInQueue(
              this.context.locationInfo.locationId
            );
          this.setState(() => ({
            showConfirmModal: true,
            latestQueueNumber: queueNumberResponse,
          }));
        } else {
          this.context.navigateTo(
            Constants.confirmPageRoute,
            this.props.history
          );
        }
      } else {
        this.setState(() => ({
          errorMessage: t('An unexpected issue occurred while booking'),
        }));
      }
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.error(error);
        this.setState(() => ({
          errorMessage: t('An unexpected issue occurred while booking'),
        }));
      }
    }

    this.setState(() => ({
      showLoadingOverlay: false,
    }));
  }

  _onDownloadIcsFile = async () => {
    try {
      this.setState(() => ({ disableDownloadIcsButton: true }));

      let appointment = Session.getItem(Constants.currCustomerApptKey);
      const { firstName, lastName } = appointment.patient;
      const fileName = `${firstName}_${lastName}_${appointment.appointmentType.displayName}.ics`;

      appointment.startTime = Utils.convertIsoStringToDate(
        appointment.startTime
      );

      let downloadUrl = '';

      // If Chrome on iOS? There's a known bug with Chrome on iOS where
      // It won't recognize an iCal file if downloaded via the download
      // attribute for an anchor.
      if (navigator.userAgent.includes('CriOS')) {
        downloadUrl = `${process.env.REACT_APP_APPOINTMENT_API}/getAppointmentForCalendar/${this._appointmentId}?fileName=${fileName}&requestedimeType=text/calendar`;
      } else {
        const icsData = await this._appointmentService.downloadIcsFile(
          appointment
        );
        downloadUrl = window.URL.createObjectURL(
          new Blob([icsData], { type: 'text/calendar' })
        );
      }

      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.setAttribute('download', fileName);

      document.body.appendChild(anchor);

      anchor.click();

      document.body.removeChild(anchor);

      eventBuilder
        .withCategory(eventBuilder.Category.Booking.confirmPage)
        .withAction(eventBuilder.Action.Click.addToMyCalendar)
        .withLabel(eventBuilder.Label.addToMyCalendar)
        .post();

      this.setState(() => ({ disableDownloadIcsButton: false }));
    } catch (error) {
      console.log(error);
    }
  };

  _onGetDirectionsClick = () => {
    if (this._isWaitlist()) {
      eventBuilder
        .withCategory(eventBuilder.Category.Waitlist.confirmPage)
        .withAction(eventBuilder.Action.Click.getDirections)
        .withLabel(eventBuilder.Label.getDirections)
        .post();
    } else {
      eventBuilder
        .withCategory(eventBuilder.Category.Booking.confirmPage)
        .withAction(eventBuilder.Action.Click.getDirections)
        .withLabel(eventBuilder.Label.getDirections)
        .post();
    }
  };

  /**
   * Executes when the user navigates back to the previous page.
   */
  onNavigateBack() {
    this.context.navigateTo(
      Constants.personalInfoPageRoute,
      this.props.history
    );
  }

  _isWaitlist = () => {
    return this.context.journey === Constants.waitlist;
  };

  _onToggleIcsDownloadTooltip = () => {
    eventBuilder
      .withAction(eventBuilder.Action.Click.infoIconIcsDownload)
      .post();
    this.setState((prevState) => ({
      showIcsDownloadTooltip: !prevState.showIcsDownloadTooltip,
    }));
  };

  _onCloseIcsDownloadTooltip = () => {
    this.setState(() => ({ showIcsDownloadTooltip: false }));
  };

  /**
   * Apply to label with most number of characters
   */
  _isNoWrap = () => {
    const { languageTag } = this.context.locationInfo?.storeInformation;

    if (
      Utils.isHongKongMarket(languageTag) ||
      Utils.isTaiwanMarket(languageTag)
    ) {
      return true;
    }
    return false;
  };

  _onChangeCheckBox = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        isContentChecked: !prevState.isContentChecked,
      };
    });
  };

  _onChangeCheckBoxRequired = () => {
    const { t } = this.props;
    if (this.state.showRequiredMessage) {
      this.setState((prevState) => {
        return {
          ...prevState,
          isContentChecked: !prevState.isContentChecked,
          showRequiredMessage: !prevState.showRequiredMessage,
          errorMessage: '',
        };
      });
    } else {
      this.setState((prevState) => {
        return {
          ...prevState,
          isContentChecked: !prevState.isContentChecked,
          showRequiredMessage: !prevState.showRequiredMessage,
          errorMessage: t(
            'Agreement is required before we can book your appointment'
          ),
        };
      });
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      disableDownloadIcsButton,
      errorMessage,
      showPreApptSurvey,
      showConfirmModal,
      showIcsDownloadTooltip,
      latestQueueNumber,
      isContentChecked,
      showLoadingOverlay,
    } = this.state;
    const { consumerInfo, locationInfo, bookingInfo, minimalLocationInfo } =
      this.context;
    const { numberOfPatientsInQueue, storeInformation } = locationInfo;
    const { name, address, latitude, longitude, languageTag } =
      storeInformation;
    const { firstName, lastName, phone, email } = consumerInfo;
    const { resource, selectedDate, selectedTime, isFirstAvailable } =
      bookingInfo;
    const { googleReviewInfo } = minimalLocationInfo;
    const rating = googleReviewInfo?.rating;
    const numberOfUserRatings = googleReviewInfo?.numberOfUserRatings;
    const viewReviewUrl = googleReviewInfo?.viewReviewUrl;
    const placeId = googleReviewInfo?.placeId;
    const { t } = this.props;
    const currDate = momentLocaleWrapper();
    const isWaitlist = this._isWaitlist();
    const queueNumber =
      latestQueueNumber === 0 ? numberOfPatientsInQueue : latestQueueNumber;
    const isNoWrap = this._isNoWrap();
    const voucherCode = Storage.getItem(
      Constants.voucherStorageKey
    )?.toUpperCase();
    const sessionApptBooked = Session.getItem('ApptBooked') ? true : false;
    if (showConfirmModal) {
      setTimeout(() => {
        this._onCloseModal();
      }, 2000);
    }

    return (
      <div className="page">
        <section className="details__header">
          <Header
            onNavigateBack={this.onNavigateBack}
            eventBuilder={eventBuilder}
          />
        </section>
        <section className="details__content">
          <h1 className="details__title">{t('Personal Info')}</h1>
          <p className="details__subtitle">
            {isWaitlist ? t('Waitlist Details') : t('Schedule your visit with')}
          </p>
          <p className="details__subtitle">{storeInformation.name}</p>
          {placeId && (
            <div className="details__subtitle grating">
              <GoogleRating
                ratings={rating}
                numberOfRatings={numberOfUserRatings}
                googleRatingUrl={viewReviewUrl}
              />
            </div>
          )}
          <p className="details__statement1">
            {isWaitlist
              ? ReactHtmlParser(
                  t('Here are the details of your upcoming waitlist placement.')
                )
              : ReactHtmlParser(
                  t('Here are the details of your upcoming visit.')
                )}
          </p>
          <p className="details__sub-statement1">
            {isWaitlist
              ? ReactHtmlParser(
                  t('Please review and select Confirm For Waitlist!')
                )
              : ReactHtmlParser(t('Please check and select Confirm & Book!'))}
          </p>
          <div className="details__body">
            <table className="details__location">
              <tbody>
                <tr>
                  <td className="details__label">
                    <strong>{t('Location')}:</strong>
                  </td>
                  <td className="details__value">
                    <strong>{name}</strong>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="details__value">{address}</td>
                </tr>
                {!isWaitlist && (
                  <tr>
                    <td className="details__label">
                      <strong>{t('Resource')}:</strong>
                    </td>
                    <td className="details__value">
                      {isFirstAvailable
                        ? t('First Available')
                        : resource.displayName ===
                          Constants.defaultResourceDisplayName
                        ? t(resource.displayName)
                        : resource.displayName}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="details__label">
                    <strong>{t('Name')}:</strong>
                  </td>
                  <td className="details__value">
                    {firstName}&nbsp;{lastName}
                  </td>
                </tr>
                <tr>
                  <td className="details__label">
                    <strong>{t('Date')}:</strong>
                  </td>
                  <td className="details__value">
                    {isWaitlist
                      ? currDate.format('dddd, LL')
                      : `${momentLocaleWrapper(selectedDate).format(
                          'dddd, LL'
                        )} 
                      ${momentLocaleWrapper(
                        selectedTime,
                        'YYYY, MM, DD, hh, mm'
                      ).format('LT')}`}
                  </td>
                </tr>
                <tr>
                  <td
                    className={`${
                      isNoWrap ? 'details__label-nowrap' : 'details__label'
                    }`}
                  >
                    <strong>{t('Phone Number(Details)')}:</strong>
                  </td>
                  <td className="details__value">{phone}</td>
                </tr>
                <tr>
                  <td className="details__label">
                    <strong>{t('Email(Details)')}:</strong>
                  </td>
                  <td className="details__value">{email}</td>
                </tr>
                {voucherCode && (
                  <tr>
                    <td className="details__label">
                      <strong>{t('Voucher Code')}:</strong>
                    </td>
                    <td className="details__value">{voucherCode}</td>
                  </tr>
                )}
              </tbody>
            </table>
            {languageTag !== Constants.turkeyLanguageTag && (
              <div className="details__opt-in-cont">
                <FormControlLabel
                  className="details__opt-in"
                  control={
                    <Checkbox
                      checked={isContentChecked}
                      onChange={this._onChangeCheckBox}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  }
                  label={
                    <p>
                      {t(
                        'I agree to receive marketing communications via email, SMS or WhatsApp'
                      )}
                      &nbsp;
                    </p>
                  }
                />
              </div>
            )}
            {languageTag === Constants.turkeyLanguageTag && (
              <div className="details__opt-in-cont">
                <FormControlLabel
                  className="details__opt-in"
                  control={
                    <Checkbox
                      defaultChecked={true}
                      checked={isContentChecked}
                      onChange={this._onChangeCheckBoxRequired}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  }
                  label={
                    <p className="details__privacy">
                      {ReactHtmlParser(
                        t(
                          'I have read and accepted Terms of Use, the Privacy Policy, and consent to the processing and storage of my personal data outside of Turkey'
                        )
                      )}{' '}
                      &nbsp;
                    </p>
                  }
                />
              </div>
            )}
            <button
              className="details__confirm"
              disabled={this.state.showRequiredMessage || sessionApptBooked}
              onClick={() => {
                this._onConfirmBooking();
              }}
            >
              {isWaitlist ? t('Confirm for Waitlist') : t('Confirm & Book')}
            </button>
            <p className="details__statement2">
              <strong>{t('Something wrong')}</strong>{' '}
              {t(
                `Use the back arrow to change any details that don't look right`
              )}
            </p>
            <span
              className={`details__error ${
                errorMessage ? 'details__error--visible' : ''
              }`}
            >
              {errorMessage}
            </span>
          </div>
        </section>
        <section className="details__footer">
          <Footer showActions={false} />
        </section>
        <Overlay
          flex={true}
          show={
            showConfirmModal ||
            (sessionApptBooked && !showConfirmModal && !showPreApptSurvey)
          }
        >
          <ClickAwayListener onClickAway={this._onCloseModal}>
            <div className="details-modal">
              <div className="details-modal__content">
                {isWaitlist ? (
                  <Fragment>
                    <h2 className="details-modal__waitlist">
                      {t("You've been added to the waitlist")}
                    </h2>
                    <h3 className="details-modal__queue">
                      {t('Your place in line is')}: {queueNumber}
                    </h3>
                  </Fragment>
                ) : (
                  <h2 className="details-modal__booked">
                    {t('Your appointment has been booked!')}
                  </h2>
                )}
                <div className="details-modal__icon-cont">
                  <img
                    alt=""
                    className="details-modal__icon details-modal__icon--animate"
                    src={ThumbsUpIcon}
                  />
                </div>
                {!isWaitlist && (
                  <div>
                    <button
                      className="details-modal__download-ics"
                      disabled={disableDownloadIcsButton}
                      onClick={this._onDownloadIcsFile}
                    >
                      {t('Add to my calendar')}
                    </button>
                    <ClickAwayListener
                      onClickAway={this._onCloseIcsDownloadTooltip}
                    >
                      <button
                        className="details-modal__info-icon"
                        type="button"
                        onClick={this._onToggleIcsDownloadTooltip}
                      >
                        <Tooltip
                          open={showIcsDownloadTooltip}
                          placement="top-end"
                          title={t(
                            'Please note that the .ICS calendar appointment file may not work on non-native browsers for the iOS platform or native calendar applications for Android devices.'
                          )}
                        >
                          <span className="details-modal__tooltip">i</span>
                        </Tooltip>
                      </button>
                    </ClickAwayListener>
                  </div>
                )}
                <a
                  href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
                  className="details-modal__directions"
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={this._onGetDirectionsClick}
                >
                  <RoomIcon />
                  {t('Get Directions')}
                </a>
                <p className="details-modal__confirm">
                  <strong>{t(`You're all set`)}</strong>&nbsp;
                  {t(`We'll text you when you're ready to be seen safely`)}
                </p>
              </div>
            </div>
          </ClickAwayListener>
        </Overlay>
        <PreAppointmentSurvey
          show={showPreApptSurvey}
          onClose={this._onCloseSurvey}
        />
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

Details.contextType = DataContext;

export default withTranslation()(Details);
