import React, { Component, Fragment } from 'react';
import RoomIcon from '@material-ui/icons/Room';
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import MuiTooltip from '@material-ui/core/Tooltip';
import { withTranslation } from 'react-i18next';
import Session from '../../session';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import DataContext from '../../Contexts/DataContext';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import ThumbsUpIcon from '../../Assets/Images/ThumbsUp-Icon.png';
import AppointmentService from '../../Services/appointmentService';
import PreAppointmentSurvey from '../../Components/PreAppointmentSurvey/PreAppointmentSurvey';
import Utils from '../../utils';
import './Confirm.scss';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

/**
 * Represents the confirmation page for the consumer app.
 */
class Confirm extends Component {
  /**
   * Initializes a new instance of the Confirm component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.state = {
      disableDownloadIcsButton: false,
      latitude: '',
      longitude: '',
      showPreApptSurvey: false,
      showIcsDownloadTooltip: false,
      latestQueueNumber: 0,
    };
    this._appointmentService = new AppointmentService();
  }

  /**
   * Executes when the component mounts to the DOM.
   */
  async componentDidMount() {
    const locationInfo = Session.getItem(Constants.locationInfoKey);

    if (locationInfo) {
      const queueNumberResponse =
        await this._appointmentService.getLatestNumberInQueue(
          this.context.locationInfo.locationId
        );

      this.setState(() => ({
        latestQueueNumber: queueNumberResponse,
        latitude: locationInfo.storeInformation.latitude,
        longitude: locationInfo.storeInformation.longitude,
      }));
    }

    if (this._isWaitlist()) {
      eventBuilder
        .withCategory(eventBuilder.Category.Waitlist.confirmPage)
        .withAction(eventBuilder.Action.Pageview.waitlistConfirmedPage)
        .withLabel(eventBuilder.Label.practiceIdentifier)
        .post();

      pageviewBuilder.pageview(pageviewBuilder.Page.Waitlist.confirmation);
    } else {
      eventBuilder
        .withCategory(eventBuilder.Category.Booking.confirmPage)
        .withAction(eventBuilder.Action.Pageview.bookingConfirmedPage)
        .withLabel(
          eventBuilder.Label
            .practiceIdentifierAndDateTimeAndResourceAndMessagingType
        )
        .post();

      pageviewBuilder.pageview(pageviewBuilder.Page.Booking.confirmation);
    }
  }

  /**
   * Executes when the user closes the app.
   */
  onClose() {
    this.setState(() => ({
      showPreApptSurvey: true,
    }));
  }

  _onCloseSurvey = async (preAppointmentSurvey) => {
    if (preAppointmentSurvey) {
      eventBuilder
        .withCategory(eventBuilder.Category.Booking.confirmPage)
        .withAction(eventBuilder.Action.Click.submitPreAppointmentSurvey)
        .post();
      const appointment = Session.getItem(Constants.currCustomerApptKey);
      const updatedPreAppointmentSurvey = Object.assign(
        {},
        appointment.preAppointmentSurvey,
        preAppointmentSurvey
      );
      await this._appointmentService.savePreAppointmentSurvey(
        updatedPreAppointmentSurvey
      );
      this.context.navigateTo(
        Constants.surveyResponseThankYouRoute,
        this.props.history
      );
    } else {
      eventBuilder
        .withCategory(eventBuilder.Category.Booking.confirmPage)
        .withAction(eventBuilder.Action.Click.dismissPreAppointmentSurvey)
        .post();
      this.context.navigateTo(Constants.homeRoute, this.props.history);
    }

    this.setState(() => ({
      showPreApptSurvey: false,
    }));
  };

  _onDownloadIcsFile = async () => {
    try {
      this.setState(() => ({ disableDownloadIcsButton: true }));

      let appointment = Session.getItem(Constants.currCustomerApptKey);
      const { firstName, lastName } = appointment.patient;

      appointment.startTime = Utils.convertIsoStringToDate(
        appointment.startTime
      );

      const fileName = `${firstName}_${lastName}_${appointment.appointmentType.displayName}.ics`;
      let downloadUrl = '';

      // If Chrome on iOS? There's a known bug with Chrome on iOS where
      // It won't recognize an iCal file if downloaded via the download
      // attribute for an anchor.
      if (navigator.userAgent.includes('CriOS')) {
        downloadUrl = `${process.env.REACT_APP_APPOINTMENT_API}/getAppointmentForCalendar/${this._appointmentId}?fileName=${fileName}&requestedimeType=text/calendar`;
      } else {
        const icsData = await this._appointmentService.downloadIcsFile(
          appointment
        );
        downloadUrl = window.URL.createObjectURL(
          new Blob([icsData], { type: 'text/calendar' })
        );
      }

      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.setAttribute('download', fileName);

      document.body.appendChild(anchor);

      anchor.click();

      document.body.removeChild(anchor);

      eventBuilder
        .withCategory(eventBuilder.Category.Booking.confirmPage)
        .withAction(eventBuilder.Action.Click.addToMyCalendar)
        .withLabel(eventBuilder.Label.addToMyCalendar)
        .post();

      this.setState(() => ({ disableDownloadIcsButton: false }));
    } catch (error) {
      console.log(error);
    }
  };

  _onGetDirectionsClick = () => {
    eventBuilder
      .withAction(eventBuilder.Action.Click.getDirections)
      .withLabel(eventBuilder.Label.getDirections)
      .post();
  };

  _isWaitlist = () => {
    return this.context.journey === Constants.waitlist;
  };

  _onToggleIcsDownloadTooltip = () => {
    eventBuilder
      .withAction(eventBuilder.Action.Click.infoIconIcsDownload)
      .post();
    this.setState((prevState) => ({
      showIcsDownloadTooltip: !prevState.showIcsDownloadTooltip,
    }));
  };

  _onCloseIcsDownloadTooltip = () => {
    this.setState(() => ({ showIcsDownloadTooltip: false }));
  };

  componentWillUnmount() {
    Session.setItem(Constants.consumerInfoKey, null);
    Session.setItem(Constants.locationInfoKey, null);
    Session.setItem(Constants.bookingInfoKey, null);
  }

  /**
   * Renders the component.
   */
  render() {
    const { t } = this.props;
    const {
      disableDownloadIcsButton,
      latitude,
      longitude,
      showPreApptSurvey,
      showIcsDownloadTooltip,
      latestQueueNumber,
    } = this.state;

    return (
      <div className="page">
        <section className="confirm__header">
          <Header
            canNavigateBack={false}
            isBookingComplete={true}
            eventBuilder={eventBuilder}
            onClose={this.onClose}
            hideCloseButton={true}
            timeOut={2000}
          />
        </section>
        <section className="confirm__content">
          <h1 className="confirm__title">{t('Confirm')}</h1>
          {this._isWaitlist() ? (
            <Fragment>
              <h2 className="confirm__subtitle">
                {t(`You've been added to the waitlist`)}
              </h2>
              <h3 className="confirm__queue-num">
                {t('Your place in line is')}: {latestQueueNumber}
              </h3>
            </Fragment>
          ) : (
            <h2 className="confirm__subtitle">
              {t(`Your appointment has been booked!`)}
            </h2>
          )}
          <div className="confirm__icon-cont">
            <img
              alt="Thumbs Up"
              className="confirm__icon confirm__icon--animate"
              src={ThumbsUpIcon}
            />
          </div>
          {!this._isWaitlist() && (
            <div>
              <button
                className="confirm__download-ics"
                disabled={disableDownloadIcsButton}
                onClick={this._onDownloadIcsFile}
              >
                {t('Add to my calendar')}
              </button>
              <ClickAwayListener onClickAway={this._onCloseIcsDownloadTooltip}>
                <button
                  className="confirm__info-icon"
                  type="button"
                  onClick={this._onToggleIcsDownloadTooltip}
                >
                  <Tooltip
                    open={showIcsDownloadTooltip}
                    placement="top-end"
                    title={t(
                      'Please note that the .ICS calendar appointment file may not work on non-native browsers for the iOS platform or native calendar applications for Android devices.'
                    )}
                  >
                    <span className="confirm__tooltip">i</span>
                  </Tooltip>
                </button>
              </ClickAwayListener>
            </div>
          )}
          <a
            href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
            className="confirm__directions"
            rel="noopener noreferrer"
            target="_blank"
            onClick={this._onGetDirectionsClick}
          >
            <RoomIcon />
            {t('Get Directions')}
          </a>
          <p className="confirm__confirmation">
            <strong>{t(`You're all set`)}</strong>&nbsp;
            {t(`We'll text you when you're ready to be seen safely`)}
          </p>
        </section>
        <section className="confirm__footer">
          <Footer showActions={false} />
        </section>
        <PreAppointmentSurvey
          show={showPreApptSurvey}
          onClose={this._onCloseSurvey}
        />
      </div>
    );
  }
}

Confirm.contextType = DataContext;

export default withTranslation()(Confirm);
