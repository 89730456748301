/**
 * Represents a collection of enum values.
 */
const Enums = {
  /**
   * Represents the supported appointment statuses.
   */
  AppointmentStatus: {
    /**
     * Indicates a queued status.
     */
    get queued() {
      return 0;
    },

    /**
     * Indicates a booked status.
     */
    get booked() {
      return 1;
    },
  },

  /**
   * Represents the supported http status codes.
   */
  HttpStatusCodes: {
    /**
     * Indicates an HTTP status ok value.
     */
    get httpStatusOk() {
      return 200;
    },

    /**
     * Indicates an HTTP status server error value.
     */
    get httpStatusInternalServerError() {
      return 500;
    },

    /**
     * Indicates an HTTP status unauthorized value.
     */
    get httpStatusUnauthorizedError() {
      return 401;
    },

    /**
     * Indicates that the server cannot or will not process the request due to something that is perceived to be a client error.
     */
    get httpStatusBadRequest() {
      return 400;
    },
  },

  /**
   * Represents the supported keyboard keys.
   */
  KeyboardKeys: {
    /**
     * Indicates the Enter key.
     */
    get enterKey() {
      return 'Enter';
    },
  },

  /**
   * Represents the supported swimlanes.
   */
  Swimlanes: {
    /**
     * Indicates the queued value.
     */
    get queued() {
      return 0;
    },

    /**
     * Indicates the booked value.
     */
    get booked() {
      return 1;
    },
  },

  /**
   * Represents the supported user statuses.
   */
  UserStatus: {
    /**
     * Indicates the onboarding status.
     */
    get onboarding() {
      return 'Onboarding';
    },

    /**
     * Indicates the active status.
     */
    get active() {
      return 'Active';
    },

    /**
     * Indicates the password reset status.
     */
    get pwReset() {
      return 'PwReset';
    },
  },
};

// Lock object to prevent modification (true static).
Object.freeze(Enums);

export default Enums;
