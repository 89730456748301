import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import Breakpoint from '../../breakpoint';
import './PatientSurvey.scss';

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Checkbox = withStyles({
  root: {
    color: '#000',
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
})(MuiCheckbox);

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);

const FormControlLabel = withStyles({
  label: {
    fontSize: '0.8rem',
    fontWeight: 500,
  },
})(MuiFormControlLabel);

/**
 * Represents a patient survey for the consumer.
 */
class PatientSurvey extends Component {
  /**
   * Initializes a new instance of the PatientSurvey component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      visualDemandDigitalDevice: false,
      visualDemandReadWrite: false,
      visualDemandNightDrive: false,
      currentlyWearGlasses: false,
      ageRange: '',
      frequencyOfUse: '',
      specialInstructions: '',
    };
  }

  _onSubmit = () => {
    const survey = {
      visualDemandDigitalDevice: this.state.visualDemandDigitalDevice,
      visualDemandReadWrite: this.state.visualDemandReadWrite,
      visualDemandNightDrive: this.state.visualDemandNightDrive,
      currentlyWearGlasses: this.state.currentlyWearGlasses,
      ageRange: this.state.ageRange,
      frequencyOfUse: this.state.frequencyOfUse,
      specialInstructions: this.state.specialInstructions,
    };
    this.props.onClose(survey);
  };

  _onCancel = () => {
    this.props.onClose(null);
  };

  _onWearGlassesRadioChanged = () => {
    if (this.state.currentlyWearGlasses === true) {
      this.setState((prevState) => ({ currentlyWearGlasses: false }));
      this.setState({ frequencyOfUse: '' });
    } else {
      this.setState((prevState) => ({ currentlyWearGlasses: true }));
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const { t } = this.props;
    const isMobile = Breakpoint.value === 'mobile';

    return (
      <>
        <div className="patient-survey__survey-header patient-survey__survey-header--dialog">
          <h3 className="patient-survey__survey-title patient-survey__survey-title--dialog">
            {t('We would like to learn more about you!')}
          </h3>
          <p className="patient-survey__survey-statement patient-survey__survey-statement--dialog">
            {t(
              'Please answer 4 short questions to help us prepare for your appointment.'
            )}
          </p>
        </div>

        <div className="patient-survey__survey-body">
          <div className="patient-survey__survey-column patient-survey__survey-column1">
            <ol className="patient-survey__survey-list patient-survey__survey-list--dialog">
              <li value="1" className="patient-survey__survey-question">
                <div className="patient-survey__survey-question-body">
                  {ReactHtmlParser(
                    t(
                      'Which of the following visual demands do you encounter on a regular basis? (select all that apply)'
                    )
                  )}
                  <FormControlLabel
                    className="patient-survey__form-label"
                    control={
                      <Checkbox
                        checked={this.state.visualDemandDigitalDevice}
                        onChange={(e, checked) => {
                          this.setState((prevState) => ({
                            visualDemandDigitalDevice: checked,
                          }));
                        }}
                      />
                    }
                    label={t('Digital devices')}
                  />
                  <FormControlLabel
                    className="patient-survey__form-label"
                    control={
                      <Checkbox
                        checked={this.state.visualDemandReadWrite}
                        onChange={(e, checked) => {
                          this.setState((prevState) => ({
                            visualDemandReadWrite: checked,
                          }));
                        }}
                      />
                    }
                    label={t('Read/Write Newspapers/Books')}
                  />
                  <FormControlLabel
                    className="patient-survey__form-label"
                    control={
                      <Checkbox
                        checked={this.state.visualDemandNightDrive}
                        onChange={(e, checked) => {
                          this.setState((prevState) => ({
                            visualDemandNightDrive: checked,
                          }));
                        }}
                      />
                    }
                    label={t('Night Driving')}
                  />
                </div>
              </li>
              <li
                value="3"
                className="patient-survey__survey-question patient-survey__survey-list--dialog"
              >
                {t('Do you wear eyeglasses now?')}
                <RadioGroup>
                  <FormControlLabel
                    className="patient-survey__form-label"
                    control={
                      <Radio
                        checked={!this.state.currentlyWearGlasses}
                        onChange={(e, checked) => {
                          this._onWearGlassesRadioChanged();
                        }}
                      />
                    }
                    label={t('No')}
                  />
                  <FormControlLabel
                    className="patient-survey__form-label"
                    control={
                      <Radio
                        checked={this.state.currentlyWearGlasses}
                        onChange={(e, checked) => {
                          this._onWearGlassesRadioChanged();
                        }}
                      />
                    }
                    label={t('Yes')}
                  />
                  <span>{t('If "Yes", how often?')}</span>
                  <div className="patient-survey__survey-column-cont">
                    <div className="patient-survey__survey-column">
                      <FormControlLabel
                        className="patient-survey__form-label"
                        control={
                          <Radio
                            checked={this.state.frequencyOfUse === 'allthetime'}
                            onChange={(e, checked) => {
                              this.setState((prevState) => ({
                                frequencyOfUse: 'allthetime',
                              }));
                            }}
                          />
                        }
                        label={t('All the time')}
                        value="allthetime"
                        disabled={!this.state.currentlyWearGlasses}
                      />
                      <FormControlLabel
                        className="patient-survey__form-label"
                        control={
                          <Radio
                            checked={this.state.frequencyOfUse === 'sometimes'}
                            onChange={(e, checked) => {
                              this.setState((prevState) => ({
                                frequencyOfUse: 'sometimes',
                              }));
                            }}
                          />
                        }
                        label={t('Sometimes')}
                        value="sometimes"
                        disabled={!this.state.currentlyWearGlasses}
                      />
                      <FormControlLabel
                        className="patient-survey__form-label"
                        control={
                          <Radio
                            checked={
                              this.state.frequencyOfUse === 'onlyforfardistance'
                            }
                            onChange={(e, checked) => {
                              this.setState((prevState) => ({
                                frequencyOfUse: 'onlyforfardistance',
                              }));
                            }}
                          />
                        }
                        label={t('Only for far distance')}
                        value="onlyforfardistance"
                        disabled={!this.state.currentlyWearGlasses}
                      />
                    </div>
                    <div className="patient-survey__survey-column">
                      <FormControlLabel
                        className="patient-survey__form-label"
                        control={
                          <Radio
                            checked={
                              this.state.frequencyOfUse === 'onlyforereading'
                            }
                            onChange={(e, checked) => {
                              this.setState((prevState) => ({
                                frequencyOfUse: 'onlyforereading',
                              }));
                            }}
                          />
                        }
                        label={t('Only for reading')}
                        value="onlyforereading"
                        disabled={!this.state.currentlyWearGlasses}
                      />
                      <FormControlLabel
                        className="patient-survey__form-label"
                        control={
                          <Radio
                            checked={
                              this.state.frequencyOfUse === 'onlyforthecomputer'
                            }
                            onChange={(e, checked) => {
                              this.setState((prevState) => ({
                                frequencyOfUse: 'onlyforthecomputer',
                              }));
                            }}
                          />
                        }
                        label={t('Only for the computer')}
                        value="onlyforthecomputer"
                        disabled={!this.state.currentlyWearGlasses}
                      />
                    </div>
                  </div>
                </RadioGroup>
              </li>
            </ol>
          </div>
          <div className="patient-survey__survey-column">
            <ol className="patient-survey__survey-list patient-survey__survey-list--dialog">
              <li value="2" className="patient-survey__survey-question">
                {t('What is your age range?')}
                <RadioGroup>
                  <FormControlLabel
                    className="patient-survey__form-label"
                    control={
                      <Radio
                        checked={this.state.ageRange === 'lessthan40'}
                        onChange={(e, checked) => {
                          this.setState((prevState) => ({
                            ageRange: 'lessthan40',
                          }));
                        }}
                      />
                    }
                    label={t('Less than 40')}
                    value="lessthan40"
                  />
                  <FormControlLabel
                    className="patient-survey__form-label"
                    control={
                      <Radio
                        checked={this.state.ageRange === 'over40'}
                        onChange={(e, checked) => {
                          this.setState((prevState) => ({
                            ageRange: 'over40',
                          }));
                        }}
                      />
                    }
                    label={t('Over 40')}
                    value="over40"
                  />
                </RadioGroup>
              </li>
              <li value="4" className="patient-survey__survey-question">
                {t('Special instructions related to your appointment?')}
                <textarea
                  className="patient-survey__form-multiinput patient-survey__form-multiinput--dialog"
                  onChange={(e) => {
                    const value = e.target.value;
                    this.setState((prevState) => ({
                      specialInstructions: value,
                    }));
                  }}
                ></textarea>
              </li>
            </ol>
            <button
              className="patient-survey__survey-submit patient-survey__survey-submit--dialog"
              onClick={this._onSubmit}
            >
              {t('Submit')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

PatientSurvey.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default withTranslation()(PatientSurvey);
