import axios from 'axios';

/**
 * Represents the feedback service.
 */
class FeedbackService {
  /**
   * Returns appointment feedback.
   * @param {Object} params The params.
   * @param {Number} params.appointmentId The appointment id.
   * @returns A Promise.
   */
  async getAppointmentFeedback(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_APPOINTMENT_FEEDBACK_API}/${params.locationId}/${params.feedbackUrlKey}`;
        const res = await axios.get(url);

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Saves feedback.
   * @param {Object} params The params.
   * @param {Object} params.feedback The feedback.
   * @returns A Promise.
   */
  async saveFeedback(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { feedback, locationId } = params;
        const url = `${process.env.REACT_APP_APPOINTMENT_FEEDBACK_API}`;
        feedback.locationId = locationId;
        const data = feedback;
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          resolve();
        }, 250);

        await axios.post(url, data);

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default FeedbackService;
