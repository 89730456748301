import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import DataContext from '../../Contexts/DataContext';
import LocationInfo from '../../Components/LocationInfo/LocationInfo';
import LocationGroup from '../../Components/LocationGroup/LocationGroup';
import Utils from '../../utils';
import Constants from '../../constants';
import Storage from '../../storage';

import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import ContentManagementService from '../../Services/contentManagementService';

const eventBuilder = new EventBuilder();
const pageviewBuilder = new PageviewBuilder();
const _contentManagementService = new ContentManagementService();

/**
 * Represents the home page of the consumer app.
 */
const Home = (props) => {
  if (isDownloadRequest()) {
    downloadFile();
  }

  const context = useContext(DataContext);
  const { chainInfo, minimalLocationInfo } = context;
  let locPage = null;

  if (minimalLocationInfo?.siteId) {
    locPage = <LocationInfo history={props.history} />;
  } else if (chainInfo?.chainName) {
    const code = Utils.getUrlParam(Constants.voucherStorageKey);
    if (code) {
      Storage.setItem(Constants.voucherStorageKey, code);
    }
    locPage = <LocationGroup history={props.history} />;
  }

  return <div>{locPage}</div>;
};

const isDownloadRequest = () => {
  const pathname = window.location.pathname;
  let fileId = '';

  if (pathname) {
    const urlSegments = pathname.split('/');

    if (urlSegments && urlSegments.length > 0) {
      return urlSegments[2] == 'download';
    }
  }

  return false;
};

const downloadFile = async () => {
  const pathname = window.location.pathname;
  let fileId = '';

  if (pathname) {
    const urlSegments = pathname.split('/');

    if (urlSegments && urlSegments.length > 0) {
      fileId = urlSegments[3];
    }
  }

  const locId = Utils.getUniqueLocationIdFromUrlPathName(
    window.location.pathname
  );

  eventBuilder
    .withCategory(eventBuilder.Category.Download.downloadPage)
    .withLabel(eventBuilder.Label.practiceIdentifier);

  pageviewBuilder.pageview(pageviewBuilder.Page.Booking.time);

  //Attempt to download file then redirect to store page
  let file = null;
  if (fileId) {
    file = await _contentManagementService.downloadChatAttachment(fileId);
  }

  if (file) {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = fileId;
    link.href = URL.createObjectURL(new Blob([file]));
    link.click();
  }
  return locId;
};

export default withTranslation()(Home);
