import React, { Component } from 'react';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';
import CloseIcon from '@material-ui/icons/Close';
import DataContext from '../../Contexts/DataContext';
import EventBuilder from '../../eventBuilder';
import Utils from '../../utils';
import Overlay from '../../Components/Overlay/Overlay';
import FeedbackService from '../../Services/feedbackService';
import LocationConfigService from '../../Services/locationConfigService';
import ContentManagementService from '../../Services/contentManagementService';
import Rating, {
  RatingAlignment,
  RatingValue,
} from '../../Components/Rating/Rating';
import Constants from '../../constants';
import Enums from '../../enums';
import './Feedback.scss';

const eventBuilder = new EventBuilder();

/**
 * Represents the feedback page.
 */
class Feedback extends Component {
  /**
   * Initializes a new instance of the Feedback page.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      canAddReview: false,
      disableRating: false,
      feedback: {
        feedbackUrlKey: -1,
        rating: RatingValue.neutral,
        comments: '',
      },
      isReadyToRender: false,
      locationId: -1,
      placeId: '',
      showFeedbackReceivedModal: false,
      showGoogleFeedbackModal: false,
      showLoadingOverlay: false,
      showFeedbackModal: false,
      showThankYouModal: false,
      storeInformation: {
        address: '',
        name: '',
      },
    };
    this._feedbackService = new FeedbackService();
    this._locationConfigService = new LocationConfigService();
    this._contentManagementService = new ContentManagementService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      this.setState(() => ({ showLoadingOverlay: true }));

      const locationData =
        await this._locationConfigService.getLocationOrGroupInfo();

      const languageTag =
        locationData?.locationInfo.storeInformation.languageTag;
      if (languageTag && languageTag.length) {
        await this._contentManagementService.loadLocalizations(languageTag);
        await i18n.changeLanguage(languageTag);
      }

      const feedbackUrlKey = Utils.getUrlParam('feedbackUrlKey');
      const isRequestCustomerFeedbackEnabled =
        locationData.locationInfo.locationSettings.some(
          (locationSetting) =>
            locationSetting.displayName ===
              Constants.settingIsRequestCustomerFeedbackEnabled &&
            locationSetting.settingValue === true
        );

      let isFeedbackReceived = false;
      if (feedbackUrlKey) {
        const feedbackData = await this._feedbackService.getAppointmentFeedback(
          {
            feedbackUrlKey: feedbackUrlKey,
            locationId: locationData?.locationInfo?.locationId,
          }
        );
        isFeedbackReceived = feedbackData.isFeedbackReceived;
      } else {
        this.setState(() => ({
          isReadyToRender: true,
          showLoadingOverlay: false,
          showInvalidLinkErrorModal: true,
          disableRating: true,
        }));
        return;
      }

      this.setState(() => ({
        disableRating: isFeedbackReceived || !isRequestCustomerFeedbackEnabled,
        feedback: {
          ...this.state.feedback,
          feedbackUrlKey: feedbackUrlKey,
        },
        isReadyToRender: true,
        locationId: locationData?.locationInfo?.locationId,
        placeId: this.context.minimalLocationInfo.googleReviewInfo?.placeId,
        showFeedbackReceivedModal: isFeedbackReceived,
        showLoadingOverlay: false,
        showFeedbackDisabledModal: !isRequestCustomerFeedbackEnabled,
        storeInformation: { ...locationData?.locationInfo?.storeInformation },
      }));
    } catch (error) {
      const showInvalidLinkErrorModal =
        (error.isAxiosError &&
          error?.response?.status ===
            Enums.HttpStatusCodes.httpStatusBadRequest) ||
        false;

      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
        showInvalidLinkErrorModal: showInvalidLinkErrorModal,
        disableRating: true,
      }));
    }
  }

  _onCloseFeedbackReceivedModal = () => {
    this.setState(() => ({ showFeedbackReceivedModal: false }));
  };

  _onCloseGoogleFeedbackModal = () => {
    this.setState(() => ({ showGoogleFeedbackModal: false }));
  };

  _onCloseFeedbackModal = () => {
    this.setState(() => ({ showFeedbackModal: false }));
  };

  _onCloseThankYouModal = () => {
    this.setState(() => ({ showThankYouModal: false }));
  };

  _onCloseFeedbackDisabledModal = () => {
    this.setState(() => ({ showFeedbackDisabledModal: false }));
  };

  _onCloseInvalidLinkErrorModal = () => {
    this.setState(() => ({ showInvalidLinkErrorModal: false }));
  };

  _onCloseErrorSavingFeedbackModal = () => {
    this.setState(() => ({ showErrorSavingFeedbackModal: false }));
  };

  _onFeedbackChange = (path, value) => {
    this.setState((prevState) => {
      const updatedFeedback = cloneDeep(prevState.feedback);

      Utils.update(updatedFeedback, path, value);

      const canAddReview = path[0] === 'comments' && value;

      return {
        canAddReview: canAddReview,
        feedback: updatedFeedback,
      };
    });
  };

  _onOpenGoogleReview = () => {
    const { placeId } = this.state;

    if (placeId) {
      eventBuilder
        .withCategory(eventBuilder.Category.Feedback.feedbackPage)
        .withAction(eventBuilder.Action.Click.addGoogleReview)
        .post();

      window.open(`${process.env.REACT_APP_GOOGLE_REVIEW_URL}${placeId}`);

      this.setState(() => ({
        disableRating: true,
        showThankYouModal: true,
        showGoogleFeedbackModal: false,
      }));
    }
  };

  _onSubmitFeedback = async () => {
    try {
      const { feedback, locationId } = this.state;
      await this._feedbackService.saveFeedback({
        feedback: feedback,
        locationId: locationId,
      });
    } catch (error) {
      this.setState(() => ({
        disableRating: true,
        showErrorSavingFeedbackModal: true,
      }));
    }
  };

  _onSubmitInternalOnlyFeedback = async () => {
    try {
      await this._onSubmitFeedback();

      eventBuilder
        .withCategory(eventBuilder.Category.Feedback.feedbackPage)
        .withAction(eventBuilder.Action.Click.addReview)
        .post();

      this.setState({
        showFeedbackModal: false, 
        showThankYouModal: true, 
        disableRating: true
      });
    } catch (error) {
      this.setState(() => ({
        disableRating: true,
        showErrorSavingFeedbackModal: true,
      }));
    }
  };

  _onSubmitFeedbackToGoogle = async () => {
    try {
      await this._onSubmitFeedback();

      // Check for placeid for location for google review
      if (this.state.placeId) {
        this.setState(() => ({
          showGoogleFeedbackModal: true,
          disableRating: true,
        }));
      } else {
        this.setState(() => ({
          showThankYouModal: true,
          disableRating: true,
        }));
      }
    } catch (error) {
      this.setState(() => ({
        disableRating: true,
        showErrorSavingFeedbackModal: true,
      }));
    }
  };

  /**
   * Executes when the rating is submitted.
   * @param {Any} rating The value.
   */
  onSubmitRating = async (rating) => {
    await this.setState(() => ({
      feedback: {
        ...this.state.feedback,
        rating,
      },
    }));

    const {
      placeId
    } = this.state;

    if (placeId && rating) {
      //send to google
      this._onSubmitFeedbackToGoogle();
    }
    else if (rating) {
      this.setState(() => ({
        showFeedbackModal: true
      }));
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      canAddReview,
      disableRating,
      feedback,
      isReadyToRender,
      showFeedbackReceivedModal,
      showGoogleFeedbackModal,
      showLoadingOverlay,
      showFeedbackModal,
      showThankYouModal,
      storeInformation,
      showFeedbackDisabledModal,
      showInvalidLinkErrorModal,
      showErrorSavingFeedbackModal,
    } = this.state;
    const { t } = this.props;
    const { address, name } = storeInformation;
    const logoUrl = Utils.getLogoUrl();

    return (
      <div className="page page--feedback">
        {isReadyToRender && (
          <section className="feedback__header">
            <div className="feedback__brand">
              <img alt="Site Logo" className="feedback__logo" src={logoUrl} />
            </div>
          </section>
        )}
        {isReadyToRender && (
          <section className="feedback__content">
            <h1 className="feedback__title">{t('Feedback')}</h1>
            <div className="feedback__hero"></div>
            <div className="feedback__overlay"></div>
            <div className="feedback__shape">
              <h2 className="feedback__location">{name}</h2>
              <p className="feedback__address">{address}</p>
              <div className="feedback__body">
                <p className="feedback__statement1">
                  {t('We would love to hear from you!')}
                </p>
                <p className="feedback__statement2">
                  {t('Only by listening can we know how to serve you better.')}
                </p>
                <Rating
                  align={RatingAlignment.center}
                  disabled={disableRating}
                  text={t('How likely are you to recommend us to a friend?')}
                  lowRatingText={`1 - ${t('Not Likely')}`}
                  highRatingText={`5 - ${t('Very Likely')}`}
                  onSubmitRating={this.onSubmitRating}
                />
              </div>
            </div>
          </section>
        )}
        {isReadyToRender && (
          <section className="feedback__footer">
            <span className="feedback__sponsor">{t('Powered by Essilor')}</span>
          </section>
        )}
        <Overlay flex={true} show={showGoogleFeedbackModal}>
          <div className="feedback-modal">
            <div className="feedback-modal__header">
              <span className="feedback-modal__spacer">&nbsp;</span>
              <h3 className="feedback-modal__title">{t('Your Review')}</h3>
              <button
                className="feedback-modal__close"
                onClick={this._onCloseGoogleFeedbackModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="feedback-modal__content">
              <p className="feedback-modal__statement">
                {t('Yay! Thank you for the positive rating!')}
              </p>
              <p className="feedback-modal__share">
                {t(
                  'Share your experience with others by posting a review of our location on Google. Simply click the button below.'
                )}
              </p>
              <div className="feedback-modal__footer">
                <button
                  className="feedback-modal__add-google"
                  onClick={this._onOpenGoogleReview}
                >
                  {t('Add Google Review')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay
          flex={true}
          show={showFeedbackModal}>
          <div className="feedback-modal">
            <div className="feedback-modal__header">
              <span className="feedback-modal__spacer">&nbsp;</span>
              <h3 className="feedback-modal__title">{t('Your Review')}</h3>
              <button
                className="feedback-modal__close"
                onClick={this._onCloseFeedbackModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="feedback-modal__content">
              <p className="feedback-modal__statement">
                {t('How would you describe your experience at {{locationName}}?', {
                  locationName: name,
                })}
              </p>
              <textarea
                className="feedback-modal__comments"
                placeholder={t('Write your review...')}
                value={feedback.comments}
                onChange={(e) =>
                  this._onFeedbackChange(['comments'], e.target.value)
                }
              ></textarea>
              <div className="feedback-modal__footer">
                <button
                  className="feedback-modal__add-review"
                  disabled={!canAddReview}
                  onClick={this._onSubmitInternalOnlyFeedback}
                >
                  {t('Add Review')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay flex={true} show={showThankYouModal}>
          <div className="feedback-modal">
            <div className="feedback-modal__header">
              <span className="feedback-modal__spacer">&nbsp;</span>
              <h3 className="feedback-modal__title">{t('Your Review')}</h3>
              <button
                className="feedback-modal__close"
                onClick={this._onCloseThankYouModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="feedback-modal__content">
              <h3 className="feedback-modal__thankyou">{t('Thank you!')}</h3>
              <p className="feedback-modal__gesture">
                {t('This simple gesture can make a big difference.')}
              </p>
              <div className="feedback-modal__footer">
                <button
                  className="feedback-modal__dismiss"
                  onClick={this._onCloseThankYouModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay flex={true} show={showFeedbackReceivedModal}>
          <div className="feedback-modal">
            <div className="feedback-modal__header">
              <span className="feedback-modal__spacer">&nbsp;</span>
              <h3 className="feedback-modal__title">{t('Your Review')}</h3>
              <button
                className="feedback-modal__close"
                onClick={this._onCloseFeedbackReceivedModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="feedback-modal__content">
              <h3 className="feedback-modal__thankyou">{t('Thank you!')}</h3>
              <p className="feedback-modal__gesture">
                {t('You have already given feedback.')}
              </p>
              <div className="feedback-modal__footer">
                <button
                  className="feedback-modal__dismiss"
                  onClick={this._onCloseFeedbackReceivedModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay flex={true} show={showFeedbackDisabledModal}>
          <div className="feedback-modal">
            <div className="feedback-modal__header">
              <span className="feedback-modal__spacer">&nbsp;</span>
              <h3 className="feedback-modal__title">{t('Your Review')}</h3>
              <button
                className="feedback-modal__close"
                onClick={this._onCloseFeedbackDisabledModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="feedback-modal__content">
              <h3 className="feedback-modal__thankyou">{t("We're sorry!")}</h3>
              <p className="feedback-modal__gesture">
                {t('Feedback is disabled for this location.')}
              </p>
              <div className="feedback-modal__footer">
                <button
                  className="feedback-modal__dismiss"
                  onClick={this._onCloseFeedbackDisabledModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay flex={true} show={showInvalidLinkErrorModal}>
          <div className="feedback-modal">
            <div className="feedback-modal__header">
              <span className="feedback-modal__spacer">&nbsp;</span>
              <h3 className="feedback-modal__title">{t('Your Review')}</h3>
              <button
                className="feedback-modal__close"
                onClick={this._onCloseInvalidLinkErrorModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="feedback-modal__content">
              <h3 className="feedback-modal__thankyou">{t("We're sorry!")}</h3>
              <p className="feedback-modal__gesture">
                {t('This feedback link is invalid.')}
              </p>
              <div className="feedback-modal__footer">
                <button
                  className="feedback-modal__dismiss"
                  onClick={this._onCloseInvalidLinkErrorModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay flex={true} show={showErrorSavingFeedbackModal}>
          <div className="feedback-modal">
            <div className="feedback-modal__header">
              <span className="feedback-modal__spacer">&nbsp;</span>
              <h3 className="feedback-modal__title">{t('Your Review')}</h3>
              <button
                className="feedback-modal__close"
                onClick={this._onCloseErrorSavingFeedbackModal}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="feedback-modal__content">
              <h3 className="feedback-modal__thankyou">{t("We're sorry!")}</h3>
              <p className="feedback-modal__gesture">
                {t('An error occurred while saving your feedback.')}
              </p>
              <div className="feedback-modal__footer">
                <button
                  className="feedback-modal__dismiss"
                  onClick={this._onCloseErrorSavingFeedbackModal}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </div>
        </Overlay>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

Feedback.contextType = DataContext;

export default withTranslation()(Feedback);
