import React, { Component } from 'react';
import Router from './Router/router';
import cloneDeep from 'lodash.clonedeep';
import I18n from './i18n';
import Utils from './utils';
import Session from './session';
import Constants from './constants';
import DataContext from './Contexts/DataContext';
import Overlay from './Components/Overlay/Overlay';
import momentLocaleWrapper from './momentLocaleWrapper';
import LocationConfigService from './Services/locationConfigService';
import ContentManagementService from './Services/contentManagementService';
import Events from './events';
import './Layout.scss';

/**
 * Represents the main application.
 */
class App extends Component {
  /**
   * Initializes a new instance of the Application.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      isReadyToRender: false,
      showLoadingOverlay: true,
    };
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      await this._setupMinimalLocationInfo();
      await this._setupI18nLanguage();

      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  _formatRequestUrl() {
    let requestUrl = window.location.href.toLowerCase();

    if (requestUrl.includes('www.')) {
      requestUrl = requestUrl.replace('www.', '');
      // We want to remove www in order to be consistent with the
      // locator team. Also, this fix an issue with local storage
      // saving data in www.<domain>.com vs. <domain>.com.
      window.location.href = requestUrl;
    }
  }

  _formatResources = (resources) => {
    const { t } = this.props;
    const formattedResources = resources?.map((resource) => {
      if (resource.displayName === '') {
        resource.displayName = t('Default Resource Display Name');
      }

      return resource;
    });

    return formattedResources;
  };

  _setupFullLocationInfo = async () => {
    this._formatRequestUrl();

    const requestUrl = window.location.href.toLowerCase();
    const uniqueLocationId = Utils.getUniqueLocationIdFromUrlPathName(
      window.location.pathname
    );
    const locationConfigService = new LocationConfigService();
    await locationConfigService
      .getLocationAndGroupInfoData({
        siteConfigUrl: requestUrl,
        uniqueLocationId: uniqueLocationId,
      })
      .then((locAndGroupInfoData) => {
        const {
          siteConfig,
          phoneCountryCode,
          formattedPhoneNumber,
          formattedSmsPhoneNumber,
          getLocationAndGroupInfoResponse,
        } = locAndGroupInfoData;
        const { chainInfo, locationInfo } = getLocationAndGroupInfoResponse;
        const newLocationInfo = {
          ...locationInfo,
          phoneCountryCode: phoneCountryCode,
          resources: this._formatResources(locationInfo?.resources),
        };
        const { siteId, siteName } = siteConfig;

        document.title = siteName;

        Session.setItem(Constants.siteConfig, siteConfig);
        Session.setItem(Constants.siteIdKey, siteId);
        Session.setItem(Constants.locationInfoKey, newLocationInfo);
        Session.setItem(Constants.maskedPhone, formattedPhoneNumber);
        Session.setItem(Constants.maskedSmsPhone, formattedSmsPhoneNumber);

        this.context.update({
          phoneCountryCode: phoneCountryCode,
          uniqueLocationId: uniqueLocationId,
          chainInfo: cloneDeep(chainInfo),
          locationInfo: newLocationInfo,
          locationInfoRetrieved: true
        });

        Events.emit(Constants.Events.LocationInfoLoaded);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  _setupI18nLanguage = async () => {
    let { languageTag } = this.context.minimalLocationInfo;

    if (!languageTag) {
      languageTag = Utils.convertUrlToLocale();
    }

    const contentManagementService = new ContentManagementService();
    await contentManagementService.loadLocalizations(languageTag);
    I18n.init();
    await I18n.changeLanguage(languageTag);

    momentLocaleWrapper.locale(languageTag);
  };

  _setupMinimalLocationInfo = async () => {
    this._formatRequestUrl();

    const requestUrl = window.location.href.toLowerCase();
    const uniqueLocationId = decodeURIComponent(
      Utils.getUniqueLocationIdFromUrlPathName(window.location.pathname)
    );

    if (uniqueLocationId && !Utils.isTermsOrPrivacyPage(uniqueLocationId)) {
      // Get the minimum location info needed to render the content.
      // This will allow much faster initial interaction for the user.
      try {
        const locationConfigService = new LocationConfigService();
        const minimalLocationInfo =
          await locationConfigService.getMinimalLocationInfo({
            url: requestUrl,
            uniqueLocationId: uniqueLocationId,
          });

        Session.setItem(Constants.siteIdKey, minimalLocationInfo?.siteId);
        Session.setItem(
          Constants.languageTagKey,
          minimalLocationInfo?.languageTag
        );

        this.context.update({
          minimalLocationInfo: minimalLocationInfo,
        });

        // Now get the rest of the location info needed for the app in the background.
        await this._setupFullLocationInfo();
      } catch {
        // If we received an error, then most likely the unique location id
        // is a location group, so lets try and get the full location info.
        await this._setupFullLocationInfo();
      }
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const { isReadyToRender, showLoadingOverlay } = this.state;

    return (
      <div className="App">
        {isReadyToRender && <Router />}
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

App.contextType = DataContext;

export default App;
