import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDrawer from '@material-ui/core/Drawer';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withTranslation } from 'react-i18next';
import Breakpoint from '../../breakpoint';
import './PreAppointmentSurvey.scss';

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Drawer = withStyles({
  paper: {
    borderRadius: '15px 15px 0 0',
  },
})(MuiDrawer);

const Checkbox = withStyles({
  root: {
    color: '#000',
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
})(MuiCheckbox);

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);

const Dialog = withStyles({
  paper: {
    borderRadius: '16px',
    maxWidth: '900px',
  },
})(MuiDialog);

const DialogTitle = withStyles({
  root: {
    padding: 0,
  },
})(MuiDialogTitle);

const FormControlLabel = withStyles({
  label: {
    fontSize: '0.8rem',
    fontWeight: 500,
  },
})(MuiFormControlLabel);

/**
 * Represents a pre-appointment survey for the consumer.
 */
class PreAppointmentSurvey extends Component {
  /**
   * Initializes a new instance of the PreAppointmentSurvey component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showSurveyQuestions: false,
      visualDemandDigitalDevice: false,
      visualDemandReadWrite: false,
      visualDemandNightDrive: false,
      currentlyWearGlasses: false,
      ageRange: '',
      frequencyOfUse: '',
      specialInstructions: '',
    };
  }

  _onStartConsumerSurvey = () => {
    this.setState(() => ({ showSurveyQuestions: true }));
  };

  _onSubmit = () => {
    const preAppointmentSurvey = {
      visualDemandDigitalDevice: this.state.visualDemandDigitalDevice,
      visualDemandReadWrite: this.state.visualDemandReadWrite,
      visualDemandNightDrive: this.state.visualDemandNightDrive,
      currentlyWearGlasses: this.state.currentlyWearGlasses,
      ageRange: this.state.ageRange,
      frequencyOfUse: this.state.frequencyOfUse,
      specialInstructions: this.state.specialInstructions,
    };
    this.props.onClose(preAppointmentSurvey);
  };

  _onCancel = () => {
    this.props.onClose(null);
  };

  _onWearGlassesRadioChanged = () => {
    if (this.state.currentlyWearGlasses === true) {
      this.setState((prevState) => ({ currentlyWearGlasses: false }));
      this.setState({ frequencyOfUse: '' });
    } else {
      this.setState((prevState) => ({ currentlyWearGlasses: true }));
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const { showSurveyQuestions } = this.state;
    const { show, t } = this.props;
    const isMobile = Breakpoint.value === 'mobile';

    return (
      <>
        {isMobile && (
          <Drawer
            anchor="bottom"
            className="pre-appt-survey__survey"
            open={show}
          >
            <div className="pre-appt-survey__survey-content">
              <div
                className={`pre-appt-survey__survey-header ${
                  showSurveyQuestions
                    ? 'pre-appt-survey__survey-header--questionaire'
                    : ''
                }`}
              >
                <h3 className="pre-appt-survey__survey-title">
                  {t('We would like to learn more about you!')}
                </h3>
                <button
                  className="pre-appt-survey__survey-close"
                  onClick={this._onCancel}
                >
                  <CloseIcon />
                </button>
              </div>
              <div>
                {!showSurveyQuestions && (
                  <p className="pre-appt-survey__survey-statement">
                    {t(
                      'Please answer 4 short questions to help us prepare for your appointment.'
                    )}
                  </p>
                )}
                {!showSurveyQuestions && (
                  <button
                    className="pre-appt-survey__survey-start"
                    onClick={this._onStartConsumerSurvey}
                  >
                    <KeyboardArrowDownIcon />
                    &nbsp;&nbsp;
                    <span>{t('Questions Below')}</span>&nbsp;&nbsp;
                    <KeyboardArrowDownIcon />
                  </button>
                )}
                {showSurveyQuestions && (
                  <div className="pre-appt-survey__survey-questions">
                    <ol className="pre-appt-survey__survey-list">
                      <li
                        value="1"
                        className="pre-appt-survey__survey-question"
                      >
                        {ReactHtmlParser(
                          t(
                            'Which of the following visual demands do you encounter on a regular basis? (select all that apply)'
                          )
                        )}
                        <ul className="pre-appt-survey__survey-sublist">
                          <li>
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Checkbox
                                  checked={this.state.visualDemandDigitalDevice}
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      visualDemandDigitalDevice: checked,
                                    }));
                                  }}
                                />
                              }
                              label={t('Digital devices')}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Checkbox
                                  checked={this.state.visualDemandReadWrite}
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      visualDemandReadWrite: checked,
                                    }));
                                  }}
                                />
                              }
                              label={t('Read/Write Newspapers/Books')}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Checkbox
                                  checked={this.state.visualDemandNightDrive}
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      visualDemandNightDrive: checked,
                                    }));
                                  }}
                                />
                              }
                              label={t('Night Driving')}
                            />
                          </li>
                        </ul>
                      </li>
                      <li
                        value="2"
                        className="pre-appt-survey__survey-question"
                      >
                        {t('What is your age range?')}
                        <RadioGroup>
                          <FormControlLabel
                            className="pre-appt-survey__form-label"
                            control={
                              <Radio
                                checked={this.state.ageRange === 'lessthan40'}
                                onChange={(e, checked) => {
                                  this.setState((prevState) => ({
                                    ageRange: 'lessthan40',
                                  }));
                                }}
                              />
                            }
                            label={t('Less than 40')}
                            value="lessthan40"
                          />
                          <FormControlLabel
                            className="pre-appt-survey__form-label"
                            control={
                              <Radio
                                checked={this.state.ageRange === 'over40'}
                                onChange={(e, checked) => {
                                  this.setState((prevState) => ({
                                    ageRange: 'over40',
                                  }));
                                }}
                              />
                            }
                            label={t('Over 40')}
                            value="over40"
                          />
                        </RadioGroup>
                      </li>
                      <li
                        value="3"
                        className="pre-appt-survey__survey-question"
                      >
                        {t('Do you wear eyeglasses now?')}
                        <RadioGroup>
                          <FormControlLabel
                            className="pre-appt-survey__form-label"
                            control={
                              <Radio
                                checked={!this.state.currentlyWearGlasses}
                                onChange={(e, checked) => {
                                  this._onWearGlassesRadioChanged();
                                }}
                              />
                            }
                            label={t('No')}
                          />
                          <FormControlLabel
                            className="pre-appt-survey__form-label"
                            control={
                              <Radio
                                checked={this.state.currentlyWearGlasses}
                                onChange={(e, checked) => {
                                  this._onWearGlassesRadioChanged();
                                }}
                              />
                            }
                            label={t('Yes')}
                          />
                          <span>{t('If "Yes", how often?')}</span>
                          <RadioGroup>
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse === 'allthetime'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'allthetime',
                                    }));
                                  }}
                                />
                              }
                              label={t('All the time')}
                              value="allthetime"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse === 'sometimes'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'sometimes',
                                    }));
                                  }}
                                />
                              }
                              label={t('Sometimes')}
                              value="sometimes"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse ===
                                    'onlyforfardistance'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'onlyforfardistance',
                                    }));
                                  }}
                                />
                              }
                              label={t('Only for far distance')}
                              value="onlyforfardistance"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse ===
                                    'onlyforereading'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'onlyforereading',
                                    }));
                                  }}
                                />
                              }
                              label={t('Only for reading')}
                              value="onlyforereading"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse ===
                                    'onlyforthecomputer'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'onlyforthecomputer',
                                    }));
                                  }}
                                />
                              }
                              label={t('Only for the computer')}
                              value="onlyforthecomputer"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                          </RadioGroup>
                        </RadioGroup>
                      </li>
                      <li
                        value="4"
                        className="pre-appt-survey__survey-question"
                      >
                        {t('Special instructions related to your appointment?')}
                        <textarea
                          className="pre-appt-survey__form-multiinput"
                          onChange={(e) => {
                            const value = e.target.value;
                            this.setState((prevState) => ({
                              specialInstructions: value,
                            }));
                          }}
                        ></textarea>
                      </li>
                    </ol>
                  </div>
                )}
                {showSurveyQuestions && (
                  <div className="pre-appt-survey__survey-footer">
                    <button
                      className="pre-appt-survey__survey-submit"
                      onClick={this._onSubmit}
                    >
                      {t('Submit')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Drawer>
        )}
        {!isMobile && (
          <Dialog open={show}>
            <DialogTitle>
              <div className="pre-appt-survey__survey-header pre-appt-survey__survey-header--dialog">
                <h3 className="pre-appt-survey__survey-title pre-appt-survey__survey-title--dialog">
                  {t('We would like to learn more about you!')}
                </h3>
                <p className="pre-appt-survey__survey-statement pre-appt-survey__survey-statement--dialog">
                  {t(
                    'Please answer 4 short questions to help us prepare for your appointment.'
                  )}
                </p>
                <button
                  className="pre-appt-survey__survey-close"
                  onClick={this._onCancel}
                >
                  <CloseIcon />
                </button>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="pre-appt-survey__survey-body">
                <div className="pre-appt-survey__survey-column pre-appt-survey__survey-column1">
                  <ol className="pre-appt-survey__survey-list pre-appt-survey__survey-list--dialog">
                    <li value="1" className="pre-appt-survey__survey-question">
                      <div className="pre-appt-survey__survey-question-body">
                        {ReactHtmlParser(
                          t(
                            'Which of the following visual demands do you encounter on a regular basis? (select all that apply)'
                          )
                        )}
                        <FormControlLabel
                          className="pre-appt-survey__form-label"
                          control={
                            <Checkbox
                              checked={this.state.visualDemandDigitalDevice}
                              onChange={(e, checked) => {
                                this.setState((prevState) => ({
                                  visualDemandDigitalDevice: checked,
                                }));
                              }}
                            />
                          }
                          label={t('Digital devices')}
                        />
                        <FormControlLabel
                          className="pre-appt-survey__form-label"
                          control={
                            <Checkbox
                              checked={this.state.visualDemandReadWrite}
                              onChange={(e, checked) => {
                                this.setState((prevState) => ({
                                  visualDemandReadWrite: checked,
                                }));
                              }}
                            />
                          }
                          label={t('Read/Write Newspapers/Books')}
                        />
                        <FormControlLabel
                          className="pre-appt-survey__form-label"
                          control={
                            <Checkbox
                              checked={this.state.visualDemandNightDrive}
                              onChange={(e, checked) => {
                                this.setState((prevState) => ({
                                  visualDemandNightDrive: checked,
                                }));
                              }}
                            />
                          }
                          label={t('Night Driving')}
                        />
                      </div>
                    </li>
                    <li
                      value="3"
                      className="pre-appt-survey__survey-question pre-appt-survey__survey-list--dialog"
                    >
                      {t('Do you wear eyeglasses now?')}
                      <RadioGroup>
                        <FormControlLabel
                          className="pre-appt-survey__form-label"
                          control={
                            <Radio
                              checked={!this.state.currentlyWearGlasses}
                              onChange={(e, checked) => {
                                this._onWearGlassesRadioChanged();
                              }}
                            />
                          }
                          label={t('No')}
                        />
                        <FormControlLabel
                          className="pre-appt-survey__form-label"
                          control={
                            <Radio
                              checked={this.state.currentlyWearGlasses}
                              onChange={(e, checked) => {
                                this._onWearGlassesRadioChanged();
                              }}
                            />
                          }
                          label={t('Yes')}
                        />
                        <span>{t('If "Yes", how often?')}</span>
                        <div className="pre-appt-survey__survey-column-cont">
                          <div className="pre-appt-survey__survey-column">
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse === 'allthetime'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'allthetime',
                                    }));
                                  }}
                                />
                              }
                              label={t('All the time')}
                              value="allthetime"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse === 'sometimes'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'sometimes',
                                    }));
                                  }}
                                />
                              }
                              label={t('Sometimes')}
                              value="sometimes"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse ===
                                    'onlyforfardistance'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'onlyforfardistance',
                                    }));
                                  }}
                                />
                              }
                              label={t('Only for far distance')}
                              value="onlyforfardistance"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                          </div>
                          <div className="pre-appt-survey__survey-column">
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse ===
                                    'onlyforereading'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'onlyforereading',
                                    }));
                                  }}
                                />
                              }
                              label={t('Only for reading')}
                              value="onlyforereading"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                            <FormControlLabel
                              className="pre-appt-survey__form-label"
                              control={
                                <Radio
                                  checked={
                                    this.state.frequencyOfUse ===
                                    'onlyforthecomputer'
                                  }
                                  onChange={(e, checked) => {
                                    this.setState((prevState) => ({
                                      frequencyOfUse: 'onlyforthecomputer',
                                    }));
                                  }}
                                />
                              }
                              label={t('Only for the computer')}
                              value="onlyforthecomputer"
                              disabled={!this.state.currentlyWearGlasses}
                            />
                          </div>
                        </div>
                      </RadioGroup>
                    </li>
                  </ol>
                </div>
                <div className="pre-appt-survey__survey-column">
                  <ol className="pre-appt-survey__survey-list pre-appt-survey__survey-list--dialog">
                    <li value="2" className="pre-appt-survey__survey-question">
                      {t('What is your age range?')}
                      <RadioGroup>
                        <FormControlLabel
                          className="pre-appt-survey__form-label"
                          control={
                            <Radio
                              checked={this.state.ageRange === 'lessthan40'}
                              onChange={(e, checked) => {
                                this.setState((prevState) => ({
                                  ageRange: 'lessthan40',
                                }));
                              }}
                            />
                          }
                          label={t('Less than 40')}
                          value="lessthan40"
                        />
                        <FormControlLabel
                          className="pre-appt-survey__form-label"
                          control={
                            <Radio
                              checked={this.state.ageRange === 'over40'}
                              onChange={(e, checked) => {
                                this.setState((prevState) => ({
                                  ageRange: 'over40',
                                }));
                              }}
                            />
                          }
                          label={t('Over 40')}
                          value="over40"
                        />
                      </RadioGroup>
                    </li>
                    <li value="4" className="pre-appt-survey__survey-question">
                      {t('Special instructions related to your appointment?')}
                      <textarea
                        className="pre-appt-survey__form-multiinput pre-appt-survey__form-multiinput--dialog"
                        onChange={(e) => {
                          const value = e.target.value;
                          this.setState((prevState) => ({
                            specialInstructions: value,
                          }));
                        }}
                      ></textarea>
                    </li>
                  </ol>
                  <button
                    className="pre-appt-survey__survey-submit pre-appt-survey__survey-submit--dialog"
                    onClick={this._onSubmit}
                  >
                    {t('Submit')}
                  </button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  }
}

PreAppointmentSurvey.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

PreAppointmentSurvey.defaultProps = {
  show: false,
};

export default withTranslation()(PreAppointmentSurvey);
