import Session from './session';
import Constants from './constants';
import GoogleAnalytics from './googleAnalytics';

class EventCategory {
  Home = {
    homePage: () => 'Patient_Experience_Homepage',
  };

  Waitlist = {
    startPage: () => 'Patient_Experience_Waitlist_StartPage',
    reasonPage: () => 'Patient_Experience_Waitlist_ReasonPage',
    providerPage: () => 'Patient_Experience_Waitlist_ProviderPage', // unused currently
    infoPage: () => 'Patient_Experience_Waitlist_InfoPage',
    detailsPage: () => 'Patient_Experience_Waitlist_DetailsPage',
    confirmPage: () => 'Patient_Experience_Waitlist_ConfirmedPage',
  };

  Booking = {
    reasonPage: () => 'Patient_Experience_Book_Appt_ReasonPage',
    providerPage: () => 'Patient_Experience_Book_Appt_ProviderPage',
    timePage: () => 'Patient_Experience_Book_Appt_TimePage',
    infoPage: () => 'Patient_Experience_Book_Appt_InfoPage',
    detailsPage: () => 'Patient_Experience_Book_Appt_DetailsPage',
    confirmPage: () => 'Patient_Experience_Book_Appt_ConfirmedPage',
  };

  Feedback = {
    feedbackPage: () => 'Patient_Experience_Feedback_RatingPage',
  };

  Download = {
    downloadPage: () => 'Patient_Experience_Download_ChatAttachment'
  };
}

Object.freeze(EventCategory.Home);
Object.freeze(EventCategory.Waitlist);
Object.freeze(EventCategory.Booking);
Object.freeze(EventCategory.Feedback);
Object.freeze(EventCategory);

class EventAction {
  eventOptions = {
    numberOfPeople: false,
    practiceIdentifier: false,
  };

  Click = {
    locationAddress: () => 'Click_Location_Address',
    callNow: () => 'Click_Call_Now',
    callNowPhoneNumber: () => 'Click_Call_Now_Phone_Number',
    addToWaitlist: () => 'Click_Add_To_Waitlist',
    stillAddToWaitlist: () => 'Click_Still_Add_Name',
    bookNow: () => 'Click_Book_Now', // unused currently
    ecpOfficeHours: () => 'Click_ECP_Office_Hours',
    backButton: () => 'Click_Back_Button',
    reason: (reason) => `Click_Reason_${reason}`,
    providerName: () => 'Click_Provider_Name',
    chooseDate: () => 'Click_Choose_Date',
    chooseDateScrollForward: () => 'Click_Choose_Date_Scroll_Forward',
    chooseDateScrollBackward: () => 'Click_Choose_Date_Scroll_Backward',
    appointmentTimeListing: () => 'Click_Appointment_Time_Listing',
    infoIconPhone: () => 'Click_Info_Icon_Phone',
    infoIconIcsDownload: () => 'Click_Info_Icon_Ics_Download',
    seeDetailResults: () => 'Click_See_Detail_Results',
    confirmForWaitlist: () => 'Click_Confirm_For_Waitlist',
    confirmBooking: () => 'Click_Confirm_Booking',
    closeFinishedBooking: () => 'Click_Close_Finished_Booking',
    getDirections: () => 'Click_Get_Directions',
    addToMyCalendar: () => 'Click_Add_to_my_Calendar',
    submitStarRating: () => 'Click_Submit_Star_Rating',
    addReview: () => 'Click_Add_Review',
    addGoogleReview: () => 'Click_Add_Google_Review',
    submitPreAppointmentSurvey: () => 'Click_Submit_PreAppointment_Survey',
    dismissPreAppointmentSurvey: () => 'Click_Dismiss_PreAppointment_Survey',
    prebookedAppointmentTimeSlot: () =>
      'Click_Reschedule_Prebooked_Appointment',
    googleRating: () => 'Click_Google_Rating',
  };

  Pageview = {
    homePage: () => `Homepage_Load`,
    reasonPage: () => `Reason_For_Visit`,
    providerPage: () => `Providers_Available`,
    infoPage: () => `InfoPage`,
    detailsPage: () => `DetailsPage`,
    waitlistConfirmedPage: () => {
      if (!this.eventOptions.numberOfPeople) {
        this.init();
      }
      return `Waitlist_Confirmed_Page_${this.eventOptions.numberOfPeople}`;
    },
    bookingConfirmedPage: () => `Booking_Confirmed_Page`,
  };

  init = () => {
    const locationInfo = Session.getItem(Constants.locationInfoKey);
    this.eventOptions = {
      numberOfPeople: locationInfo?.numberOfPatientsInQueue + 1,
    };
  };
}

Object.freeze(EventAction.Click);
Object.freeze(EventAction.Pageview);
Object.freeze(EventAction);

class EventLabel {
  labelOptions = {
    numberOfPeople: false,
    practiceIdentifier: false,
  };

  bookingOptions = {
    selectedTime: false,
    selectedResource: false,
  };

  consumerOptions = {
    selectedMessagingSmsType: false,
  };

  numberOfPeople = () => {
    if (!this.labelOptions.numberOfPeople) {
      this.init();
    }
    return this.labelOptions.numberOfPeople;
  };

  practiceIdentifier = () => {
    if (!this.labelOptions.practiceIdentifier) {
      this.init();
    }
    return this.labelOptions.practiceIdentifier;
  };

  selectedTime = () => {
    this.bookingInit();
    return this.bookingOptions.selectedTime;
  };

  selectedResource = () => {
    this.bookingInit();
    return this.bookingOptions.selectedResource;
  };

  selectedMessagingSmsType = () => {
    this.consumerInit();
    return this.consumerOptions.selectedMessagingSmsType;
  };

  firstAvailableResource = () => {
    return `First_Available;${this.selectedResource()}`;
  };

  practiceIdentifierAndNumberOfPeople = () => {
    return `${this.practiceIdentifier()};${this.numberOfPeople()}`;
  };

  practiceIdentifierAndDateTime = () => {
    return `${this.practiceIdentifier()};${this.selectedTime()}`;
  };

  practiceIdentifierAndDateTimeAndResource = () => {
    return `${this.practiceIdentifier()};${this.selectedTime()};${this.selectedResource()}`;
  };

  practiceIdentifierAndDateTimeAndResourceAndMessagingType = () => {
    return `${this.practiceIdentifier()};${this.selectedTime()};${this.selectedResource()};${this.selectedMessagingSmsType()}`;
  };

  getDirections = () => {
    return `${this.practiceIdentifier()};${this.selectedTime()};${this.selectedResource()}`;
  };

  addToMyCalendar = () => {
    return `${this.practiceIdentifier()};${this.selectedTime()};${this.selectedResource()}`;
  };

  listOfOptions = (options) => `${options}`;

  init = () => {
    const locationInfo = Session.getItem(Constants.locationInfoKey);
    this.labelOptions = {
      numberOfPeople: locationInfo?.numberOfPatientsInQueue + 1,
      practiceIdentifier: locationInfo?.uniqueLocationId,
    };
  };

  bookingInit = () => {
    const bookingInfo = Session.getItem(Constants.bookingInfoKey);
    this.bookingOptions = {
      selectedTime: bookingInfo.selectedTime,
      selectedResource: bookingInfo.resource.displayName,
    };
  };

  consumerInit = () => {
    const consumerInfo = Session.getItem(Constants.consumerInfoKey);
    this.consumerOptions = {
      selectedMessagingSmsType:
        consumerInfo.messagingSmsType === 1 ? 'SMS' : 'WhatsApp',
    };
  };
}

Object.freeze(EventLabel);

/**
 * Wrapper class around GA Events.
 */
class EventBuilder {
  constructor() {
    this.category = 'default category';
    this.action = 'default action';
    this.label = 'default label';
    this.nonInteraction = false;

    this.Action = new EventAction();
    this.Label = new EventLabel();
    this.Category = new EventCategory();
  }
  /**
   * Sets the category of the event.
   * @param {function} category Set with this.Category
   */
  withCategory = (category) => {
    this.category = category();
    return this;
  };
  /**
   * Sets the Action of the event.
   * @param {function} action Set with this.Action
   * @param {Object} args Optional parameters for this.Action
   */
  withAction = (action, args) => {
    this.action = action(args);
    return this;
  };
  /**
   * Sets the Label of the event.
   * @param {function} label Set with this.Label
   */
  withLabel = (label, args) => {
    this.label = label(args);
    return this;
  };

  /**
   * Use this to call the GA Wrapper and post this to GA.
   * This violates SRP (kind of?) but it's real convenient.
   */
  post = () => {
    GoogleAnalytics.postEvent({
      category: this.category,
      action: this.action,
      label: this.label,
      nonInteraction: this.nonInteraction,
    });
  };
}

Object.freeze(EventBuilder);

export default EventBuilder;
