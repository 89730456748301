import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import I18n from '../../i18n';
import Utils from '../../utils';
import Session from '../../session';
import Constants from '../../constants';

import QRIcon from '../../Assets/Images/QR-Icon.png';
import DateIcon from '../../Assets/Images/Date-Icon.png';
import LocationConfigService from '../../Services/locationConfigService';
import ContentManagementService from '../../Services/contentManagementService';
import NotificationsIcon from '../../Assets/Images/Notifications-Icon.png';
import backgroundImageEnglish from '../../Assets/Images/Background-Image-1001.jpg';
import backgroundImageSpanish from '../../Assets/Images/Background-Image-1002.jpg';
import backgroundImagePortuguese from '../../Assets/Images/Background-Image-1003.jpg';
import backgroundImageTurkish from '../../Assets/Images/Background-Image-1004.jpg';
import backgroundImageChinese from '../../Assets/Images/Background-Image-1005.jpg';

import './Root.scss';

/**
 * Represents the home page of the consumer app.
 */
class Home extends Component {
  /**
   * Initializes a new instance of the Home component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this._locationConfigService = new LocationConfigService();
    this._contentManagementService = new ContentManagementService();
  }

  /**
   * Executes when the compoenent has mounted to the DOM.
   */
  async componentDidMount() {
    const requestUrl = window.location.href.toLowerCase();
    const siteConfig = await this._locationConfigService.getSiteConfigData(
      requestUrl
    );
    const { siteId, siteName } = siteConfig;

    Session.setItem(Constants.siteIdKey, siteId);
    Session.setItem(Constants.siteConfig, siteConfig);

    document.title = siteName;

    await this._contentManagementService.loadLocalizations(
      Utils.convertUrlToLocale()
    );
    await this._setI18nLanguage();

    this.forceUpdate();
  }

  _setI18nLanguage = async () => {
    I18n.init();
    await I18n.changeLanguage(Utils.convertUrlToLocale());
  };

  _navigateToECPLogin = () => {
    const siteConfig = Session.getItem(Constants.siteConfig);
    window.open(siteConfig?.baseAdminUrl);
  };

  _navigateToLocator = () => {
    const siteConfig = Session.getItem(Constants.siteConfig);
    var participatingLocationsUrl =
      siteConfig?.locatorUrl ??
      'https://www.allaboutvision.com/locator/' +
        siteConfig.languageTag +
        '/#/result?geo=true&skipRedirect=true&filteredSearch=1&Online Booking=Yes';
    window.open(participatingLocationsUrl);
  };

  _displayPoweredBy = () => {
    const siteConfig = Session.getItem(Constants.siteConfig);

    if (
      Utils.isTurkeyMarket(siteConfig?.languageTag) ||
      Utils.isHongKongMarket(siteConfig?.languageTag) ||
      Utils.isTaiwanMarket(siteConfig?.languageTag) ||
      Utils.isIndiaMarket(siteConfig?.languageTag)
    ) {
      return false;
    }
    return true;
  };

  _displayParticipatingLocations = () => {
    const siteConfig = Session.getItem(Constants.siteConfig);

    if (
      Utils.isIndiaMarket(siteConfig?.languageTag) ||
      Utils.isPortugueseMarket(siteConfig?.languageTag) ||
      Utils.isSpanishMarket(siteConfig?.languageTag)
    ) {
      return true;
    }
    return false;
  };

  _getBackgroundImage = () => {
    const siteConfig = Session.getItem(Constants.siteConfig);

    if (Utils.isSpanishMarket(siteConfig?.languageTag)) {
      return backgroundImageSpanish;
    } else if (Utils.isPortugueseMarket(siteConfig?.languageTag)) {
      return backgroundImagePortuguese;
    } else if (Utils.isTurkeyMarket(siteConfig?.languageTag)) {
      return backgroundImageTurkish;
    } else if (
      Utils.isHongKongMarket(siteConfig?.languageTag) ||
      Utils.isTaiwanMarket(siteConfig?.languageTag)
    ) {
      return backgroundImageChinese;
    } else {
      return backgroundImageEnglish;
    }
  };

  _contactEmailLink = () => {
    const siteConfig = Session.getItem(Constants.siteConfig);
    return (
      <a href={'mailto:' + siteConfig?.siteSupportEmailReplyToAddress}>
        {siteConfig?.siteSupportEmailReplyToAddress}
      </a>
    );
  };

  /**
   * Renders the component.
   */
  render() {
    const { t } = this.props;
    const logoUrl = Utils.getLogoUrl();
    const displayPoweredBy = this._displayPoweredBy();
    const displayParticipatingLocations = this._displayParticipatingLocations();
    const backgroundImage = this._getBackgroundImage();
    const containerTextRowsStyle = displayParticipatingLocations
      ? 'container-text__3Rows'
      : 'container-text__2Rows';
    const containerHeaderStyle = displayParticipatingLocations
      ? ''
      : 'header-container__content--1Btn';
    const containerHeaderLogoStyle = displayParticipatingLocations
      ? 'header-logo--2Btn'
      : 'header-logo--1Btn';

    return (
      <div>
        <header>
          <section className="header-content">
            <ul className={'header-container__content ' + containerHeaderStyle}>
              <li className={containerHeaderLogoStyle}>
                <img alt="Site Logo" src={logoUrl} />
              </li>
              <li className="header-button">
                {displayParticipatingLocations && (
                  <button
                    className="btn header-button-text"
                    onClick={this._navigateToLocator}
                  >
                    {t('Participating Locations')}
                  </button>
                )}
              </li>
              <li className="header-button">
                <button
                  className="btn header-button-text"
                  onClick={this._navigateToECPLogin}
                >
                  {t('ECP Login')}
                </button>
              </li>
            </ul>
          </section>
        </header>
        <div>
          <section>
            <div
              style={{ backgroundImage: `url(${backgroundImage})` }}
              className="container container-hero"
            >
              <div className="container-logo">
                <img alt="Site Logo" src={logoUrl} />
                {displayPoweredBy ? (
                  <p className="container-text1">
                    {t('Powered by')} <strong>{t('Essilor')}</strong>
                  </p>
                ) : (
                  <p></p>
                )}
              </div>
              <div className={'container-text ' + containerTextRowsStyle}>
                <p className="container-text2">
                  {t('Schedule an appointment with your eye care professional')}
                </p>
                <p className="container-text3">{t('Quick, Simple and Safe')}</p>
                {displayParticipatingLocations && (
                  <p className="container-text4">
                    {t('Interested in participating?')}
                    <br></br>
                    {t('Contact')} {this._contactEmailLink()}
                  </p>
                )}
              </div>
            </div>
          </section>
        </div>
        <footer>
          <section className="footer-content" id="footer-content">
            <ul>
              <li>
                <img alt="qr icon" src={QRIcon} />
                <p>
                  {t(
                    'Enter the virtual waitlist by scanning the QR code of your preferred optical store'
                  )}
                </p>
              </li>
              <li>
                <img alt="date icon" src={DateIcon} />
                <p>
                  {t(
                    'Book an appointment for the most convenient date and time, of your choice'
                  )}
                </p>
              </li>
              <li>
                <img alt="notifications icon" src={NotificationsIcon} />
                <p>
                  {t(
                    'Receive personalized reminders about your upcoming appointment via SMS or WhatsApp'
                  )}
                </p>
              </li>
            </ul>
          </section>
        </footer>
      </div>
    );
  }
}

export default withTranslation()(Home);
