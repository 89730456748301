import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DataContext from '../../Contexts/DataContext';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Utils from '../../utils';
import './Footer.scss';

/**
 * Represents the footer of a page.
 * @param {Object} props The component properties.
 */
const Footer = (props) => {
  const { showForMobile, showHours, showStatement, t } = props;
  const context = useContext(DataContext);
  const { schedules } = context.minimalLocationInfo.storeHours;
  const { languageTag } = context.minimalLocationInfo;

  return (
    <div className={`footer ${!showForMobile ? 'footer--mobile-hidden' : ''}`}>
      <span className="footer__sponsor">{t('Powered by Essilor')}</span>
      {showStatement && (
        <p className="footer__statement">
          {t(
            "We're prioritizing your safety with socially-distanced appointments"
          )}
        </p>
      )}
      {showHours && (
        <div className="footer-hours">
          <div className="footer-hours__store">
            <span className="footer-hours__icon">
              <QueryBuilderIcon />
            </span>
            <span className="footer-hours__label">{t('Store Hours')}</span>
          </div>
          <div className="footer-hours__hours">
            <div className="footer-hours__col">
              <span className="footer-hours__day">{t('Mon')}</span>
              <span className="footer-hours__day">{t('Tue')}</span>
              <span className="footer-hours__day">{t('Wed')}</span>
              <span className="footer-hours__day">{t('Thu')}</span>
            </div>
            <div className="footer-hours__col footer-hours__col--margin">
              <span className="footer-hours__time">
                {Utils.getDayHours(2, schedules, languageTag, t)}
              </span>
              <span className="footer-hours__time">
                {Utils.getDayHours(3, schedules, languageTag, t)}
              </span>
              <span className="footer-hours__time">
                {Utils.getDayHours(4, schedules, languageTag, t)}
              </span>
              <span className="footer-hours__time">
                {Utils.getDayHours(5, schedules, languageTag, t)}
              </span>
            </div>
            <div className="footer-hours__col">
              <span className="footer-hours__day">{t('Fri')}</span>
              <span className="footer-hours__day">{t('Sat')}</span>
              <span className="footer-hours__day">{t('Sun')}</span>
            </div>
            <div className="footer-hours__col">
              <span className="footer-hours__time">
                {Utils.getDayHours(6, schedules, languageTag, t)}
              </span>
              <span className="footer-hours__time">
                {Utils.getDayHours(7, schedules, languageTag, t)}
              </span>
              <span className="footer-hours__time">
                {Utils.getDayHours(1, schedules, languageTag, t)}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Footer.propTypes = {
  showForMobile: PropTypes.bool,
  showHours: PropTypes.bool,
  showStatement: PropTypes.bool,
};

Footer.defaultProps = {
  showForMobile: true,
  showHours: false,
  showStatement: false,
};

export default withTranslation()(Footer);
