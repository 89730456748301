import axios from 'axios';
import Session from '../session';
import Constants from '../constants';

/**
 * Represents the content management service.
 */
class ContentManagementService {
  /**
   * Loads the translation url into memory.
   * @param {String} langTag The language tag.
   */
  async loadLocalizations(langTag) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}`;
        const res = await axios.get(url);
        Session.setItem(
          Constants.currTranslationUrlKey,
          res?.data?.translation
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns the termsAndConditions url.
   * @param {String} langTag The language tag.
   */
  async getTermsAndConditions(langTag, stellestContent = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}&stellestContent=${stellestContent}`;
        const res = await axios.get(url);
        resolve(res?.data?.termsAndConditions);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns the privacy url.
   * @param {String} langTag The language tag.
   */
  async getPrivacy(langTag, stellestContent = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}&stellestContent=${stellestContent}`;
        const res = await axios.get(url);
        resolve(res?.data?.privacy);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Returns the phone country code for the provided country code.
   * @param {String} countryCode The country code.
   * @returns A Promise.
   */
  async getPhoneCountryCode(countryCode) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getPhoneCountryCode`;
        const res = await axios.post(url, { countryCode: countryCode });

        resolve(res?.data);
      } catch (error) {
        reject(error);
      }
    });
  }

  async downloadChatAttachment(fileId) {
    return new Promise(async (resolve, reject) =>{
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/downloadAttachment/${fileId}`;

        const res = await axios.get(url, { responseType: "blob" });
        resolve(res?.data);
          
      } catch (error) {
        reject(error);
      }

    });
    
  }
}

export default ContentManagementService;
